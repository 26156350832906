import React from "react"
import { IAuthLayoutProps } from "./interface"
import { Outlet } from 'react-router-dom';
import { ContentContainer, SidebarAccount, SidebarMenu } from "components/templates";

const Component: React.FC<IAuthLayoutProps> = ({
  setIsSidebarAccount,
  isSidebarAccount,
  setIsSidebarMenu,
  isSidebarMenu
}) => {
  return (
    <div>
      <div className={`allMobile:inline xl:flex 2xl:flex ${isSidebarAccount || isSidebarMenu ? 'h-screen overflow-y-hidden' : ''}`}>
        <SidebarMenu
          setIsSidebarMenu={setIsSidebarMenu}
          isSidebarMenu={isSidebarMenu}
        />
        <ContentContainer
          isSidebarMenu={isSidebarMenu}
          isSidebarAccount={isSidebarAccount}
        >
          <Outlet />
        </ContentContainer>
      </div>
      <SidebarAccount
        setIsSidebarAccount={setIsSidebarAccount}
        isSidebarAccount={isSidebarAccount}
      />
    </div>
  )
};

export default Component;

/* eslint-disable react-hooks/exhaustive-deps */
import { IcArrBot, IcArrTop } from 'assets/svgs';
import React, { useEffect, useState } from 'react';
import TimePicker from 'react-time-picker-input';
import Button from '../Button';
import { ITime } from './interface';

const Component: React.FC<ITime> = ({
  className,
  setValue,
  name,
  value,
  readOnly,
}) => {
  const [time, setTime] = useState<string>('00:00');
  const [showTime, setShowTime] = useState<boolean>(false);

  useEffect(() => {
    setTime(value);
  }, [value]);

  const onChangeTime = (timeValue) => {
    setTime(timeValue);
  };

  const plusHour = () => {
    const currentTime = time.split(':');
    let hour = '';

    if (Number(currentTime[0]) < 23) {
      hour =
        Number(currentTime[0]) < 9
          ? `0${Number(currentTime[0]) + 1}`
          : `${Number(currentTime[0]) + 1}`;
    } else {
      hour = `00`;
    }

    setTime(`${hour}.${currentTime[1]}`);
  };

  const plusMinute = () => {
    const currentTime = time.split(':');
    let minute = '';

    if (Number(currentTime[1]) < 59) {
      minute =
        Number(currentTime[1]) < 9
          ? `0${Number(currentTime[1]) + 1}`
          : `${Number(currentTime[1]) + 1}`;
    } else {
      minute = `00`;
    }

    setTime(`${currentTime[0]}.${minute}`);
  };

  const minHour = () => {
    const currentTime = time.split(':');
    let hour = '';

    if (Number(currentTime[0]) > 0) {
      if (Number(currentTime[0]) <= 10 && Number(currentTime[0]) > 0) {
        hour = `0${Number(currentTime[0]) - 1}`;
      } else if (Number(currentTime[0]) > 0) {
        hour = `${Number(currentTime[0]) - 1}`;
      }
    } else {
      hour = `23`;
    }

    setTime(`${hour}:${currentTime[1]}`);
  };

  const minMinute = () => {
    const currentTime = time.split(':');
    let minute = '';

    if (Number(currentTime[1]) > 0) {
      if (Number(currentTime[1]) <= 10 && Number(currentTime[1]) > 0) {
        minute = `0${Number(currentTime[1]) - 1}`;
      } else if (Number(currentTime[1]) > 0) {
        minute = `${Number(currentTime[1]) - 1}`;
      }
    } else {
      minute = `59`;
    }

    setTime(`${currentTime[0]}.${minute}`);
  };

  const handleCancel = () => {
    setTime('00:00');
    setShowTime(false);
  };

  const handleSubmitTime = () => {
    setValue(time, name);
    setShowTime(false);
  };

  return (
    <div className="relative">
      <input
        autoComplete="off"
        value={time}
        disabled={readOnly}
        placeholder="Atur Jam"
        onFocus={() => setShowTime(true)}
        className={`absolute p-4 h-14 border-[1px] w-full border-[#C8CBCE] rounded text-black focus:border-[#4C494A] focus:outline-none focus:ring-0 font-normal text-base bg-white ${
          false
            ? 'bg-[#F5F5F5] text-[#898F96] cursor-not-allowed'
            : 'bg-[#FFFFFF]'
        }`}
      />

      {showTime && (
        <div
          className={`${className} top-14 mt-1 w-full absolute z-50 p-3 rounded border border-[#c8cbce] bg-[#FFF]`}
        >
          <div className="p-4 text-sm font-medium border-b-[1px] border-[#F1F1F2] mb-4">
            Atur Jam
          </div>
          <div className="flex justify-center">
            <div
              className="w-[50px] text-center mx-2 mb-1 cursor-pointer"
              onClick={plusHour}
            >
              <IcArrTop className="m-auto" />
            </div>
            <div
              className="w-[50px] text-center mx-2 mb-1 cursor-pointer"
              onClick={plusMinute}
            >
              <IcArrTop className="m-auto" />
            </div>
          </div>
          <TimePicker
            isMobile={false}
            onChange={onChangeTime}
            value={time}
            allowDelete
          />
          <div className="flex justify-center">
            <div
              className="w-[50px] text-center mx-2 mt-1 cursor-pointer"
              onClick={minHour}
            >
              <IcArrBot className="m-auto" />
            </div>
            <div
              className="w-[50px] text-center mx-2 mt-1 cursor-pointer"
              onClick={minMinute}
            >
              <IcArrBot className="m-auto" />
            </div>
          </div>
          <div className="p-4 pb-0 grid grid-cols-2 gap-2 border-t-[1px] border-[#F1F1F2] mt-4">
            <Button
              onClick={handleCancel}
              type="button"
              btnType="secondary"
              classNameBtnWrapper="w-full"
            >
              Batalkan
            </Button>
            <Button
              onClick={handleSubmitTime}
              type="button"
              btnType="primary"
              classNameBtnWrapper="w-full"
            >
              Terapkan
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Component;

const SVGComponent = (props: any) => (
  <svg
    width={280}
    height={233}
    viewBox="0 0 280 233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_488_2395)">
      <path
        d="M277.943 228.802H2.05651C0.920732 228.802 0 229.722 0 230.857V230.945C0 232.08 0.920732 233 2.05651 233H277.943C279.079 233 280 232.08 280 230.945V230.857C280 229.722 279.079 228.802 277.943 228.802Z"
        fill="#FFE7B0"
      />
      <path
        d="M231.466 5.08777H122.577C121.827 5.08777 121.22 5.69502 121.22 6.44411V160.083C121.22 160.833 121.827 161.44 122.577 161.44H231.466C232.216 161.44 232.824 160.833 232.824 160.083V6.44411C232.824 5.69502 232.216 5.08777 231.466 5.08777Z"
        fill="white"
      />
      <path
        d="M161.013 52.2309V161.44H122.577C121.828 161.44 121.22 160.832 121.22 160.083V25.7764H139.805L161.013 52.2309Z"
        fill="#FFF7E6"
      />
      <path
        d="M129.878 63.4252V70.2597C129.878 70.6531 130.198 70.9731 130.591 70.9731H137.131C137.525 70.9731 137.845 70.6531 137.845 70.2597V63.4252C137.845 61.2292 136.059 59.4442 133.861 59.4442C131.664 59.4442 129.878 61.2292 129.878 63.4252ZM133.503 60.1782V63.8421H130.591V63.4252C130.591 61.7429 131.869 60.3572 133.503 60.1782ZM137.128 63.4252V63.8421H134.217V60.1782C135.853 60.3572 137.128 61.7429 137.128 63.4252ZM137.128 70.2597H134.217V64.5554H137.128V70.2597ZM130.588 70.2597V64.5554H133.503V70.2597H130.588Z"
        fill="#FFE7B0"
      />
      <path
        d="M144.388 63.4252V70.2597C144.388 70.6531 144.708 70.9731 145.102 70.9731H151.641C152.035 70.9731 152.355 70.6531 152.355 70.2597V63.4252C152.355 61.2292 150.569 59.4442 148.371 59.4442C146.174 59.4442 144.388 61.2292 144.388 63.4252ZM148.013 60.1782V63.8421H145.102V63.4252C145.102 61.7429 146.38 60.3572 148.013 60.1782ZM151.638 63.4252V63.8421H148.727V60.1782C150.363 60.3572 151.638 61.7429 151.638 63.4252ZM151.638 70.2597H148.727V64.5554H151.638V70.2597ZM145.099 70.2597V64.5554H148.013V70.2597H145.099Z"
        fill="#FFE7B0"
      />
      <path
        d="M129.878 83.0598V89.8944C129.878 90.2878 130.198 90.6078 130.591 90.6078H137.131C137.525 90.6078 137.845 90.2878 137.845 89.8944V83.0598C137.845 80.8638 136.059 79.0789 133.861 79.0789C131.664 79.0789 129.878 80.8638 129.878 83.0598ZM133.503 79.8128V83.4767H130.591V83.0598C130.591 81.3776 131.869 79.9919 133.503 79.8128ZM137.128 83.0598V83.4767H134.217V79.8128C135.853 79.9919 137.128 81.3776 137.128 83.0598ZM137.128 89.8944H134.217V84.1901H137.128V89.8944ZM130.588 89.8944V84.1901H133.503V89.8944H130.588Z"
        fill="#FFE7B0"
      />
      <path
        d="M144.388 83.0598V89.8944C144.388 90.2878 144.708 90.6078 145.102 90.6078H151.641C152.035 90.6078 152.355 90.2878 152.355 89.8944V83.0598C152.355 80.8638 150.569 79.0789 148.371 79.0789C146.174 79.0789 144.388 80.8638 144.388 83.0598ZM148.013 79.8128V83.4767H145.102V83.0598C145.102 81.3776 146.38 79.9919 148.013 79.8128ZM151.638 83.0598V83.4767H148.727V79.8128C150.363 79.9919 151.638 81.3776 151.638 83.0598ZM151.638 89.8944H148.727V84.1901H151.638V89.8944ZM145.099 89.8944V84.1901H148.013V89.8944H145.099Z"
        fill="#FFE7B0"
      />
      <path
        d="M129.878 102.694V109.529C129.878 109.922 130.198 110.242 130.591 110.242H137.131C137.525 110.242 137.845 109.922 137.845 109.529V102.694C137.845 100.498 136.059 98.7135 133.861 98.7135C131.664 98.7135 129.878 100.498 129.878 102.694ZM133.503 99.4475V103.111H130.591V102.694C130.591 101.012 131.869 99.6265 133.503 99.4475ZM137.128 102.694V103.111H134.217V99.4475C135.853 99.6265 137.128 101.012 137.128 102.694ZM137.128 109.529H134.217V103.825H137.128V109.529ZM130.588 109.529V103.825H133.503V109.529H130.588Z"
        fill="#FFE7B0"
      />
      <path
        d="M144.388 102.694V109.529C144.388 109.922 144.708 110.242 145.102 110.242H151.641C152.035 110.242 152.355 109.922 152.355 109.529V102.694C152.355 100.498 150.569 98.7135 148.371 98.7135C146.174 98.7135 144.388 100.498 144.388 102.694ZM148.013 99.4475V103.111H145.102V102.694C145.102 101.012 146.38 99.6265 148.013 99.4475ZM151.638 102.694V103.111H148.727V99.4475C150.363 99.6265 151.638 101.012 151.638 102.694ZM151.638 109.529H148.727V103.825H151.638V109.529ZM145.099 109.529V103.825H148.013V109.529H145.099Z"
        fill="#FFE7B0"
      />
      <path
        d="M129.878 122.329V129.164C129.878 129.557 130.198 129.877 130.591 129.877H137.131C137.525 129.877 137.845 129.557 137.845 129.164V122.329C137.845 120.133 136.059 118.348 133.861 118.348C131.664 118.348 129.878 120.133 129.878 122.329ZM133.503 119.082V122.746H130.591V122.329C130.591 120.647 131.869 119.261 133.503 119.082ZM137.128 122.329V122.746H134.217V119.082C135.853 119.261 137.128 120.647 137.128 122.329ZM137.128 129.164H134.217V123.459H137.128V129.164ZM130.588 129.164V123.459H133.503V129.164H130.588Z"
        fill="#FFE7B0"
      />
      <path
        d="M144.388 122.329V129.164C144.388 129.557 144.708 129.877 145.102 129.877H151.641C152.035 129.877 152.355 129.557 152.355 129.164V122.329C152.355 120.133 150.569 118.348 148.371 118.348C146.174 118.348 144.388 120.133 144.388 122.329ZM148.013 119.082V122.746H145.102V122.329C145.102 120.647 146.38 119.261 148.013 119.082ZM151.638 122.329V122.746H148.727V119.082C150.363 119.261 151.638 120.647 151.638 122.329ZM151.638 129.164H148.727V123.459H151.638V129.164ZM145.099 129.164V123.459H148.013V129.164H145.099Z"
        fill="#FFE7B0"
      />
      <path
        d="M129.878 141.964V148.798C129.878 149.192 130.198 149.512 130.591 149.512H137.131C137.525 149.512 137.845 149.192 137.845 148.798V141.964C137.845 139.768 136.059 137.983 133.861 137.983C131.664 137.983 129.878 139.768 129.878 141.964ZM133.503 138.717V142.381H130.591V141.964C130.591 140.282 131.869 138.896 133.503 138.717ZM137.128 141.964V142.381H134.217V138.717C135.853 138.896 137.128 140.282 137.128 141.964ZM137.128 148.798H134.217V143.094H137.128V148.798ZM130.588 148.798V143.094H133.503V148.798H130.588Z"
        fill="#FFE7B0"
      />
      <path
        d="M144.388 141.964V148.798C144.388 149.192 144.708 149.512 145.102 149.512H151.641C152.035 149.512 152.355 149.192 152.355 148.798V141.964C152.355 139.768 150.569 137.983 148.371 137.983C146.174 137.983 144.388 139.768 144.388 141.964ZM148.013 138.717V142.381H145.102V141.964C145.102 140.282 146.38 138.896 148.013 138.717ZM151.638 141.964V142.381H148.727V138.717C150.363 138.896 151.638 140.282 151.638 141.964ZM151.638 148.798H148.727V143.094H151.638V148.798ZM145.099 148.798V143.094H148.013V148.798H145.099Z"
        fill="#FFE7B0"
      />
      <path
        d="M235.709 0H118.335C117.585 0 116.977 0.607711 116.977 1.35634V165.168C116.977 165.917 117.585 166.525 118.335 166.525H235.709C236.458 166.525 237.066 165.917 237.066 165.168V1.35634C237.066 0.607711 236.458 0 235.709 0ZM121.22 6.44409C121.22 5.69546 121.828 5.08775 122.577 5.08775H174.56V39.3427H121.22V6.44409ZM121.22 43.0359H174.56V105.777H121.22V43.0359ZM121.22 160.081V109.473H174.56V161.44H122.577C121.828 161.44 121.22 160.832 121.22 160.083V160.081ZM232.824 160.081C232.824 160.829 232.215 161.437 231.466 161.437H178.256V109.47H232.824V160.078V160.081ZM232.824 105.777H178.256V43.0359H232.824V105.777ZM232.824 39.3398H178.256V5.08775H231.466C232.215 5.08775 232.824 5.69546 232.824 6.44409V39.3427V39.3398Z"
        fill="#FFE7B0"
      />
      <path
        d="M232.824 52.2308V72.7492H184.801L201.15 52.2308H232.824Z"
        fill="#FFF7E6"
      />
      <path
        d="M232.824 72.7491V76.8182H186.156C185.406 76.8182 184.798 76.2105 184.798 75.4618V72.7491H232.824Z"
        fill="#FFE7B0"
      />
      <path
        d="M232.827 76.8182H189.892V101.576H232.827V76.8182Z"
        fill="#FFF7E6"
      />
      <path
        d="M200.119 86.8704V93.7049C200.119 94.0983 200.439 94.4183 200.833 94.4183H207.373C207.766 94.4183 208.087 94.0983 208.087 93.7049V86.8704C208.087 84.6744 206.3 82.8894 204.103 82.8894C201.905 82.8894 200.119 84.6744 200.119 86.8704ZM203.745 83.6234V87.2872H200.833V86.8704C200.833 85.1881 202.111 83.8024 203.745 83.6234ZM207.37 86.8704V87.2872H204.458V83.6234C206.095 83.8024 207.37 85.1881 207.37 86.8704ZM207.37 93.7049H204.458V88.0006H207.37V93.7049ZM200.83 93.7049V88.0006H203.745V93.7049H200.83Z"
        fill="#FFE7B0"
      />
      <path
        d="M214.629 86.8704V93.7049C214.629 94.0983 214.95 94.4183 215.343 94.4183H221.883C222.277 94.4183 222.597 94.0983 222.597 93.7049V86.8704C222.597 84.6744 220.811 82.8894 218.613 82.8894C216.416 82.8894 214.629 84.6744 214.629 86.8704ZM218.255 83.6234V87.2872H215.343V86.8704C215.343 85.1881 216.621 83.8024 218.255 83.6234ZM221.88 86.8704V87.2872H218.969V83.6234C220.605 83.8024 221.88 85.1881 221.88 86.8704ZM221.88 93.7049H218.969V88.0006H221.88V93.7049ZM215.34 93.7049V88.0006H218.255V93.7049H215.34Z"
        fill="#FFE7B0"
      />
      <path
        d="M163.281 168.427C161.339 168.398 159.847 167.678 158.848 166.296C156.997 163.736 157.441 159.49 157.925 157.946C158.625 155.727 163.546 156.61 164.524 156.81L164.277 158.02C162.018 157.562 159.339 157.57 159.104 158.316C158.739 159.473 158.24 163.342 159.85 165.57C160.619 166.636 161.748 167.165 163.299 167.188L163.278 168.424L163.281 168.427Z"
        fill="#F47F56"
      />
      <path
        d="M164.606 154.247H177.733C178.543 154.247 179.202 154.905 179.202 155.715V167.681C179.202 169.786 177.489 171.498 175.382 171.498H166.956C164.85 171.498 163.137 169.786 163.137 167.681V155.715C163.137 154.905 163.795 154.247 164.606 154.247Z"
        fill="#FBAE61"
      />
      <path
        d="M270.96 127.191H259.068C258.581 127.191 258.186 127.585 258.186 128.071V129.874C258.186 130.36 258.581 130.755 259.068 130.755H270.96C271.447 130.755 271.842 130.36 271.842 129.874V128.071C271.842 127.585 271.447 127.191 270.96 127.191Z"
        fill="#FFE7B0"
      />
      <path
        d="M263.433 50.4487H251.541C251.054 50.4487 250.659 50.8431 250.659 51.3295V53.1321C250.659 53.6185 251.054 54.0128 251.541 54.0128H263.433C263.92 54.0128 264.315 53.6185 264.315 53.1321V51.3295C264.315 50.8431 263.92 50.4487 263.433 50.4487Z"
        fill="#FFE7B0"
      />
      <path
        d="M266.671 23.9943H253.774C253.287 23.9943 252.892 24.3886 252.892 24.875V26.6776C252.892 27.164 253.287 27.5583 253.774 27.5583H266.671C267.158 27.5583 267.552 27.164 267.552 26.6776V24.875C267.552 24.3886 267.158 23.9943 266.671 23.9943Z"
        fill="#FFF7E6"
      />
      <path
        d="M259.341 53.9541H246.444C245.957 53.9541 245.562 54.3484 245.562 54.8348V56.6374C245.562 57.1238 245.957 57.5182 246.444 57.5182H259.341C259.828 57.5182 260.222 57.1238 260.222 56.6374V54.8348C260.222 54.3484 259.828 53.9541 259.341 53.9541Z"
        fill="#FFF7E6"
      />
      <path
        d="M63.2752 37.0515C65.4184 34.9099 65.4184 31.4375 63.2752 29.2959C61.132 27.1542 57.6572 27.1542 55.514 29.2959C53.3709 31.4375 53.3709 34.9099 55.5141 37.0515C57.6572 39.1932 61.132 39.1932 63.2752 37.0515Z"
        fill="#FEEB9B"
      />
      <path
        d="M65.1738 2.60693H53.6103C52.9613 2.60693 52.4352 3.1327 52.4352 3.78125V4.48585C52.4352 5.13441 52.9613 5.66017 53.6103 5.66017H65.1738C65.8228 5.66017 66.349 5.13441 66.349 4.48585V3.78125C66.349 3.1327 65.8228 2.60693 65.1738 2.60693Z"
        fill="#FEEB9B"
      />
      <path
        d="M59.9884 5.66016H58.4608V20.4977H59.9884V5.66016Z"
        fill="#F47F56"
      />
      <path
        d="M83.8293 32.3671C83.8293 32.3671 71.2229 35.7257 59.392 35.758H59.2216C47.5759 35.7257 35.181 32.4699 34.7932 32.3671C35.3984 32.508 37.6811 30.8288 38.1923 30.5557C38.9004 30.1799 39.6084 29.8042 40.3164 29.4284C40.7013 29.2258 41.0832 29.0232 41.4622 28.8207C42.937 28.0397 44.4118 27.2559 45.8866 26.475C49.0507 24.7986 52.2119 23.1193 55.373 21.4401C56.366 20.9116 57.3532 20.3068 58.3785 19.8459C60.2969 18.9857 62.1948 20.8206 63.8282 21.7013C65.6144 22.6643 67.386 23.6448 69.1781 24.596C71.8427 26.0111 74.5074 27.4232 77.172 28.8383C77.554 29.0409 77.9359 29.2434 78.3178 29.446C80.1511 30.4207 81.9872 31.3954 83.8234 32.3671H83.8293Z"
        fill="#FBAE61"
      />
      <path
        d="M78.3267 29.446C74.1431 30.5704 68.0471 31.5245 59.395 31.5245C50.743 31.5245 44.547 30.5616 40.3223 29.4313C40.7072 29.2287 41.0891 29.0262 41.4681 28.8236C45.5958 29.8423 51.4157 30.6761 59.395 30.6761C67.3743 30.6761 73.1002 29.8482 77.1809 28.8383C77.5628 29.0409 77.9447 29.2434 78.3267 29.446Z"
        fill="#F47F56"
      />
      <path
        d="M41.2536 48.7225H22.6833C22.1965 48.7225 21.8019 49.1169 21.8019 49.6033V51.4059C21.8019 51.8923 22.1965 52.2866 22.6833 52.2866H41.2536C41.7404 52.2866 42.135 51.8923 42.135 51.4059V49.6033C42.135 49.1169 41.7404 48.7225 41.2536 48.7225Z"
        fill="#FFE7B0"
      />
      <path
        d="M46.909 52.1721H34.0117C33.525 52.1721 33.1304 52.5664 33.1304 53.0529V54.8554C33.1304 55.3419 33.525 55.7362 34.0117 55.7362H46.909C47.3958 55.7362 47.7904 55.3419 47.7904 54.8554V53.0529C47.7904 52.5664 47.3958 52.1721 46.909 52.1721Z"
        fill="#FFF7E6"
      />
      <path
        d="M103.087 63.425H90.1898C89.7031 63.425 89.3085 63.8194 89.3085 64.3058V66.1084C89.3085 66.5948 89.7031 66.9891 90.1898 66.9891H103.087C103.574 66.9891 103.968 66.5948 103.968 66.1084V64.3058C103.968 63.8194 103.574 63.425 103.087 63.425Z"
        fill="#FFE7B0"
      />
      <path
        d="M100.099 32.6637H91.6176C91.1309 32.6637 90.7363 33.058 90.7363 33.5444V34.023C90.7363 34.5094 91.1309 34.9037 91.6176 34.9037H100.099C100.586 34.9037 100.981 34.5094 100.981 34.023V33.5444C100.981 33.058 100.586 32.6637 100.099 32.6637Z"
        fill="#FFF7E6"
      />
      <path
        d="M106.075 34.9712H97.5933C97.1065 34.9712 96.7119 35.3655 96.7119 35.8519V36.3305C96.7119 36.8169 97.1065 37.2112 97.5933 37.2112H106.075C106.562 37.2112 106.956 36.8169 106.956 36.3305V35.8519C106.956 35.3655 106.562 34.9712 106.075 34.9712Z"
        fill="#FFE7B0"
      />
      <path
        d="M106.707 93.5581H98.2249C97.7381 93.5581 97.3435 93.9524 97.3435 94.4388V94.9174C97.3435 95.4038 97.7381 95.7981 98.2249 95.7981H106.707C107.193 95.7981 107.588 95.4038 107.588 94.9174V94.4388C107.588 93.9524 107.193 93.5581 106.707 93.5581Z"
        fill="#FFF7E6"
      />
      <path
        d="M101.586 95.7981H93.1042C92.6174 95.7981 92.2228 96.1924 92.2228 96.6788V97.1574C92.2228 97.6438 92.6174 98.0381 93.1042 98.0381H101.586C102.073 98.0381 102.467 97.6438 102.467 97.1574V96.6788C102.467 96.1924 102.073 95.7981 101.586 95.7981Z"
        fill="#FFE7B0"
      />
      <path
        d="M109.364 124.228C109.364 120.511 106.601 117.372 102.908 116.914C96.9022 116.167 86.3877 115.283 70.2364 115.283C54.0851 115.283 43.2967 116.179 37.2101 116.929C33.5118 117.384 30.7424 120.523 30.7395 124.246C30.7365 130.694 30.9479 141.178 32.0884 151.355C33.2974 162.112 35.7183 171.629 37.225 176.866C38.032 179.663 40.4023 181.731 43.2877 182.127C47.6412 182.722 55.702 183.365 70.2334 183.365C84.7649 183.365 92.4743 182.743 96.7771 182.154C99.6745 181.758 102.06 179.696 102.867 176.89C104.376 171.658 106.8 162.13 108.012 151.355C109.156 141.169 109.367 130.677 109.361 124.228H109.364Z"
        fill="#FFB100"
      />
      <path
        d="M121.219 176.137L126.717 184.211C127.317 185.093 128.519 185.322 129.402 184.722C130.284 184.122 130.513 182.921 129.912 182.039L124.415 173.964C123.815 173.082 122.613 172.854 121.73 173.454C120.848 174.054 120.619 175.255 121.219 176.137Z"
        fill="#E8A100"
      />
      <path
        d="M128.803 186.138L121.752 194.339C121.362 194.792 120.921 195.196 120.438 195.541C119.831 195.976 119.158 196.315 118.437 196.532C117.904 196.69 117.365 196.8 116.823 196.86C114.715 197.092 112.574 196.58 110.796 195.408C110.508 195.217 110.231 195.009 109.963 194.783C109.343 194.265 108.786 193.655 108.313 192.956C108.28 192.911 108.25 192.872 108.23 192.84L106.026 189.516C103.76 186.088 104.117 181.698 106.613 178.693C107.482 177.642 108.664 176.896 109.966 176.491L117.723 174.069H117.729L120.292 173.265C120.909 173.075 121.564 173.224 122.031 173.631C122.154 173.735 122.261 173.857 122.353 173.997L128.94 183.951C129.39 184.626 129.333 185.522 128.803 186.138Z"
        fill="#FFCB54"
      />
      <path
        d="M141.464 162.808C140.092 161.972 138.332 162.112 137.084 163.124C134.359 165.338 133.374 168.522 131.927 167.897C130.47 167.272 135.151 164.835 135.461 162.579C135.556 161.892 134.937 161.327 134.255 161.449C130.432 162.127 127.123 164.933 126.04 169.001C124.708 173.997 127.35 179.297 132.144 181.24C137.772 183.52 144.058 180.538 145.91 174.866C147.435 170.209 145.475 165.252 141.461 162.805L141.464 162.808Z"
        fill="#212529"
      />
      <path
        d="M120.438 195.541C119.831 195.976 119.158 196.315 118.437 196.532C117.904 196.69 117.365 196.8 116.823 196.86C114.715 197.092 112.574 196.58 110.796 195.408C110.552 195.164 110.231 195.009 109.963 194.783C109.182 193.964 108.575 193.298 108.313 192.956C108.28 192.911 108.25 192.872 108.23 192.84L106.026 189.516C103.76 186.088 104.117 181.698 106.613 178.693C107.482 177.642 108.664 176.896 109.966 176.491L117.723 174.069H117.729L120.292 173.265C120.909 173.075 121.564 173.224 122.031 173.631C120.468 178.029 115.239 186.367 120.438 195.541Z"
        fill="#E8A100"
      />
      <path
        d="M36.7575 216.616C35.8672 217.532 34.8637 218.231 33.8006 218.731C33.1098 219.059 32.27 218.722 32.0408 217.996C32.0408 217.993 32.0348 217.993 32.0378 217.99C31.8174 217.291 31.9663 216.467 32.3296 215.824C33.1515 214.36 33.4016 210.715 31.2665 210.956C29.1315 211.203 27.6873 213.399 29.5841 216.217C30.1112 217.002 30.141 217.687 29.9057 218.267C29.7568 218.645 29.4948 218.981 29.1881 219.267C28.9022 219.532 28.5151 219.657 28.128 219.606C25.2991 219.225 22.637 217.663 20.9456 215.038C18.1763 210.733 19.1054 204.945 23.0837 201.722C27.8779 197.836 34.9977 199.068 38.1898 204.409C40.5155 208.304 39.9259 213.36 36.7605 216.613L36.7575 216.616Z"
        fill="#212529"
      />
      <path
        d="M41.3232 199.158L32.7394 194.489C31.8019 193.979 30.6283 194.325 30.1181 195.262C29.6078 196.199 29.9541 197.371 30.8916 197.881L39.4753 202.55C40.4128 203.06 41.5864 202.714 42.0967 201.777C42.6069 200.841 42.2606 199.668 41.3232 199.158Z"
        fill="#E8A100"
      />
      <path
        d="M53.1411 190.215C52.8463 191.545 52.1406 192.747 51.1698 193.702L43.6986 201.05C43.6182 201.13 43.5379 201.21 43.4575 201.288C42.8827 201.859 41.9984 201.975 41.2926 201.579L30.8794 195.741C30.1677 195.339 29.8104 194.521 30.001 193.732L32.5202 183.216C32.8358 181.904 33.4939 180.687 34.4676 179.752C37.2816 177.038 41.65 176.357 45.2352 178.369L48.7132 180.324C49.1718 180.58 49.5976 180.868 49.9906 181.187C52.6885 183.365 53.8826 186.888 53.1441 190.215H53.1411Z"
        fill="#FFCB54"
      />
      <path
        d="M53.1411 190.215C52.8463 191.545 52.1406 192.747 51.1699 193.702L43.6987 201.05C56.5477 188.766 48.7132 180.324 48.7132 180.324L49.9907 181.187C52.6885 183.365 53.8826 186.888 53.1441 190.215H53.1411Z"
        fill="#E8A100"
      />
      <path
        d="M95.4937 207.801H63.4382C57.3874 207.801 52.9833 202.067 54.5466 196.226L56.8872 187.489C57.2356 186.189 58.4147 185.284 59.7637 185.284H99.1713C100.52 185.284 101.699 186.189 102.048 187.489L104.388 196.226C105.955 202.067 101.55 207.801 95.4967 207.801H95.4937Z"
        fill="#FFCB54"
      />
      <path
        d="M64.7157 208.596C68.9351 208.596 72.3625 212.021 72.3625 216.238V223.879H57.0688V216.238C57.0688 212.021 60.4962 208.596 64.7157 208.596Z"
        fill="#FFCB54"
      />
      <path
        d="M54.228 224.596V222.972C54.228 218.91 57.5274 215.616 61.589 215.616H67.2617C71.0851 215.616 74.1909 218.719 74.1909 222.54V223.975C74.1909 227.795 71.0851 230.899 67.2617 230.899H60.5319C57.0509 230.899 54.228 228.075 54.228 224.599V224.596Z"
        fill="#FFCB54"
      />
      <path
        d="M57.1432 216.776C57.1432 216.776 63.6824 210.578 70.3526 216.669C70.3526 216.669 64.3494 213.557 57.1432 216.776Z"
        fill="#E8A100"
      />
      <path
        d="M100.672 223.876H85.3783V216.235C85.3783 212.018 88.8057 208.593 93.0252 208.593C97.2447 208.593 100.672 212.018 100.672 216.235V223.876Z"
        fill="#FFCB54"
      />
      <path
        d="M85.3784 226.834V215.616H97.6944C101.914 215.616 105.341 219.041 105.341 223.257C105.341 227.474 101.914 230.899 97.6944 230.899H89.443C87.2007 230.899 85.3784 229.078 85.3784 226.837V226.834Z"
        fill="#FFCB54"
      />
      <path
        d="M100.672 216.238C100.672 216.238 93.6446 210.599 87.4985 217.217C87.4985 217.217 93.2248 213.622 100.672 216.238Z"
        fill="#E8A100"
      />
      <path
        d="M103.948 195.774H55.1125C54.1422 195.774 53.3556 196.56 53.3556 197.529V199.722C53.3556 200.692 54.1422 201.478 55.1125 201.478H103.948C104.918 201.478 105.705 200.692 105.705 199.722V197.529C105.705 196.56 104.918 195.774 103.948 195.774Z"
        fill="#FFB100"
      />
      <path
        d="M79.0149 109.016C78.9583 105.707 76.2605 103.041 72.9343 103.041C69.6082 103.041 66.9103 105.707 66.8537 109.016H66.8478V123.431H79.0476V109.016H79.0119H79.0149Z"
        fill="#FFB100"
      />
      <path
        d="M104.966 109.016C104.909 105.707 102.212 103.041 98.8854 103.041C95.5593 103.041 92.8614 105.707 92.8048 109.016H92.7989V123.431H104.999V109.016H104.963H104.966Z"
        fill="#FFB100"
      />
      <path
        d="M78.4372 121.833H67.4821V109.01H67.5029C67.7173 106.201 70.0965 103.963 72.9492 103.963C75.8019 103.963 78.1781 106.198 78.3955 109.01H78.4402V121.833H78.4372ZM68.1372 121.178H77.7821V109.614L77.7523 109.343C77.7077 106.74 75.5518 104.618 72.9462 104.618C70.3407 104.618 68.1848 106.737 68.1401 109.343L68.1342 109.602V121.181L68.1372 121.178Z"
        fill="#E8A100"
      />
      <path
        d="M104.391 121.833H93.4362V109.01H93.457C93.6714 106.201 96.0506 103.963 98.9033 103.963C101.756 103.963 104.132 106.198 104.35 109.01H104.394V121.833H104.391ZM94.0913 121.178H103.739V109.614L103.709 109.343C103.665 106.74 101.509 104.618 98.9033 104.618C96.2978 104.618 94.1419 106.737 94.0972 109.343L94.0913 109.602V121.181V121.178Z"
        fill="#E8A100"
      />
      <path
        d="M125.471 124.228C125.471 120.511 122.707 117.372 119.015 116.914C113.009 116.167 102.494 115.283 86.3431 115.283C70.1917 115.283 59.4033 116.179 53.3168 116.929C49.6185 117.384 46.8491 120.523 46.8462 124.246C46.8432 130.694 47.0546 141.178 48.1951 151.355C49.4041 162.112 51.825 171.629 53.3317 176.866C54.1387 179.663 56.509 181.731 59.3944 182.127C63.7479 182.722 71.8087 183.365 86.3401 183.365C100.872 183.365 108.581 182.743 112.884 182.154C115.781 181.758 118.166 179.696 118.973 176.89C120.483 171.658 122.907 162.13 124.119 151.355C125.262 141.169 125.474 130.677 125.468 124.228H125.471Z"
        fill="#FFCB54"
      />
      <path
        d="M115.668 130.486C115.671 135.328 115.513 143.204 114.656 150.849C113.747 158.937 111.925 166.091 110.793 170.019C110.186 172.125 108.396 173.673 106.223 173.967C103.23 174.378 97.8878 174.809 88.5585 174.869H88.5496C87.823 174.875 87.0726 174.878 86.2984 174.878C75.3879 174.878 69.3371 174.396 66.0705 173.947C63.9027 173.652 62.122 172.099 61.5175 170.001C60.386 166.07 58.5666 158.925 57.6614 150.849C56.8038 143.21 56.6459 135.339 56.6459 130.501C56.6489 127.704 58.7274 125.35 61.5056 125.008C66.0735 124.442 74.0896 123.77 86.2984 123.77C98.5072 123.77 106.315 124.433 110.823 124.996C113.592 125.341 115.668 127.695 115.668 130.486Z"
        fill="#2B3036"
      />
      <path
        d="M120.045 172.988L116.29 187.257C116.007 188.334 114.682 188.73 113.855 187.986L107.947 182.677L120.045 172.991V172.988Z"
        fill="#FFCB54"
      />
      <path
        d="M115.668 130.486C115.671 135.328 115.513 143.204 114.656 150.849C113.747 158.937 111.925 166.091 110.793 170.019C110.186 172.125 108.396 173.673 106.223 173.967C103.23 174.378 97.8878 174.809 88.5585 174.869C89.8836 174.723 106.916 173.741 108.623 167.575C110.397 161.163 115.108 135.574 111.967 130.117C108.325 123.794 60.3205 124.195 56.6459 130.501C56.6489 127.704 58.7274 125.35 61.5056 125.008C66.0735 124.442 74.0896 123.77 86.2984 123.77C98.5072 123.77 106.315 124.433 110.823 124.996C113.592 125.341 115.668 127.695 115.668 130.486Z"
        fill="#485059"
      />
      <path
        d="M79.262 150.519C79.262 150.519 79.262 150.54 79.262 150.548C79.262 154.438 82.4155 157.589 86.3074 157.589C90.1993 157.589 93.3528 154.438 93.3528 150.548C93.3528 150.54 93.3528 150.528 93.3528 150.519H79.262Z"
        fill="white"
      />
      <path
        d="M74.3219 147.942C75.7724 147.942 76.9483 146.767 76.9483 145.317C76.9483 143.868 75.7724 142.693 74.3219 142.693C72.8714 142.693 71.6956 143.868 71.6956 145.317C71.6956 146.767 72.8714 147.942 74.3219 147.942Z"
        fill="white"
      />
      <path
        d="M98.0249 147.942C99.4754 147.942 100.651 146.767 100.651 145.317C100.651 143.868 99.4754 142.693 98.0249 142.693C96.5744 142.693 95.3985 143.868 95.3985 145.317C95.3985 146.767 96.5744 147.942 98.0249 147.942Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.639 178.893H225.2C224.322 178.893 223.61 179.604 223.61 180.481V182.865C221.749 183.284 220 183.999 218.406 184.941L218.403 184.943L216.652 183.196C216.031 182.576 215.024 182.576 214.403 183.196L210.557 187.035C209.936 187.655 209.936 188.661 210.557 189.281L212.245 190.966C211.252 192.534 210.509 194.26 210.04 196.104L210.039 196.108H207.559C206.681 196.108 205.968 196.819 205.968 197.696V203.126C205.968 204.003 206.681 204.714 207.559 204.714H209.948C210.364 206.569 211.08 208.314 212.027 209.905L212.028 209.906L210.275 211.656C209.654 212.276 209.654 213.282 210.275 213.902L214.121 217.741C214.742 218.361 215.749 218.361 216.37 217.741L218.054 216.06L218.055 216.061C219.627 217.045 221.356 217.795 223.205 218.264L223.213 218.266V220.737C223.213 221.614 223.925 222.325 224.804 222.325H230.242C231.121 222.325 231.833 221.614 231.833 220.737V218.352C233.692 217.933 235.44 217.222 237.025 216.276L237.03 216.274L238.787 218.029C239.409 218.649 240.416 218.649 241.037 218.029L244.883 214.189C245.504 213.569 245.504 212.564 244.883 211.944L243.192 210.255L243.192 210.255C244.186 208.686 244.937 206.959 245.407 205.114L247.884 205.114C248.762 205.114 249.475 204.403 249.475 203.526V198.096C249.475 197.219 248.762 196.508 247.884 196.508H245.499C245.08 194.651 244.363 192.905 243.412 191.313C243.412 191.313 243.411 191.312 243.411 191.312L245.167 189.559C245.789 188.939 245.789 187.933 245.167 187.313L241.322 183.474C240.7 182.854 239.693 182.854 239.072 183.474L237.385 185.158C237.385 185.157 237.384 185.157 237.384 185.157C235.812 184.172 234.075 183.423 232.23 182.954L232.23 182.954V180.481C232.23 179.604 231.518 178.893 230.639 178.893ZM217.597 200.611C217.597 195.028 222.131 190.506 227.719 190.506C233.308 190.506 237.85 195.028 237.85 200.611C237.85 206.194 233.316 210.716 227.719 210.716C222.123 210.716 217.597 206.194 217.597 200.611Z"
        fill="#FFE7B0"
      />
      <path
        d="M220.547 230.754H152.34C150.132 230.754 148.592 228.969 148.896 226.766L165.598 105.595C165.902 103.392 167.936 101.608 170.143 101.608H238.351C240.558 101.608 242.098 103.392 241.794 105.595L225.093 226.766C224.788 228.969 222.755 230.754 220.547 230.754Z"
        fill="#E8A100"
      />
      <path
        d="M217.936 229.031H149.728C147.521 229.031 145.98 227.247 146.284 225.043L162.986 103.873C163.29 101.669 165.324 99.885 167.531 99.885H235.739C237.946 99.885 239.487 101.669 239.182 103.873L222.481 225.043C222.177 227.247 220.143 229.031 217.936 229.031Z"
        fill="white"
      />
      <path
        d="M236.401 105.257L220.174 222.955C219.974 224.401 218.641 225.57 217.193 225.57H151.561C150.109 225.57 149.1 224.397 149.3 222.955L152.571 199.242L160.586 141.079L165.524 105.257C165.725 103.811 167.057 102.642 168.509 102.642H185.45C186.582 102.642 187.372 103.557 187.214 104.684C187.141 105.249 187.299 105.761 187.618 106.13C187.934 106.499 188.416 106.726 188.982 106.726H212.54C213.672 106.726 214.712 105.807 214.87 104.684C214.951 104.119 215.247 103.607 215.667 103.238C216.087 102.869 216.634 102.638 217.196 102.638H234.137C235.589 102.638 236.598 103.811 236.398 105.253L236.401 105.257Z"
        fill="#FFE7B0"
      />
      <path
        d="M205.656 104.088H192.764C192.529 104.088 192.337 103.896 192.337 103.661C192.337 103.427 192.529 103.234 192.764 103.234H205.656C205.891 103.234 206.084 103.427 206.084 103.661C206.084 103.896 205.891 104.088 205.656 104.088Z"
        fill="#E8A100"
      />
      <path
        d="M209.414 104.043C209.635 103.837 209.668 103.514 209.488 103.321C209.308 103.128 208.982 103.139 208.761 103.345C208.54 103.551 208.507 103.875 208.687 104.068C208.867 104.26 209.193 104.25 209.414 104.043Z"
        fill="#E8A100"
      />
      <path
        d="M225.952 130.155H169.11L170.489 120.149H227.33L225.952 130.155Z"
        fill="#E8A100"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.5}
      >
        <path
          d="M227.928 115.831H171.082L171.552 112.428H228.394L227.928 115.831Z"
          fill="#8391FC"
        />
      </g>
      <path
        d="M213.668 219.256H156.827L158.206 209.251H215.047L213.668 219.256Z"
        fill="#FFB100"
      />
      <path
        d="M176.282 213.088H175.354L175.4 212.753H177.661L177.615 213.088H176.683L176.309 215.803H175.909L176.282 213.088Z"
        fill="#31374C"
      />
      <path
        d="M181.19 214.25C181.043 215.3 180.331 215.857 179.549 215.857C178.767 215.857 178.262 215.234 178.389 214.311C178.524 213.342 179.214 212.712 180.03 212.712C180.847 212.712 181.317 213.35 181.19 214.254V214.25ZM178.813 214.3C178.724 214.95 178.998 215.534 179.618 215.534C180.238 215.534 180.677 214.961 180.77 214.269C180.855 213.661 180.623 213.031 179.965 213.031C179.306 213.031 178.902 213.631 178.809 214.3H178.813Z"
        fill="#31374C"
      />
      <path
        d="M182.804 212.792C183 212.762 183.25 212.735 183.57 212.735C183.959 212.735 184.233 212.827 184.39 212.989C184.537 213.135 184.606 213.354 184.572 213.627C184.533 213.904 184.421 214.119 184.248 214.277C184.009 214.5 183.655 214.611 183.27 214.611C183.15 214.611 183.042 214.607 182.958 214.584L182.788 215.803H182.395L182.811 212.792H182.804ZM182.996 214.261C183.081 214.284 183.189 214.292 183.32 214.292C183.797 214.292 184.117 214.061 184.175 213.642C184.229 213.238 183.971 213.046 183.535 213.046C183.362 213.046 183.231 213.058 183.158 213.077L182.996 214.261Z"
        fill="#31374C"
      />
      <path
        d="M188.385 212.758L188.134 214.561C188.042 215.246 188.304 215.534 188.712 215.534C189.167 215.534 189.498 215.234 189.59 214.561L189.841 212.758H190.241L189.995 214.534C189.868 215.469 189.317 215.853 188.658 215.853C188.034 215.853 187.611 215.496 187.742 214.55L187.988 212.754H188.389L188.385 212.758Z"
        fill="#31374C"
      />
      <path
        d="M191.821 212.792C192.017 212.762 192.267 212.735 192.587 212.735C192.976 212.735 193.25 212.827 193.408 212.989C193.554 213.135 193.623 213.354 193.589 213.627C193.55 213.904 193.438 214.119 193.265 214.277C193.026 214.5 192.672 214.611 192.287 214.611C192.167 214.611 192.059 214.607 191.975 214.584L191.805 215.803H191.412L191.828 212.792H191.821ZM192.013 214.261C192.098 214.284 192.206 214.292 192.337 214.292C192.814 214.292 193.134 214.061 193.192 213.642C193.246 213.238 192.988 213.046 192.552 213.046C192.379 213.046 192.248 213.058 192.175 213.077L192.013 214.261Z"
        fill="#31374C"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.5}
      >
        <path
          d="M224.118 141.079L216.099 199.242H152.567L160.582 141.079H224.118Z"
          fill="#B57E00"
        />
      </g>
      <path
        d="M212.104 194.939H130.839L138.854 136.78H220.124L212.104 194.939Z"
        fill="#FFC133"
      />
      <path
        d="M174.861 155.053H142.329L144.031 142.702H176.564L174.861 155.053Z"
        fill="#212529"
      />
      <path
        d="M211.615 155.053H179.079L180.781 142.702H213.318L211.615 155.053Z"
        fill="white"
      />
      <path
        d="M172.519 172.037H139.987L141.689 159.686H174.222L172.519 172.037Z"
        fill="white"
      />
      <path
        d="M209.273 172.037H176.737L178.439 159.686H210.976L209.273 172.037Z"
        fill="white"
      />
      <path
        d="M170.181 189.021H137.645L139.347 176.67H171.884L170.181 189.021Z"
        fill="white"
      />
      <path
        d="M206.931 189.021H174.399L176.101 176.67H208.634L206.931 189.021Z"
        fill="white"
      />
      <path
        d="M152.301 147.927H152.289L151.685 148.227L151.646 147.896L152.405 147.519H152.779L152.336 150.726H151.912L152.297 147.923L152.301 147.927Z"
        fill="white"
      />
      <path
        d="M155.902 149.092C155.752 150.185 155.259 150.784 154.543 150.784C153.911 150.784 153.561 150.196 153.695 149.135C153.846 148.058 154.393 147.466 155.051 147.466C155.71 147.466 156.041 148.07 155.899 149.089L155.902 149.092ZM154.135 149.142C154.019 149.977 154.215 150.45 154.612 150.45C155.063 150.45 155.348 149.931 155.46 149.112C155.567 148.323 155.436 147.804 154.982 147.804C154.597 147.804 154.254 148.27 154.135 149.142Z"
        fill="white"
      />
      <path
        d="M158.464 149.092C158.314 150.185 157.821 150.784 157.104 150.784C156.473 150.784 156.122 150.196 156.257 149.135C156.407 148.058 156.954 147.466 157.613 147.466C158.271 147.466 158.603 148.07 158.46 149.089L158.464 149.092ZM156.696 149.142C156.58 149.977 156.777 150.45 157.174 150.45C157.624 150.45 157.909 149.931 158.021 149.112C158.129 148.323 157.998 147.804 157.543 147.804C157.158 147.804 156.815 148.27 156.696 149.142Z"
        fill="white"
      />
      <path
        d="M158.414 151.338C158.564 151.046 158.772 150.519 158.876 150.158L159.373 150.108C159.2 150.527 158.907 151.073 158.734 151.307L158.414 151.338Z"
        fill="white"
      />
      <path
        d="M162.054 149.092C161.904 150.185 161.411 150.784 160.694 150.784C160.059 150.784 159.712 150.196 159.847 149.135C159.993 148.058 160.544 147.466 161.203 147.466C161.861 147.466 162.193 148.07 162.05 149.089L162.054 149.092ZM160.286 149.142C160.17 149.977 160.367 150.45 160.764 150.45C161.214 150.45 161.499 149.931 161.611 149.112C161.719 148.323 161.588 147.804 161.133 147.804C160.748 147.804 160.405 148.27 160.286 149.142Z"
        fill="white"
      />
      <path
        d="M164.615 149.092C164.465 150.185 163.972 150.784 163.256 150.784C162.62 150.784 162.273 150.196 162.408 149.135C162.555 148.058 163.105 147.466 163.764 147.466C164.423 147.466 164.754 148.07 164.611 149.089L164.615 149.092ZM162.847 149.142C162.732 149.977 162.928 150.45 163.325 150.45C163.776 150.45 164.061 149.931 164.172 149.112C164.28 148.323 164.149 147.804 163.695 147.804C163.31 147.804 162.967 148.27 162.847 149.142Z"
        fill="white"
      />
      <path
        d="M188.2 150.669L188.238 150.404L188.628 150.073C189.563 149.293 189.995 148.877 190.068 148.397C190.114 148.07 189.995 147.77 189.51 147.77C189.217 147.77 188.951 147.916 188.782 148.043L188.685 147.735C188.936 147.547 189.275 147.409 189.648 147.409C190.345 147.409 190.577 147.882 190.511 148.343C190.43 148.935 189.929 149.412 189.155 150.066L188.862 150.304V150.316H190.318L190.268 150.677H188.196L188.2 150.669Z"
        fill="#31374C"
      />
      <path
        d="M193.142 149.031C192.992 150.123 192.499 150.723 191.782 150.723C191.147 150.723 190.8 150.135 190.935 149.073C191.081 147.997 191.632 147.404 192.291 147.404C192.949 147.404 193.281 148.008 193.138 149.027L193.142 149.031ZM191.374 149.081C191.258 149.915 191.455 150.388 191.851 150.388C192.302 150.388 192.587 149.869 192.699 149.05C192.807 148.262 192.676 147.743 192.221 147.743C191.836 147.743 191.493 148.208 191.374 149.081Z"
        fill="#31374C"
      />
      <path
        d="M195.703 149.031C195.553 150.123 195.06 150.723 194.344 150.723C193.708 150.723 193.361 150.135 193.496 149.073C193.643 147.997 194.193 147.404 194.852 147.404C195.511 147.404 195.842 148.008 195.699 149.027L195.703 149.031ZM193.935 149.081C193.82 149.915 194.016 150.388 194.413 150.388C194.864 150.388 195.149 149.869 195.26 149.05C195.368 148.262 195.237 147.743 194.783 147.743C194.398 147.743 194.055 148.208 193.935 149.081Z"
        fill="#31374C"
      />
      <path
        d="M195.653 151.273C195.803 150.98 196.011 150.454 196.115 150.092L196.612 150.042C196.439 150.461 196.146 151.011 195.973 151.242L195.653 151.273Z"
        fill="#31374C"
      />
      <path
        d="M199.297 149.031C199.147 150.123 198.654 150.723 197.937 150.723C197.306 150.723 196.955 150.135 197.094 149.073C197.24 147.997 197.791 147.404 198.45 147.404C199.108 147.404 199.44 148.008 199.297 149.027V149.031ZM197.529 149.081C197.413 149.915 197.61 150.388 198.007 150.388C198.457 150.388 198.742 149.869 198.854 149.05C198.962 148.262 198.831 147.743 198.376 147.743C197.991 147.743 197.648 148.208 197.529 149.081Z"
        fill="#31374C"
      />
      <path
        d="M201.858 149.031C201.708 150.123 201.215 150.723 200.499 150.723C199.867 150.723 199.517 150.135 199.655 149.073C199.802 147.997 200.352 147.404 201.011 147.404C201.67 147.404 202.001 148.008 201.858 149.027V149.031ZM200.09 149.081C199.975 149.915 200.171 150.388 200.568 150.388C201.019 150.388 201.304 149.869 201.415 149.05C201.523 148.262 201.392 147.743 200.938 147.743C200.553 147.743 200.21 148.208 200.09 149.081Z"
        fill="#31374C"
      />
      <path
        d="M148.834 166.904C148.95 166.985 149.22 167.108 149.524 167.108C150.086 167.108 150.31 166.754 150.344 166.485C150.402 166.035 150.017 165.843 149.593 165.843H149.351L149.397 165.516H149.639C149.959 165.516 150.387 165.354 150.437 164.974C150.471 164.716 150.34 164.489 149.936 164.489C149.678 164.489 149.412 164.601 149.258 164.701L149.189 164.385C149.374 164.262 149.72 164.139 150.071 164.139C150.71 164.139 150.949 164.516 150.895 164.904C150.849 165.235 150.61 165.516 150.194 165.658V165.67C150.579 165.75 150.864 166.046 150.803 166.492C150.733 167.004 150.267 167.454 149.489 167.454C149.123 167.454 148.823 167.342 148.677 167.238L148.842 166.904H148.834Z"
        fill="#31374C"
      />
      <path
        d="M153.603 165.762C153.453 166.854 152.96 167.453 152.243 167.453C151.608 167.453 151.261 166.865 151.396 165.804C151.546 164.727 152.093 164.135 152.752 164.135C153.41 164.135 153.742 164.739 153.599 165.758L153.603 165.762ZM151.835 165.812C151.719 166.646 151.916 167.119 152.313 167.119C152.763 167.119 153.048 166.6 153.16 165.781C153.268 164.992 153.137 164.473 152.682 164.473C152.297 164.473 151.954 164.939 151.835 165.812Z"
        fill="#31374C"
      />
      <path
        d="M156.164 165.762C156.014 166.854 155.521 167.453 154.805 167.453C154.169 167.453 153.823 166.865 153.957 165.804C154.108 164.727 154.655 164.135 155.313 164.135C155.972 164.135 156.303 164.739 156.161 165.758L156.164 165.762ZM154.396 165.812C154.281 166.646 154.477 167.119 154.874 167.119C155.325 167.119 155.61 166.6 155.721 165.781C155.829 164.992 155.698 164.473 155.244 164.473C154.859 164.473 154.516 164.939 154.396 165.812Z"
        fill="#31374C"
      />
      <path
        d="M156.114 168.007C156.265 167.715 156.473 167.188 156.577 166.827L157.073 166.777C156.9 167.196 156.607 167.746 156.434 167.976L156.114 168.007Z"
        fill="#31374C"
      />
      <path
        d="M159.758 165.762C159.608 166.854 159.115 167.453 158.399 167.453C157.763 167.453 157.416 166.865 157.555 165.804C157.701 164.727 158.252 164.135 158.911 164.135C159.569 164.135 159.901 164.739 159.758 165.758V165.762ZM157.99 165.812C157.875 166.646 158.071 167.119 158.468 167.119C158.919 167.119 159.204 166.6 159.315 165.781C159.423 164.992 159.292 164.473 158.838 164.473C158.452 164.473 158.11 164.939 157.99 165.812Z"
        fill="#31374C"
      />
      <path
        d="M162.32 165.762C162.169 166.854 161.676 167.453 160.96 167.453C160.324 167.453 159.978 166.865 160.116 165.804C160.263 164.727 160.814 164.135 161.472 164.135C162.131 164.135 162.462 164.739 162.32 165.758V165.762ZM160.552 165.812C160.436 166.646 160.633 167.119 161.029 167.119C161.48 167.119 161.765 166.6 161.877 165.781C161.985 164.992 161.854 164.473 161.399 164.473C161.014 164.473 160.671 164.939 160.552 165.812Z"
        fill="#31374C"
      />
      <path
        d="M187.26 167.337L187.379 166.464H185.873L185.912 166.18L187.641 164.13H188.115L187.842 166.126H188.296L188.25 166.464H187.795L187.676 167.337H187.26ZM187.426 166.122L187.572 165.049C187.595 164.88 187.622 164.715 187.657 164.546H187.641C187.514 164.734 187.418 164.872 187.306 165.019L186.363 166.111V166.122H187.426Z"
        fill="#31374C"
      />
      <path
        d="M190.846 165.7C190.696 166.792 190.203 167.392 189.486 167.392C188.851 167.392 188.504 166.804 188.639 165.742C188.785 164.666 189.336 164.073 189.995 164.073C190.654 164.073 190.985 164.677 190.842 165.696L190.846 165.7ZM189.078 165.75C188.963 166.584 189.159 167.057 189.556 167.057C190.006 167.057 190.291 166.538 190.403 165.719C190.511 164.931 190.38 164.412 189.926 164.412C189.54 164.412 189.198 164.877 189.078 165.75Z"
        fill="#31374C"
      />
      <path
        d="M193.404 165.7C193.253 166.792 192.76 167.392 192.044 167.392C191.408 167.392 191.062 166.804 191.197 165.742C191.343 164.666 191.894 164.073 192.552 164.073C193.211 164.073 193.542 164.677 193.4 165.696L193.404 165.7ZM191.636 165.75C191.52 166.584 191.717 167.057 192.113 167.057C192.564 167.057 192.849 166.538 192.961 165.719C193.069 164.931 192.938 164.412 192.483 164.412C192.098 164.412 191.755 164.877 191.636 165.75Z"
        fill="#31374C"
      />
      <path
        d="M193.358 167.946C193.508 167.653 193.716 167.127 193.82 166.765L194.317 166.715C194.143 167.134 193.851 167.68 193.677 167.915L193.358 167.946Z"
        fill="#31374C"
      />
      <path
        d="M196.997 165.7C196.847 166.792 196.354 167.392 195.638 167.392C195.006 167.392 194.656 166.804 194.79 165.742C194.941 164.666 195.488 164.073 196.146 164.073C196.805 164.073 197.136 164.677 196.994 165.696L196.997 165.7ZM195.229 165.75C195.114 166.584 195.31 167.057 195.707 167.057C196.158 167.057 196.443 166.538 196.555 165.719C196.662 164.931 196.531 164.412 196.077 164.412C195.692 164.412 195.349 164.877 195.229 165.75Z"
        fill="#31374C"
      />
      <path
        d="M199.559 165.7C199.409 166.792 198.916 167.392 198.199 167.392C197.568 167.392 197.217 166.804 197.352 165.742C197.502 164.666 198.049 164.073 198.708 164.073C199.366 164.073 199.698 164.677 199.555 165.696L199.559 165.7ZM197.791 165.75C197.675 166.584 197.872 167.057 198.269 167.057C198.719 167.057 199.004 166.538 199.116 165.719C199.224 164.931 199.093 164.412 198.638 164.412C198.253 164.412 197.91 164.877 197.791 165.75Z"
        fill="#31374C"
      />
      <path
        d="M148.58 182.045H147.344L147.105 182.868C147.182 182.857 147.251 182.849 147.371 182.849C147.621 182.849 147.864 182.903 148.045 183.022C148.28 183.164 148.449 183.441 148.395 183.845C148.311 184.472 147.741 184.941 147.036 184.941C146.681 184.941 146.396 184.841 146.254 184.745L146.408 184.414C146.531 184.495 146.781 184.591 147.078 184.591C147.494 184.591 147.883 184.325 147.941 183.895C147.995 183.479 147.756 183.183 147.105 183.183C146.92 183.183 146.774 183.203 146.65 183.218L147.074 181.68H148.626L148.576 182.045H148.58Z"
        fill="#31374C"
      />
      <path
        d="M151.192 183.25C151.041 184.342 150.548 184.941 149.832 184.941C149.196 184.941 148.85 184.353 148.985 183.292C149.135 182.215 149.682 181.623 150.34 181.623C150.999 181.623 151.33 182.227 151.188 183.246L151.192 183.25ZM149.424 183.3C149.308 184.134 149.505 184.607 149.901 184.607C150.352 184.607 150.637 184.088 150.749 183.269C150.857 182.481 150.726 181.961 150.271 181.961C149.886 181.961 149.543 182.427 149.424 183.3Z"
        fill="#31374C"
      />
      <path
        d="M153.753 183.25C153.603 184.342 153.11 184.941 152.393 184.941C151.758 184.941 151.411 184.353 151.546 183.292C151.696 182.215 152.243 181.623 152.902 181.623C153.561 181.623 153.892 182.227 153.749 183.246L153.753 183.25ZM151.985 183.3C151.87 184.134 152.066 184.607 152.463 184.607C152.913 184.607 153.199 184.088 153.31 183.269C153.418 182.481 153.287 181.961 152.833 181.961C152.447 181.961 152.105 182.427 151.985 183.3Z"
        fill="#31374C"
      />
      <path
        d="M153.703 185.495C153.853 185.203 154.061 184.676 154.165 184.315L154.662 184.265C154.489 184.684 154.196 185.234 154.023 185.464L153.703 185.495Z"
        fill="#31374C"
      />
      <path
        d="M157.347 183.25C157.197 184.342 156.704 184.941 155.987 184.941C155.352 184.941 155.005 184.353 155.14 183.292C155.286 182.215 155.837 181.623 156.496 181.623C157.154 181.623 157.486 182.227 157.343 183.246L157.347 183.25ZM155.579 183.3C155.463 184.134 155.66 184.607 156.057 184.607C156.507 184.607 156.792 184.088 156.904 183.269C157.012 182.481 156.881 181.961 156.426 181.961C156.041 181.961 155.698 182.427 155.579 183.3Z"
        fill="#31374C"
      />
      <path
        d="M159.908 183.25C159.758 184.342 159.265 184.941 158.549 184.941C157.913 184.941 157.566 184.353 157.701 183.292C157.848 182.215 158.398 181.623 159.057 181.623C159.716 181.623 160.047 182.227 159.905 183.246L159.908 183.25ZM158.14 183.3C158.025 184.134 158.221 184.607 158.618 184.607C159.069 184.607 159.354 184.088 159.465 183.269C159.573 182.481 159.442 181.961 158.988 181.961C158.603 181.961 158.26 182.427 158.14 183.3Z"
        fill="#31374C"
      />
      <path
        d="M185.743 181.919C185.654 181.915 185.538 181.919 185.411 181.938C184.706 182.054 184.275 182.55 184.125 183.077H184.14C184.321 182.873 184.614 182.707 184.976 182.707C185.55 182.707 185.897 183.119 185.812 183.746C185.731 184.334 185.253 184.876 184.579 184.876C183.905 184.876 183.505 184.342 183.62 183.507C183.709 182.877 184.005 182.377 184.367 182.061C184.672 181.8 185.053 181.638 185.469 181.589C185.6 181.569 185.712 181.565 185.793 181.565L185.743 181.919ZM185.361 183.769C185.423 183.311 185.199 183.034 184.795 183.034C184.529 183.034 184.263 183.196 184.113 183.43C184.075 183.48 184.048 183.542 184.036 183.623C183.975 184.149 184.163 184.542 184.622 184.542C185.003 184.542 185.3 184.23 185.361 183.773V183.769Z"
        fill="#31374C"
      />
      <path
        d="M188.435 183.184C188.285 184.276 187.792 184.876 187.075 184.876C186.44 184.876 186.093 184.288 186.228 183.227C186.374 182.15 186.925 181.558 187.584 181.558C188.242 181.558 188.574 182.161 188.431 183.18L188.435 183.184ZM186.667 183.234C186.551 184.069 186.748 184.542 187.145 184.542C187.595 184.542 187.88 184.023 187.992 183.203C188.1 182.415 187.969 181.896 187.514 181.896C187.129 181.896 186.786 182.361 186.667 183.234Z"
        fill="#31374C"
      />
      <path
        d="M190.996 183.184C190.846 184.276 190.353 184.876 189.637 184.876C189.001 184.876 188.654 184.288 188.789 183.227C188.936 182.15 189.486 181.558 190.145 181.558C190.804 181.558 191.135 182.161 190.993 183.18L190.996 183.184ZM189.228 183.234C189.113 184.069 189.309 184.542 189.706 184.542C190.157 184.542 190.442 184.023 190.553 183.203C190.661 182.415 190.53 181.896 190.076 181.896C189.691 181.896 189.348 182.361 189.228 183.234Z"
        fill="#31374C"
      />
      <path
        d="M190.946 185.43C191.097 185.138 191.305 184.611 191.409 184.249L191.905 184.199C191.732 184.619 191.439 185.165 191.266 185.399L190.946 185.43Z"
        fill="#31374C"
      />
      <path
        d="M194.59 183.184C194.44 184.276 193.947 184.876 193.23 184.876C192.599 184.876 192.248 184.288 192.387 183.227C192.533 182.15 193.084 181.558 193.743 181.558C194.401 181.558 194.733 182.161 194.59 183.18V183.184ZM192.822 183.234C192.707 184.069 192.903 184.542 193.3 184.542C193.75 184.542 194.035 184.023 194.147 183.203C194.255 182.415 194.124 181.896 193.67 181.896C193.284 181.896 192.942 182.361 192.822 183.234Z"
        fill="#31374C"
      />
      <path
        d="M197.148 183.184C196.998 184.276 196.505 184.876 195.788 184.876C195.156 184.876 194.806 184.288 194.945 183.227C195.091 182.15 195.642 181.558 196.3 181.558C196.959 181.558 197.29 182.161 197.148 183.18V183.184ZM195.38 183.234C195.264 184.069 195.461 184.542 195.857 184.542C196.308 184.542 196.593 184.023 196.705 183.203C196.813 182.415 196.682 181.896 196.227 181.896C195.842 181.896 195.499 182.361 195.38 183.234Z"
        fill="#31374C"
      />
      <path
        d="M259.867 172.779H236.918L240.7 148.431H263.649L259.867 172.779Z"
        fill="#FFF7E6"
      />
      <path
        d="M255.653 161.97H244.483L245.369 156.264C245.811 153.407 248.296 150.896 251.189 150.577C254.617 150.196 257.07 152.826 256.562 156.114L255.653 161.97ZM245.526 161.101H254.879L255.641 156.206C256 153.907 254.705 151.849 252.491 151.476C249.64 150.996 246.751 153.249 246.308 156.079L245.526 161.101Z"
        fill="#6B4A00"
      />
      <path
        d="M257.933 157.667H243.547C243.065 157.667 242.614 158.056 242.537 158.54L240.785 169.807C240.712 170.287 241.039 170.679 241.521 170.679H255.907C256.389 170.679 256.839 170.287 256.916 169.807L258.669 158.54C258.742 158.059 258.411 157.667 257.933 157.667ZM249.086 164.062L249.232 163.131C249.278 162.843 249.49 162.566 249.779 162.478C250.256 162.335 250.622 162.666 250.553 163.101L250.403 164.062C250.769 164.293 250.973 164.723 250.896 165.219C250.765 166.058 249.883 166.711 249.043 166.507C248.566 166.392 248.234 165.973 248.219 165.473C248.2 164.873 248.577 164.331 249.086 164.062Z"
        fill="#FFC133"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.3}
      >
        <path
          d="M258.673 158.536L256.92 169.803C256.847 170.283 256.392 170.676 255.911 170.676H241.524C241.043 170.676 240.712 170.287 240.789 169.803L240.877 169.241C244.29 169.288 249.405 169.138 252.337 168.022C256.535 166.423 257.563 159.771 257.752 157.663H257.937C258.419 157.663 258.75 158.056 258.673 158.536Z"
          fill="#C5D0FF"
        />
      </g>
      <path
        d="M258.318 123.18C258.261 122.345 258.014 121.588 257.617 120.938C257.24 120.323 256.72 119.807 256.1 119.442C255.41 119.034 254.597 118.807 253.704 118.811C250.819 118.834 248.196 121.292 247.849 124.306C247.838 124.398 247.83 124.495 247.822 124.587C247.795 124.998 247.811 125.394 247.868 125.775C247.907 126.033 247.965 126.279 248.042 126.521C248.088 126.663 248.134 126.802 248.192 126.936C248.885 128.609 250.468 129.732 252.44 129.716C255.144 129.693 257.625 127.528 258.207 124.768C258.245 124.587 258.276 124.406 258.295 124.222C258.338 123.864 258.341 123.514 258.318 123.176V123.18ZM252.552 128.751C251.038 128.763 249.802 127.959 249.178 126.733C249.093 126.567 249.02 126.394 248.958 126.217C248.866 125.937 248.8 125.64 248.762 125.333C248.723 124.998 248.727 124.656 248.766 124.298C248.777 124.183 248.797 124.072 248.816 123.96C249.251 121.638 251.316 119.796 253.584 119.776C254.128 119.773 254.636 119.873 255.091 120.061C255.757 120.334 256.312 120.795 256.716 121.384C257.113 121.972 257.355 122.687 257.39 123.476C257.402 123.718 257.39 123.975 257.363 124.229C257.332 124.487 257.282 124.741 257.213 124.987C256.62 127.105 254.671 128.732 252.544 128.747L252.552 128.751Z"
        fill="#FFB100"
      />
      <path
        d="M257.787 122.857C257.729 122.022 257.483 121.265 257.086 120.615C256.709 120 256.189 119.484 255.568 119.119C254.879 118.711 254.066 118.484 253.173 118.488C250.288 118.511 247.664 120.969 247.318 123.983C247.306 124.075 247.299 124.172 247.291 124.264C247.264 124.675 247.279 125.071 247.337 125.452C247.376 125.71 247.433 125.956 247.51 126.198C247.557 126.34 247.603 126.479 247.661 126.613C248.354 128.286 249.937 129.409 251.909 129.393C254.613 129.37 257.094 127.205 257.675 124.445C257.714 124.264 257.745 124.083 257.764 123.899C257.806 123.541 257.81 123.191 257.787 122.853V122.857ZM252.021 128.428C250.507 128.44 249.271 127.636 248.647 126.41C248.562 126.244 248.489 126.071 248.427 125.894C248.335 125.614 248.269 125.317 248.231 125.01C248.192 124.675 248.196 124.333 248.235 123.975C248.246 123.86 248.265 123.749 248.285 123.637C248.72 121.315 250.784 119.473 253.053 119.453C253.596 119.45 254.105 119.55 254.559 119.738C255.226 120.011 255.78 120.472 256.185 121.061C256.581 121.649 256.824 122.364 256.859 123.153C256.87 123.395 256.859 123.652 256.832 123.906C256.801 124.164 256.751 124.418 256.682 124.664C256.088 126.782 254.139 128.409 252.013 128.424L252.021 128.428Z"
        fill="#FFCB54"
      />
      <path
        d="M253.064 119.427C255.456 119.407 257.159 121.415 256.87 123.91C256.581 126.406 254.409 128.444 252.017 128.463C249.625 128.482 247.922 126.475 248.211 123.979C248.5 121.484 250.673 119.446 253.064 119.427ZM256.3 123.914C256.55 121.745 255.071 120.003 252.995 120.019C250.919 120.034 249.032 121.807 248.777 123.975C248.527 126.144 250.006 127.886 252.082 127.871C254.158 127.855 256.046 126.083 256.3 123.914Z"
        fill="white"
      />
      <path
        d="M255.453 126.121C256.833 124.284 256.646 121.818 255.036 120.613C253.426 119.408 251.002 119.92 249.623 121.757C248.243 123.594 248.43 126.06 250.04 127.265C251.65 128.47 254.074 127.958 255.453 126.121Z"
        fill="#FFCB54"
      />
      <path
        d="M253.35 122.199C253.758 122.364 253.916 122.745 253.854 123.283L253.846 123.364H253.315C253.35 123.068 253.303 122.853 253.172 122.722L252.826 123.718C253.48 123.822 253.796 124.091 253.731 124.668V124.71C253.65 125.367 253.149 125.775 252.356 125.783C252.271 125.783 252.194 125.779 252.117 125.775L251.905 126.398H251.474L251.724 125.675C251.312 125.494 251.131 125.114 251.192 124.61V124.579L251.728 124.575C251.697 124.837 251.759 125.037 251.901 125.16L252.263 124.106C251.589 123.991 251.335 123.695 251.397 123.183V123.141C251.478 122.499 252.017 122.107 252.756 122.103C252.826 122.103 252.891 122.107 252.953 122.11L253.165 121.495H253.596L253.353 122.199H253.35ZM252.414 123.656L252.783 122.576C252.756 122.572 252.726 122.572 252.702 122.572H252.683C252.302 122.572 251.982 122.768 251.944 123.099V123.126C251.909 123.402 252.024 123.576 252.417 123.656H252.414ZM253.172 124.744V124.718C253.211 124.406 253.053 124.245 252.668 124.168L252.275 125.314C252.317 125.317 252.356 125.317 252.398 125.317H252.417C252.826 125.317 253.13 125.106 253.172 124.744Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M257.086 120.619C256.793 120.765 256.493 120.915 256.192 121.065C253.612 122.357 250.761 123.772 248.235 125.014C247.93 125.164 247.63 125.31 247.337 125.456C247.279 125.075 247.26 124.679 247.291 124.268C247.618 124.06 247.953 123.852 248.292 123.641C250.299 122.387 252.491 121.026 254.567 119.742C254.906 119.534 255.237 119.327 255.568 119.123C256.189 119.488 256.705 120 257.086 120.619Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M257.764 123.902C257.741 124.087 257.71 124.268 257.675 124.448C257.375 124.514 257.043 124.587 256.689 124.668C254.359 125.183 251.092 125.898 248.646 126.409C248.3 126.483 247.969 126.552 247.66 126.617C247.603 126.483 247.553 126.344 247.51 126.202C247.795 126.11 248.107 126.006 248.431 125.898C250.958 125.06 254.451 123.937 256.866 123.156C257.194 123.049 257.506 122.949 257.791 122.86C257.814 123.199 257.81 123.545 257.768 123.906L257.764 123.902Z"
          fill="#F0F1F1"
        />
      </g>
      <path
        d="M153.89 107.451C153.796 106.106 153.399 104.885 152.76 103.837C152.151 102.846 151.313 102.015 150.313 101.426C149.202 100.769 147.892 100.403 146.451 100.41C141.801 100.447 137.572 104.408 137.014 109.267C136.995 109.416 136.983 109.571 136.97 109.72C136.927 110.383 136.952 111.021 137.045 111.635C137.107 112.05 137.2 112.447 137.324 112.837C137.399 113.067 137.473 113.29 137.566 113.507C138.684 116.203 141.236 118.013 144.415 117.988C148.773 117.951 152.772 114.461 153.71 110.011C153.772 109.72 153.821 109.428 153.852 109.131C153.921 108.554 153.927 107.99 153.89 107.445V107.451ZM144.595 116.432C142.155 116.451 140.162 115.156 139.156 113.178C139.019 112.912 138.901 112.633 138.802 112.348C138.653 111.895 138.547 111.418 138.485 110.922C138.423 110.383 138.429 109.831 138.491 109.255C138.51 109.069 138.541 108.889 138.572 108.709C139.274 104.965 142.602 101.996 146.259 101.965C147.134 101.959 147.954 102.12 148.686 102.424C149.761 102.864 150.655 103.608 151.307 104.556C151.946 105.505 152.337 106.658 152.393 107.928C152.412 108.319 152.393 108.734 152.35 109.143C152.3 109.558 152.219 109.968 152.108 110.364C151.151 113.78 148.01 116.401 144.582 116.426L144.595 116.432Z"
        fill="#FFB100"
      />
      <path
        d="M153.033 106.93C152.94 105.585 152.542 104.364 151.903 103.317C151.294 102.325 150.456 101.494 149.456 100.906C148.345 100.249 147.035 99.8829 145.594 99.8891C140.944 99.9263 136.716 103.887 136.157 108.747C136.138 108.895 136.126 109.05 136.113 109.199C136.07 109.862 136.095 110.501 136.188 111.114C136.25 111.53 136.343 111.926 136.467 112.317C136.542 112.546 136.616 112.769 136.709 112.986C137.827 115.683 140.379 117.493 143.558 117.468C147.917 117.431 151.915 113.941 152.853 109.49C152.915 109.199 152.964 108.908 152.995 108.61C153.064 108.034 153.07 107.47 153.033 106.924V106.93ZM143.738 115.912C141.298 115.931 139.305 114.635 138.299 112.658C138.162 112.391 138.044 112.112 137.945 111.827C137.796 111.375 137.69 110.897 137.628 110.402C137.566 109.862 137.572 109.311 137.634 108.734C137.653 108.548 137.684 108.368 137.715 108.189C138.417 104.445 141.745 101.476 145.402 101.445C146.277 101.439 147.097 101.6 147.83 101.904C148.904 102.344 149.798 103.087 150.45 104.036C151.089 104.984 151.481 106.137 151.536 107.408C151.555 107.798 151.536 108.214 151.493 108.623C151.443 109.038 151.363 109.447 151.251 109.844C150.295 113.259 147.153 115.881 143.725 115.906L143.738 115.912Z"
        fill="#FFCB54"
      />
      <path
        d="M145.421 101.401C149.276 101.37 152.021 104.606 151.555 108.629C151.089 112.651 147.587 115.937 143.732 115.968C139.876 115.999 137.132 112.763 137.597 108.74C138.063 104.717 141.565 101.432 145.421 101.401ZM150.636 108.635C151.04 105.139 148.655 102.331 145.309 102.356C141.962 102.381 138.92 105.238 138.51 108.734C138.106 112.23 140.491 115.038 143.837 115.013C147.184 114.988 150.226 112.131 150.636 108.635Z"
        fill="white"
      />
      <path
        d="M149.271 112.192C151.495 109.232 151.194 105.257 148.598 103.314C146.003 101.371 142.096 102.197 139.872 105.158C137.649 108.118 137.95 112.093 140.546 114.036C143.141 115.979 147.048 115.153 149.271 112.192Z"
        fill="#FFCB54"
      />
      <path
        d="M145.88 105.87C146.538 106.137 146.793 106.751 146.693 107.618L146.681 107.748H145.824C145.88 107.271 145.806 106.924 145.594 106.713L145.036 108.319C146.091 108.486 146.6 108.92 146.495 109.85V109.918C146.364 110.978 145.557 111.635 144.278 111.647C144.142 111.647 144.017 111.641 143.893 111.635L143.552 112.639H142.856L143.26 111.474C142.595 111.182 142.304 110.569 142.403 109.757V109.707L143.266 109.701C143.216 110.122 143.316 110.445 143.545 110.643L144.129 108.945C143.043 108.759 142.633 108.282 142.732 107.457V107.389C142.862 106.354 143.732 105.722 144.924 105.715C145.036 105.715 145.141 105.722 145.241 105.728L145.582 104.736H146.277L145.886 105.87H145.88ZM144.371 108.22L144.967 106.478C144.924 106.472 144.874 106.472 144.837 106.472H144.806C144.191 106.472 143.676 106.788 143.614 107.321V107.364C143.558 107.81 143.744 108.089 144.377 108.22H144.371ZM145.594 109.974V109.93C145.657 109.428 145.402 109.168 144.781 109.044L144.148 110.891C144.216 110.897 144.278 110.897 144.346 110.897H144.377C145.036 110.897 145.526 110.556 145.594 109.974Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M151.903 103.323C151.431 103.558 150.947 103.8 150.462 104.042C146.302 106.124 141.708 108.405 137.635 110.408C137.144 110.649 136.66 110.885 136.188 111.12C136.095 110.507 136.064 109.868 136.113 109.205C136.641 108.87 137.181 108.536 137.728 108.195C140.963 106.174 144.495 103.98 147.842 101.91C148.389 101.575 148.922 101.24 149.456 100.912C150.456 101.5 151.288 102.325 151.903 103.323Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M152.996 108.616C152.958 108.914 152.909 109.205 152.853 109.496C152.368 109.602 151.834 109.72 151.263 109.85C147.507 110.68 142.242 111.833 138.299 112.658C137.74 112.775 137.206 112.887 136.709 112.992C136.616 112.775 136.536 112.552 136.467 112.323C136.927 112.174 137.43 112.007 137.951 111.833C142.024 110.482 147.656 108.672 151.549 107.414C152.077 107.24 152.58 107.079 153.039 106.937C153.076 107.482 153.07 108.04 153.002 108.622L152.996 108.616Z"
          fill="#F0F1F1"
        />
      </g>
      <path
        d="M214.446 84.9344C214.389 84.1 214.142 83.3425 213.745 82.6926C213.368 82.0774 212.848 81.5621 212.228 81.1968C211.538 80.7892 210.725 80.5624 209.832 80.5662C206.947 80.5893 204.324 83.0464 203.977 86.061C203.966 86.1533 203.958 86.2495 203.95 86.3417C203.923 86.7532 203.939 87.1492 203.996 87.5299C204.035 87.7876 204.093 88.0336 204.17 88.2759C204.216 88.4182 204.262 88.5566 204.32 88.6912C205.013 90.3639 206.596 91.4867 208.568 91.4713C211.272 91.4482 213.753 89.2833 214.335 86.5225C214.373 86.3417 214.404 86.161 214.423 85.9764C214.466 85.6188 214.469 85.2689 214.446 84.9305V84.9344ZM208.68 90.5061C207.166 90.5177 205.93 89.714 205.306 88.4874C205.221 88.322 205.148 88.149 205.086 87.9721C204.994 87.6914 204.928 87.3953 204.89 87.0877C204.851 86.7532 204.855 86.411 204.894 86.0534C204.905 85.938 204.925 85.8265 204.944 85.715C205.379 83.3925 207.444 81.5506 209.712 81.5314C210.256 81.5275 210.764 81.6275 211.218 81.8159C211.885 82.0889 212.44 82.5503 212.844 83.1387C213.241 83.727 213.483 84.4422 213.518 85.2305C213.53 85.4727 213.518 85.7304 213.491 85.9841C213.46 86.2418 213.41 86.4956 213.341 86.7417C212.748 88.8604 210.799 90.4869 208.672 90.5023L208.68 90.5061Z"
        fill="#FFB100"
      />
      <path
        d="M213.915 84.6114C213.857 83.777 213.611 83.0195 213.214 82.3696C212.836 81.7544 212.317 81.2391 211.696 80.8738C211.007 80.4662 210.194 80.2394 209.301 80.2432C206.416 80.2663 203.792 82.7234 203.446 85.738C203.434 85.8303 203.427 85.9265 203.419 86.0187C203.392 86.4302 203.407 86.8262 203.465 87.2069C203.504 87.4646 203.561 87.7107 203.638 87.9529C203.685 88.0952 203.731 88.2336 203.789 88.3682C204.482 90.0409 206.065 91.1637 208.037 91.1483C210.741 91.1252 213.222 88.9603 213.803 86.1995C213.842 86.0187 213.873 85.838 213.892 85.6535C213.934 85.2958 213.938 84.9459 213.915 84.6075V84.6114ZM208.149 90.1831C206.635 90.1947 205.399 89.391 204.775 88.1644C204.69 87.999 204.617 87.826 204.555 87.6491C204.463 87.3684 204.397 87.0723 204.359 86.7647C204.32 86.4302 204.324 86.088 204.363 85.7304C204.374 85.615 204.393 85.5035 204.413 85.392C204.848 83.0695 206.912 81.2276 209.181 81.2084C209.724 81.2045 210.233 81.3045 210.687 81.4929C211.354 81.7659 211.908 82.2274 212.313 82.8157C212.709 83.404 212.952 84.1192 212.987 84.9075C212.998 85.1497 212.987 85.4074 212.96 85.6611C212.929 85.9188 212.879 86.1726 212.81 86.4187C212.216 88.5374 210.267 90.1639 208.141 90.1793L208.149 90.1831Z"
        fill="#FFCB54"
      />
      <path
        d="M209.192 81.1814C211.584 81.1622 213.287 83.1694 212.998 85.6649C212.709 88.1605 210.537 90.1985 208.145 90.2177C205.753 90.2369 204.05 88.2297 204.339 85.7342C204.628 83.2386 206.8 81.2006 209.192 81.1814ZM212.428 85.6688C212.678 83.5001 211.199 81.7582 209.123 81.7736C207.047 81.789 205.16 83.5616 204.905 85.7303C204.655 87.899 206.134 89.6409 208.21 89.6255C210.286 89.6102 212.174 87.8375 212.428 85.6688Z"
        fill="white"
      />
      <path
        d="M211.581 87.8758C212.961 86.0391 212.774 83.5731 211.164 82.368C209.554 81.1629 207.13 81.675 205.751 83.5117C204.371 85.3485 204.558 87.8145 206.168 89.0196C207.778 90.2247 210.202 89.7126 211.581 87.8758Z"
        fill="#FFCB54"
      />
      <path
        d="M209.477 83.9538C209.886 84.1191 210.044 84.4998 209.982 85.0382L209.974 85.1189H209.443C209.477 84.8228 209.431 84.6075 209.3 84.4768L208.954 85.4727C209.608 85.5765 209.924 85.8457 209.859 86.4224V86.4647C209.778 87.1223 209.277 87.5299 208.484 87.5376C208.399 87.5376 208.322 87.5337 208.245 87.5299L208.033 88.1528H207.602L207.852 87.4299C207.44 87.2492 207.259 86.8685 207.32 86.3648V86.334L207.856 86.3302C207.825 86.5916 207.887 86.7916 208.029 86.9146L208.391 85.861C207.717 85.7457 207.463 85.4496 207.525 84.9382V84.8959C207.606 84.2537 208.145 83.8615 208.884 83.8577C208.954 83.8577 209.019 83.8615 209.081 83.8654L209.293 83.2501H209.724L209.481 83.9538H209.477ZM208.542 85.4111L208.911 84.3306C208.884 84.3268 208.854 84.3268 208.83 84.3268H208.811C208.43 84.3268 208.11 84.5229 208.072 84.8536V84.8805C208.037 85.1574 208.152 85.3304 208.545 85.4111H208.542ZM209.3 86.4993V86.4724C209.339 86.161 209.181 85.9995 208.796 85.9226L208.403 87.0684C208.445 87.0723 208.484 87.0723 208.526 87.0723H208.545C208.954 87.0723 209.258 86.8608 209.3 86.4993Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M213.214 82.3735C212.921 82.5196 212.621 82.6696 212.32 82.8195C209.74 84.1115 206.889 85.5266 204.363 86.7686C204.058 86.9185 203.758 87.0647 203.465 87.2108C203.407 86.8301 203.388 86.434 203.419 86.0226C203.746 85.815 204.081 85.6073 204.42 85.3958C206.427 84.1423 208.619 82.7811 210.695 81.4968C211.034 81.2891 211.365 81.0815 211.696 80.8777C212.317 81.243 212.833 81.7544 213.214 82.3735Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M213.892 85.6573C213.869 85.8419 213.838 86.0226 213.803 86.2033C213.503 86.2687 213.171 86.3417 212.817 86.4225C210.487 86.9378 207.22 87.653 204.774 88.1644C204.428 88.2374 204.096 88.3067 203.788 88.372C203.731 88.2374 203.68 88.099 203.638 87.9567C203.923 87.8645 204.235 87.7606 204.559 87.653C207.085 86.8147 210.579 85.6919 212.994 84.9113C213.322 84.8036 213.634 84.7037 213.919 84.6152C213.942 84.9536 213.938 85.2997 213.895 85.6611L213.892 85.6573Z"
          fill="#F0F1F1"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_488_2395">
        <rect width={280} height={233} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVGComponent;

const SVGComponent = (props: any) => (
  <svg
    width={6}
    height={8}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.139e-07 0.488903C6.49949e-07 0.0765471 0.549237 -0.149915 0.913699 0.112164L5.79641 3.62326C6.06786 3.81846 6.06786 4.18154 5.79641 4.37674L0.913699 7.88784C0.549237 8.14992 -3.60493e-08 7.92345 0 7.5111L6.139e-07 0.488903Z"
      fill={props?.color}
    />
  </svg>
)

export default SVGComponent

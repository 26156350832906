import { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContextProvider } from 'contexts/ContextProvider';
import { routing } from 'components/Routing';
import { ToastContainer } from 'react-toastify';

import PublicRoute from 'components/PublicRoute';
import PrivateRoute from 'components/PrivateRoute';
import Layout from 'containers/Layout';
import 'assets/styles';
import 'react-block-ui/style.css';

require('@solana/wallet-adapter-react-ui/styles.css');

const Login = lazy(() => import('pages/Auth/Login'));

const App = () => {
  const [isSidebarAccount, setIsSidebarAccount] = useState<boolean>(false);
  const [isSidebarMenu, setIsSidebarMenu] = useState<boolean>(false);

  return (
    <ContextProvider>
      <ToastContainer position="top-center" autoClose={3000} limit={1} />
      <BrowserRouter>
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route
              key={'login'}
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              element={
                <Layout
                  setIsSidebarAccount={setIsSidebarAccount}
                  isSidebarAccount={isSidebarAccount}
                  setIsSidebarMenu={setIsSidebarMenu}
                  isSidebarMenu={isSidebarMenu}
                />
              }
            >
              {routing.map((x) => {
                return (
                  <Route
                    key={x.key}
                    path={x.path}
                    element={
                      <PrivateRoute
                        title={x.title}
                        subtitle={x.subTitle}
                        webTitle={x.webTitle}
                        setIsSidebarAccount={setIsSidebarAccount}
                        setIsSidebarMenu={setIsSidebarMenu}
                      >
                        <x.Component />
                      </PrivateRoute>
                    }
                  />
                );
              })}
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default App;

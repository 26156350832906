import { IcFiles, IcRemove } from 'assets/svgs';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Component: React.FC<any> = ({
  files,
  setFiles
}) => {
  
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          key: `id${Math.random().toString(16).slice(2)}`,
        })
      );

      setFiles([...files, ...newFile]);
    },
  });

  const handleRemovePic = (evt: any, key: string) => {
    evt.stopPropagation();
    const newFile = files.filter((file) => file?.key !== key);

    setFiles(newFile);
  };

  return (
    <section
      className={`flex flex-col items-center ${
        files?.length < 1 ? 'h-[128px]' : 'h-[220px]'
      } justify-center w-full focus:border-[#4C494A] focus:outline-none focus:ring-0 border-[1px] border-[#C8CBCE] border-dashed rounded-lg bg-gray-50`}
    >
      <div {...getRootProps()}>
        {files?.length < 1 ? (
          <div className="flex items-center justify-center pt-5 pb-6">
            <p className="text-base font-normal text-[#898F96]">
              Drop gambar disini atau
            </p>
            <div className="border-[#C8CBCE] border-[1px] rounded ml-3 p-2 flex items-center cursor-pointer">
              <IcFiles />
              <p className="ml-2 text-xs font-medium">Browse</p>
              <input {...getInputProps()} />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 allMobile:grid-cols-1 gap-4 px-4">
            {files?.map((file) => (
              <div className="shadow-md w-full h-[188px] rounded flex flex-col justify-between">
                <img
                  src={file?.preview}
                  alt="img"
                  className="h-[136px] rounded-t-md"
                />
                <div className="px-2 py-4 flex">
                  <IcRemove
                    className="mr-3 cursor-pointer"
                    onClick={(evt) => handleRemovePic(evt, file?.key)}
                  />
                  <div className="text-xs font-normal truncate">
                    {file?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Component;

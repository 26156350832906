import jsPDF from 'jspdf';
import 'jspdf-autotable';

function ExportPDF({ column, data, filename }) {
  let doc = new jsPDF({ orientation: 'landscape' })
  // pdf.autoTable({
  //   head: [column],
  //   body: data,
  // })
  doc.autoTable(column, data, {

    margin: { horizontal: 10 },
    styles: { overflow: "linebreak" },
    bodyStyles: { valign: "top" },
    theme: "striped",
    showHead: "everyPage",
    didDrawPage: function (data) {
      // Header
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.text(filename, data.settings.margin.left, 10);

      // Footer
      var str = "Page " + doc.internal.getNumberOfPages();

      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, data.settings.margin.left, pageHeight - 10);
    }
  });
  doc.save(`${filename}.pdf`);
}

export default ExportPDF;

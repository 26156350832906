import { combineReducers } from 'redux';
import authReducer from './authReducers';
import cashierReducer from './cashierReducer';
import eventReducers from './eventReducers';
import exampleReducer from './exampleReducer';
import walletReducer from './walletReducer';

export const rootReducer = combineReducers({
  example: exampleReducer,
  auth: authReducer,
  wallet: walletReducer,
  cashier: cashierReducer,
  event: eventReducers,
});

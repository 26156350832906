import { AxiosError } from 'axios';
import { IWalletTicket } from './walletTypes';

// Event Types
export enum EventType {
  EVENT_LIST_PENDING = 'EVENT_LIST_PENDING',
  EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS',
  EVENT_LIST_ERROR = 'EVENT_LIST_ERROR',
}

// IState Event
export interface IEventtState {
  data: IEventData[] & IEventData & IWalletTicket;
  isLoading: boolean;
  error: AxiosError;
}

// IAction Event
interface GetEventAction {
  type: EventType.EVENT_LIST_SUCCESS;
  payload: {
    data: IEventResponse;
  };
}

interface SetLoadingEventAction {
  type: EventType.EVENT_LIST_PENDING;
  payload?: any;
}

interface SetErrorEventAction {
  type: EventType.EVENT_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

// IParams Event
export interface IParamEvents {
  size: number;
  current: number;
  keyword?: string;
  date?: string;
  pages?: number;
  status?: string;
  rows?: number;
}

// IResponse Event
export interface IEventResponse {
  requestId: string;
  status: string;
  code: number;
  message: string;
  page: {
    current: number;
    pages: number;
    rows: number;
    size: number;
  };
  data?: IEventData[] & IEventData & IWalletTicket;
}

export interface IEventData {
  id?: number | string;
  code: string;
  name: string;
  dateStart: string;
  dateEnd: string;
  description: string;
  pic1: string;
  pic2: string;
  status: string;
  gsnEnabled: string;
  loyaltyCode: string;
  voucherCode: string;
}

export type EventAction =
  | GetEventAction
  | SetLoadingEventAction
  | SetErrorEventAction;

const SVGComponent = (props: any) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 14.4999C16.244 14.4999 15.988 14.4019 15.793 14.2069L12.488 10.9019L9.19502 14.0819C8.79702 14.4659 8.16502 14.4559 7.78102 14.0569C7.39702 13.6599 7.40802 13.0259 7.80502 12.6429L11.805 8.78091C12.198 8.40191 12.822 8.40691 13.207 8.79291L17.207 12.7929C17.598 13.1839 17.598 13.8159 17.207 14.2069C17.012 14.4019 16.756 14.4999 16.5 14.4999Z"
      fill="#343747"
    />
    <mask
      id="mask0_1199_3699"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={8}
      width={11}
      height={7}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 14.4999C16.244 14.4999 15.988 14.4019 15.793 14.2069L12.488 10.9019L9.19502 14.0819C8.79702 14.4659 8.16502 14.4559 7.78102 14.0569C7.39702 13.6599 7.40802 13.0259 7.80502 12.6429L11.805 8.78091C12.198 8.40191 12.822 8.40691 13.207 8.79291L17.207 12.7929C17.598 13.1839 17.598 13.8159 17.207 14.2069C17.012 14.4019 16.756 14.4999 16.5 14.4999Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1199_3699)">
      <rect x={0.5} width={24} height={24} fill="#343747" />
    </g>
  </svg>
);

export default SVGComponent;

/* eslint-disable import/no-anonymous-default-export */
import { WalletType, IWalletState } from "stores/types/walletTypes";
import { updateObject } from "utils";
const initialState = {
  address: "",
  solana: {
    publicKey: "",
    balance: 0,
    isLoading: false,
  },
  qoinToken: {
    publicKey: "",
    balance: 0,
    isLoading: false,
  },
  rateQoin: {
    fee: 0,
    fee_amount: 0,
    ppn: 0,
    ppn_amount: 0,
    discount: 0,
    disc_price: 0,
    fix_rate: 0,
    markup_price: 0,
    total: 0,
    isLoading: false,
  },
  isGettingConfirmation: false,
};

export default (state: IWalletState = initialState, { type, payload }: any) => {
  switch (type) {
    case WalletType.SET_WALLET_ADDRESS:
      return updateObject(state, {
        address: payload,
      });
    case WalletType.SET_CONFIRMATION:
      return updateObject(state, {
        isGettingConfirmation: payload,
      });
    case WalletType.SET_SOLANA_PUBLICKEY:
      return updateObject(state, {
        ...state,
        solana: {
          ...state.solana,
          isLoading: payload.isLoading,
          publicKey: payload.publicKey,
        },
      });
    case WalletType.SET_QOIN_PUBLICKEY:
      return updateObject(state, {
        ...state,
        qoinToken: {
          ...state.qoinToken,
          isLoading: payload.isLoading,
          publicKey: payload.publicKey,
        },
      });
    case WalletType.SET_SOLANA_BALANCE:
      return updateObject(state, {
        ...state,
        solana: {
          ...state.solana,
          isLoading: payload.isLoading,
          balance: payload.balance,
        },
      });
    case WalletType.SET_QOIN_RATE_LOADING:
      return updateObject(state, {
        ...state,
        rateQoin: {
          ...state.rateQoin,
          isLoading: payload,
        },
      });
    case WalletType.SET_QOIN_RATE:
      return updateObject(state, {
        rateQoin: payload,
      });
    case WalletType.SET_QOIN_BALANCE:
      return updateObject(state, {
        ...state,
        qoinToken: {
          ...state.qoinToken,
          isLoading: payload.isLoading,
          balance: payload.balance,
        },
      });
    case WalletType.RESET_QOIN_RATE:
      return updateObject(state, {
        rateQoin: {
          fee: 0,
          fee_amount: 0,
          ppn: 0,
          ppn_amount: 0,
          discount: 0,
          disc_price: 0,
          fix_rate: 0,
          markup_price: 0,
          total: 0,
          isLoading: false,
        },
      });
    case WalletType.RESET_WALLET:
      return updateObject(state, {
        address: "",
        solana: {
          publicKey: "",
          balance: 0,
          isLoading: false,
        },
        qoinToken: {
          publicKey: "",
          balance: 0,
          isLoading: false,
        },
        rateQoin: {
          fee: 0,
          fee_amount: 0,
          ppn: 0,
          ppn_amount: 0,
          discount: 0,
          disc_price: 0,
          fix_rate: 0,
          markup_price: 0,
          total: 0,
          isLoading: false,
        },
        isGettingConfirmation: false,
      });
    default:
      return state;
  }
};

export const staticConst = {
  // Nominal Topup
  nomTopup: [
    {
      value: 50000,
      key: 'Rp 50.000',
    },
    {
      value: 100000,
      key: 'Rp 100.000',
    },
    {
      value: 200000,
      key: 'Rp 200.000',
    },
    {
      value: 300000,
      key: 'Rp 300.000',
    },
    {
      value: 500000,
      key: 'Rp 500.000',
    },
    {
      value: 750000,
      key: 'Rp 750.000',
    },
    {
      value: 1000000,
      key: 'Rp 1.000.000',
    },
    {
      value: 2000000,
      key: 'Rp 2.000.000',
    },
  ],

  // History Transaction
  historyTrans: [
    {
      address: '5pxrjBT7DTXMK9KqK7zcb...',
      name: 'Abdul Aziz',
      nom: '100.000',
      nomQoin: '10.000 ',
    },
    {
      address: '5pxrjBT7DTXMK9KqK7zcb...',
      name: 'Abdul Aziz',
      nom: '100.000',
      nomQoin: '10.000 ',
    },
    {
      address: '5pxrjBT7DTXMK9KqK7zcb...',
      name: 'Abdul Aziz',
      nom: '100.000',
      nomQoin: '10.000 ',
    },
  ],

  // Dropdown Search History
  optionsHistory: [
    {
      name: 'ID Transaksi',
      value: 'trxReceipt',
    },
    {
      name: 'Alamat Token',
      value: 'trxAddressTo',
    },
    {
      name: 'Hash',
      value: 'trxCryptoNo',
    },
    {
      name: 'Nomor Handphone',
      value: 'trxPhone',
    },
    {
      name: 'Email',
      value: 'trxEmail',
    },
    {
      name: 'Nama Pengguna',
      value: 'trxName',
    },
  ],

  // Dropdown Filter History
  optionsFilterHistory: [
    {
      name: 'Tanggal & Waktu',
      value: 'date',
    },
    {
      name: 'Status',
      value: 'status',
    },
    {
      name: 'Event',
      value: 'eventName',
    },
    {
      name: 'Status',
      value: 'status',
    },
  ],

  // Dropdown Filter Status
  optionsStatusHis: [
    {
      name: 'Berhasil',
      value: 1,
    },
    {
      name: 'Dibatalkan',
      value: 0,
    },
  ],

  optionsStatusEvt: [
    {
      name: 'Aktif',
      value: '1',
    },
    {
      name: 'Non Aktif',
      value: '2',
    },
  ],

  //  Dropdown Filter Cashier
  optionsEvent: [
    {
      name: 'Ultra Bali',
      value: 1,
    },
    {
      name: 'Hammersonic',
      value: 2,
    },
    {
      name: 'Djakarta Warehouse Project',
      value: 3,
    },
  ],
};

const SVGComponent = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 9.00034C7.44868 9.00034 7.00001 8.55167 7.00001 8.00034C7.00001 7.44901 7.44868 7.00034 8.00001 7.00034C8.55135 7.00034 9.00001 7.44901 9.00001 8.00034C9.00001 8.55167 8.55135 9.00034 8.00001 9.00034ZM8.00001 5.66701C6.71335 5.66701 5.66668 6.71367 5.66668 8.00034C5.66668 9.28701 6.71335 10.3337 8.00001 10.3337C9.28668 10.3337 10.3333 9.28701 10.3333 8.00034C10.3333 6.71367 9.28668 5.66701 8.00001 5.66701ZM8.14648 11.3321C5.27581 11.3987 3.40315 8.94341 2.78181 7.99741C3.46581 6.92741 5.18848 4.73674 7.85381 4.66874C10.7131 4.59607 12.5965 7.05741 13.2178 8.00341C12.5345 9.07341 10.8111 11.2641 8.14648 11.3321ZM14.5785 7.66874C14.1531 6.92741 11.8038 3.21141 7.81981 3.33607C4.13448 3.42941 1.99115 6.67607 1.42181 7.66874C1.30381 7.87407 1.30381 8.12674 1.42181 8.33207C1.84115 9.06341 4.10781 12.6667 8.01648 12.6667C8.07115 12.6667 8.12581 12.6661 8.18048 12.6647C11.8651 12.5707 14.0091 9.32474 14.5785 8.33207C14.6958 8.12674 14.6958 7.87407 14.5785 7.66874Z"
      fill="#231F20"
    />
    <mask
      id="mask0_886_4025"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={3}
      width={14}
      height={10}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 9.00034C7.44868 9.00034 7.00001 8.55167 7.00001 8.00034C7.00001 7.44901 7.44868 7.00034 8.00001 7.00034C8.55135 7.00034 9.00001 7.44901 9.00001 8.00034C9.00001 8.55167 8.55135 9.00034 8.00001 9.00034ZM8.00001 5.66701C6.71335 5.66701 5.66668 6.71367 5.66668 8.00034C5.66668 9.28701 6.71335 10.3337 8.00001 10.3337C9.28668 10.3337 10.3333 9.28701 10.3333 8.00034C10.3333 6.71367 9.28668 5.66701 8.00001 5.66701ZM8.14648 11.3321C5.27581 11.3987 3.40315 8.94341 2.78181 7.99741C3.46581 6.92741 5.18848 4.73674 7.85381 4.66874C10.7131 4.59607 12.5965 7.05741 13.2178 8.00341C12.5345 9.07341 10.8111 11.2641 8.14648 11.3321ZM14.5785 7.66874C14.1531 6.92741 11.8038 3.21141 7.81981 3.33607C4.13448 3.42941 1.99115 6.67607 1.42181 7.66874C1.30381 7.87407 1.30381 8.12674 1.42181 8.33207C1.84115 9.06341 4.10781 12.6667 8.01648 12.6667C8.07115 12.6667 8.12581 12.6661 8.18048 12.6647C11.8651 12.5707 14.0091 9.32474 14.5785 8.33207C14.6958 8.12674 14.6958 7.87407 14.5785 7.66874Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_886_4025)">
      <rect width={16} height={16} fill="#0D1C2E" />
    </g>
  </svg>
);

export default SVGComponent;

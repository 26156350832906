const SVGComponent = (props: any) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 11.0801V12.0001C22.4988 14.1565 21.8005 16.2548 20.5093 17.9819C19.2182 19.7091 17.4033 20.9726 15.3354 21.584C13.2674 22.1954 11.0573 22.122 9.03447 21.3747C7.01168 20.6274 5.28465 19.2462 4.11096 17.4372C2.93727 15.6281 2.37979 13.4882 2.52168 11.3364C2.66356 9.18467 3.49721 7.13643 4.89828 5.49718C6.29935 3.85793 8.19279 2.71549 10.2962 2.24025C12.3996 1.76502 14.6003 1.98245 16.57 2.86011M22.5 4.00011L12.5 14.0101L9.50001 11.0101"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVGComponent

import { useSelector, useDispatch } from 'react-redux';
import MethodProvider from 'provider/methods';
import {
  IBodyWalletTicket,
  IParamsGetTransactionTicket,
  IResponseTransaction,
  WalletTicket,
  WalletTicketAction,
  WalletType,
} from 'stores/types/walletTypes';

import {
  createTransferInstruction,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
  getAccount,
  createAssociatedTokenAccountInstruction,
} from '@solana/spl-token';
import provider, { IProvider } from 'provider';
import endpoints from 'provider/endpoints';
import { useWallet } from '@solana/wallet-adapter-react';

import { formatSubstring, environment, notification } from 'utils';
import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import { IError } from 'stores/types/errorTypes';
import { errorGlobal } from './errorAction';
import { RootState } from 'stores';
import useWalletTicket from 'hooks/useWallet';

const {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  clusterApiUrl,
} = require('@solana/web3.js');

export const useWalletAction = () => {
  const dispatch = useDispatch();
  let solConnection: any;
  const { sendTransaction, signTransaction } = useWallet();

  const configSPLToken = () => {
    // solConnection = new Connection("https://newest-old-wave.solana-mainnet.discover.quiknode.pro/1462b8d8ba3bb704166ffaaf4a4a017b92caf5d0/");
    // solConnection = new Connection("https://dev-e-wallet-api.qoincrypto.id/sol", {
    //   httpHeaders: {
    //     Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDbGllbnRJZCI6MSwiRXhwaXJlZCI6IjIwMjItMTAtMzEgMTU6NDA6NTAiLCJNZW1iZXJJZCI6MTkyMCwiUGVybWlzc2lvbiI6WyJBcHA6UHJvZmlsZTpBbGwiLCJBcHA6RG9jdW1lbnQ6QWxsIiwiQXBwOk1lbWJlcjpBbGwiLCJBcHA6TWVtYmVyTGV2ZWw6R2V0IiwiQXBwOk1lbWJlcklkOkFsbCIsIkFwcDpNZW1iZXJHcm91cDpHZXQiLCJBcHA6TWVtYmVyQ2FyZFR5cGU6QWxsIiwiQXBwOk1lbWJlckF0dHI6QWxsIiwiQXBwOk1lbWJlckFkZHJlc3M6QWxsIiwiQXBwOk1lbWJlckFjdGl2ZTpHZXQiLCJBcHA6TWVtYmVyUHJvZmlsZTpBbGwiLCJBcHA6UmVmZXJyYWxTZW50OkFsbCIsIkFwcDpNZXJjaGFudDpHZXQiLCJBcHA6TWVyY2hhbnRBZGRyZXNzOkdldCIsIkFwcDpNZXJjaGFudExldmVsOkdldCIsIkFwcDpNZXJjaGFudFByb2R1Y3Q6R2V0IiwiQXBwOkNoZWNrIiwiQXBwOlJlZGVlbTpGdWxsIiwiQXBwOlFyaXNJbnF1aXJ5OkFsbCIsIkFwcDpUb3BVcFZhOkFsbCIsIkFwcDpEb2N1bWVudExvc3RzOkdldCIsIkFwcDpUcmFuc2FjdGlvbjpBbGwiLCJBcHA6QWNjZXNzOlVzZXIiLCJBcHA6Tm9kZTpFdGg6QWxsIiwiQXBwOk5vZGU6QnNjOkFsbCIsIkFwcDpOb2RlOkJpdGNvaW46QWxsIiwiQXBwOk5vZGU6U29sOkFsbCIsIkFwcDpOb2RlOlBvbHk6QWxsIiwiQXBwOkJsb2NrQ29uZmlybWF0aW9uOkFsbCIsIkFwcDpBZ2lDYXNoOkFsbCJdLCJTb3VyY2UiOiJhcHAiLCJVc2VySWQiOjI0Mjl9.3frLbL0hrRPluMLWyk9SxdN-QMklknZgnaRH1h2YB1I"
    //   }
    // })
    solConnection = new Connection('https://mainnet-beta.solflare.network');
  };

  const managementWalletState = useSelector((state: RootState) => state.wallet);

  const managementQoinRateState = useSelector(
    (state: RootState) => state.wallet.rateQoin
  );

  const setAddress = async (address: string) => {
    dispatch({
      type: WalletType.SET_WALLET_ADDRESS,
      payload: address,
    });

    setSolanaPublickey(address);
    setSolanaBalance(address);

    let pubKey = await setQoinPublickey(address);
    setQoinBalance(pubKey);
  };

  const setSolanaPublickey = (publicKey: string) => {
    dispatch({
      type: WalletType.SET_SOLANA_PUBLICKEY,
      payload: {
        isLoading: false,
        publicKey: publicKey,
      },
    });
  };

  const setQoinPublickey = async (val: string) => {
    let publicKey = await getQoinToken(val);
    dispatch({
      type: WalletType.SET_QOIN_PUBLICKEY,
      payload: {
        isLoading: false,
        publicKey: publicKey,
      },
    });
    return publicKey;
  };

  const setSolanaBalance = async (val: string) => {
    dispatch({
      type: WalletType.SET_SOLANA_BALANCE,
      payload: {
        isLoading: true,
        balance: 0,
      },
    });
    let balance = await getSolanaBalance(val);
    dispatch({
      type: WalletType.SET_SOLANA_BALANCE,
      payload: {
        isLoading: false,
        balance: balance,
      },
    });
  };

  const setQoinBalance = async (val: string) => {
    dispatch({
      type: WalletType.SET_QOIN_BALANCE,
      payload: {
        isLoading: true,
        balance: 0,
      },
    });
    let balance = await getSPlBalance(val, managementWalletState.address);

    dispatch({
      type: WalletType.SET_QOIN_BALANCE,
      payload: {
        isLoading: false,
        balance: balance,
      },
    });
  };

  const getSolanaBalance = async (val: string) => {
    configSPLToken();
    let publicKey = val;
    publicKey = new PublicKey(publicKey);
    const balance = await solConnection.getBalance(publicKey);
    const lam = balance / LAMPORTS_PER_SOL;
    return lam;
  };

  const getQoinToken = async (val: string) => {
    configSPLToken();
    let resp = await solConnection._rpcRequest('getTokenAccountsByOwner', [
      val,
      {
        mint: 'J7ABbyXvCfEp9JZ853TUd2GZV46q6SpvEEpSe7wCNy3S',
      },
      {
        encoding: 'jsonParsed',
      },
    ]);
    let returnVal = '';
    if (!resp.error) {
      let val = resp.result.value;
      if (val.length !== 0) {
        returnVal = val[0].pubkey;
      }
    } else {
      console.log('failed getting mint');
    }
    return returnVal;
  };

  const getSPlBalance = async (pubKey: string, address: string) => {
    configSPLToken();
    let balance = 0;
    let publicKey = '';
    if (pubKey && pubKey !== '') {
      balance = await getTokenSplBalance(pubKey);
    } else {
      let pubKey = await setQoinPublickey(address);
      if (pubKey !== '') {
        balance = await getTokenSplBalance(pubKey);
      }
    }

    return balance;
  };

  const getTokenSplBalance = async (publicKey: string) => {
    configSPLToken();
    let balance = 0;
    let resp = await solConnection._rpcRequest('getTokenAccountBalance', [
      publicKey,
    ]);

    if (!resp.error) {
      let amount = resp.result.value;
      balance = amount.uiAmount;
    } else {
      console.log('failed getting balance mint');
    }
    return balance;
  };

  const splSendTransaction = async (
    inputUserTopup: IBodyWalletTicket,
    amount = '0.01'
  ) => {
    configSPLToken();
    let returnValue = {
      status: 0,
      data: '',
    };
    let pubKey = new PublicKey(managementWalletState.qoinToken.publicKey);
    let addressSender = new PublicKey(managementWalletState.address);
    let to: any;
    const mint = new PublicKey('J7ABbyXvCfEp9JZ853TUd2GZV46q6SpvEEpSe7wCNy3S');
    let transaction = new Transaction();
    let lam = parseFloat(amount) * LAMPORTS_PER_SOL;

    try {
      to = new PublicKey(inputUserTopup?.addressReceiver);
    } catch (e) {
      returnValue.data = 'Alamat Dompet Salah';
      return returnValue;
    }

    let sourceAccount = await getAssociatedTokenAddress(mint, addressSender);
    let destinationAccount = await getAssociatedTokenAddress(mint, to);
    let account: any = null;
    let isValidAcccount = true;

    try {
      account = await getAccount(solConnection, destinationAccount);
    } catch (error: unknown) {
      isValidAcccount = false;
    }

    transaction.add(
      createTransferInstruction(
        sourceAccount,
        destinationAccount,
        addressSender,
        lam
      )
    );

    const latestBlockHash = await solConnection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockHash.blockhash;
    if (isValidAcccount === false) {
      transaction.add(
        createAssociatedTokenAccountInstruction(
          addressSender,
          destinationAccount,
          to,
          mint
        )
      );
    }

    let signature: any;
    try {
      signature = await sendTransaction(transaction, solConnection, {
        signers: [],
      });
    } catch (e) {
      returnValue.data = 'Transaksi Ditolak';
      return returnValue;
    }

    confirmTransaction(signature, inputUserTopup);
    returnValue.data = signature;
    returnValue.status = 1;

    return returnValue;
  };

  const gsnSendTransaction = async (
    inputUserTopup: IBodyWalletTicket,
    amount = '0.01'
  ) => {
    configSPLToken();
    let returnValue = {
      status: 0,
      data: '',
    };
    const feePayerAddress = environment.fee_payer;

    let feePayer = new PublicKey(feePayerAddress);
    let addressSender = new PublicKey(managementWalletState.address);
    let to: any;
    const mint = new PublicKey('J7ABbyXvCfEp9JZ853TUd2GZV46q6SpvEEpSe7wCNy3S');
    let lam = parseFloat(amount) * LAMPORTS_PER_SOL;
    try {
      to = new PublicKey(inputUserTopup?.addressReceiver);
    } catch (e) {
      returnValue.data = 'Alamat Dompet Salah';
      return returnValue;
    }
    const fromTokenAccount = await getAssociatedTokenAddress(
      mint,
      addressSender
    );

    const toTokenAccount = await getAssociatedTokenAddress(mint, to);

    let account: any = null;
    let isValidAcccount = true;
    try {
      account = await getAccount(solConnection, toTokenAccount);
    } catch (error: unknown) {
      isValidAcccount = false;
    }
    // console.log("isValidAccount : ", isValidAcccount)
    let recentBlockhash = await solConnection.getLatestBlockhash();
    let transaction = new Transaction({
      recentBlockhash: recentBlockhash.blockhash,
      feePayer: feePayer,
    });

    if (isValidAcccount === false) {
      transaction.add(
        createAssociatedTokenAccountInstruction(
          feePayer,
          toTokenAccount,
          to,
          mint
        )
      );
    }

    transaction.add(
      createTransferInstruction(
        fromTokenAccount, // source
        toTokenAccount, // dest
        addressSender,
        lam,
        [],
        TOKEN_PROGRAM_ID
      )
    );

    let signature = '';
    const signed = await signTransaction(transaction)
      .then(async function (result) {
        const base64StringSg = btoa(
          String.fromCharCode(...new Uint8Array(result.signatures[1].signature))
        );
        const base64StringTx = btoa(
          String.fromCharCode(...new Uint8Array(result.serializeMessage()))
        );

        let bodyData = {
          data: base64StringTx,
          senderSignature: base64StringSg,
        };
        try {
          const objProvider: IProvider = {
            method: MethodProvider.POST,
            path: endpoints.gsnSendTrans,
            data: bodyData,
          };
          const response = await provider(objProvider);
          signature = response.data.transactionSignature;

          confirmTransaction(signature, inputUserTopup);
        } catch (e) {
          returnValue.data = 'Gagal mengirim transaksi';
          return returnValue;
        }
      })
      .catch((error) => {
        returnValue.data = 'Transaksi Ditolak';
        return returnValue;
      });
    returnValue.data = signature;
    returnValue.status = 1;
    return returnValue;
  };

  const { confirmationTicket, confirmationCallbackTicket } = useWalletTicket();

  const confirmTransaction = async (
    signature: string,
    inputUserTopup: IBodyWalletTicket
  ) => {
    dispatch({
      type: WalletType.SET_CONFIRMATION,
      payload: true,
    });
    try {
      let blockConfirm = null;

      const myPromise = await new Promise((resolve) => {
        const blockInterval = setInterval(async () => {
          if (blockConfirm === null) {
            let getSignatureStatus = await solConnection.getSignatureStatuses([
              signature,
            ]);
            blockConfirm = getSignatureStatus.value[0];
            resolve(blockConfirm);
          } else {
            clearInterval(blockInterval);
          }
        }, 2000);
      });
      setQoinBalance(managementWalletState.qoinToken.publicKey);

      try {
        const objProvider: IProvider = {
          method: MethodProvider.POST,
          path: endpoints.createTransaction,
          data: {
            ...inputUserTopup,
            transactionHashSignature: signature,
            addressSender: managementWalletState.address,
            trxRate: managementWalletState.rateQoin.fix_rate,
          },
        };

        const response = await provider(objProvider);
        if (response.status === 200) {
          let ticket = response.data.data.ticket;

          let responseCallback = {
            code: 0,
            message: '',
            data: null,
          };

          const myPromise = await new Promise((resolve) => {
            const getMembershipInterval = setInterval(async () => {
              if (
                responseCallback.code !== 200 &&
                responseCallback.code !== 400
              ) {
                responseCallback = await callbackTransaction(ticket);
                if (responseCallback.code == 200) {
                  dispatch({
                    type: WalletType.SET_CONFIRMATION,
                    payload: false,
                  });
                  notification(
                    'success',
                    `Topup ke ${formatSubstring(
                      inputUserTopup?.addressReceiver
                    )} Berhasil`
                  );
                } else {
                  dispatch({
                    type: WalletType.SET_CONFIRMATION,
                    payload: false,
                  });
                  notification('error', responseCallback.message);
                }
              } else {
                clearInterval(getMembershipInterval);
              }
            }, 1000);
          });
        } else {
          dispatch({
            type: WalletType.SET_CONFIRMATION,
            payload: false,
          });
          notification('error', 'Error while saving transaction');
        }
      } catch (e) {
        dispatch({
          type: WalletType.SET_CONFIRMATION,
          payload: false,
        });
        notification('error', 'Error while saving transaction');
      }
    } catch (e) {
      setSolanaBalance(managementWalletState.qoinToken.publicKey);
      notification(
        'error',
        `Tidak Dapat Mengkonfirmasi Topup ke ${formatSubstring(
          inputUserTopup?.addressReceiver
        )}`
      );
      dispatch({
        type: WalletType.SET_CONFIRMATION,
        payload: false,
      });
    }
  };

  const callbackTransaction = async (ticket: string) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.createTransactionCallback,
        queryParams: {
          ticket: ticket,
        },
      };
      const response = await provider(objProvider);
      let responseCallback = {
        code: response.status,
        message: response.statusText,
        data: response.data,
      };

      return responseCallback;
      // const res = {
      //   code: response?.status,
      //   message: "Login Success!",
      //   data: response?.ticket,
      // };

      // setAuthToken(response?.data?.token);
      // dispatch(userLoginSuccess(response?.data));
      // callback?.(res);
    } catch (e) {
      const error = {
        code: e?.response?.status,
        message: e?.response?.data?.statusMessage,
        data: e?.response?.data,
      };

      return error;
    }
  };

  const getExchangeRate = async (amount: string) => {
    try {
      dispatch({
        type: WalletType.SET_QOIN_RATE_LOADING,
        payload: true,
      });
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.exchangeRate,
        queryParams: {
          exchange: 'qoin_idr',
          amount: amount,
        },
      };
      const response = await provider(objProvider);

      if (response.status === 200) {
        let data = response.data.Data;

        let payload = {
          fee: data.fee,
          fee_amount: data.fee_amount,
          ppn: data.ppn,
          ppn_amount: data.ppn_amount,
          discount: data.discount,
          disc_price: data.disc_price,
          fix_rate: data.fix_rate,
          markup_price: data.markup_price,
          total: 0,
          isLoading: false,
        };

        let total = payload.markup_price;
        payload.total = total;
        dispatch({
          type: WalletType.SET_QOIN_RATE,
          payload: payload,
        });
      }
      dispatch({
        type: WalletType.SET_QOIN_RATE_LOADING,
        payload: false,
      });
    } catch (e) {
      const error = {
        status: 0,
        error: e,
      };
      dispatch({
        type: WalletType.SET_QOIN_RATE_LOADING,
        payload: false,
      });
    }
  };

  const getMemberData = async (qtag: string) => {
    let callbackData = {
      status: 0,
      message: '',
      error: null,
      data: null,
    };
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.getMembership,
        queryParams: {
          qtag: qtag,
        },
      };
      const response = await provider(objProvider);

      if (response.status === 200) {
        let ticket = response.data.data.ticket;

        let responseCallback = {
          code: 0,
          message: '',
          data: null,
        };

        const myPromise = await new Promise((resolve) => {
          const getMembershipInterval = setInterval(async () => {
            if (responseCallback.code == 202 || responseCallback.code == 0) {
              responseCallback = await callbackGetMembership(ticket);
              if (responseCallback.code == 200) {
                callbackData.data = responseCallback.data.data;
                callbackData.status = 1;

                resolve(callbackData);
              } else if (responseCallback.code != 202) {
                callbackData.message = responseCallback.message;
                resolve(callbackData);
              }
            } else {
              clearInterval(getMembershipInterval);
            }
          }, 1000);
        });

        return callbackData;
      } else {
        const error = {
          code: response?.status,
          message: response?.data?.statusMessage,
          data: null,
        };
        callbackData.message = error.message;
        callbackData.error = error;
        return callbackData;
      }
    } catch (e) {
      callbackData.error = e;
      callbackData.message = 'Error while getting data';
      return callbackData;
    }
  };

  const callbackGetMembership = async (ticket: string) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.getMembershipCallback,
        queryParams: {
          ticket: ticket,
        },
      };
      const response = await provider(objProvider);
      let responseCallback = {
        code: response.status,
        message: response.statusText,
        data: response.data,
      };

      return responseCallback;
      // const res = {
      //   code: response?.status,
      //   message: "Login Success!",
      //   data: response?.ticket,
      // };

      // setAuthToken(response?.data?.token);
      // dispatch(userLoginSuccess(response?.data));
      // callback?.(res);
    } catch (e) {
      const error = {
        code: e?.response?.status,
        message: e?.response?.data?.message,
        data: e?.response?.data,
      };

      return error;
    }
  };

  const resetWallet = () => {
    dispatch({
      type: WalletType.RESET_WALLET,
      payload: '',
    });
  };

  return {
    managementWalletState,
    setAddress,
    resetWallet,
    setSolanaPublickey,
    setQoinPublickey,
    setSolanaBalance,
    getQoinToken,
    splSendTransaction,
    gsnSendTransaction,
    getExchangeRate,
    managementQoinRateState,
    getMemberData,
  };
};

export const createTicketPending = (): WalletTicketAction => ({
  type: WalletTicket.WALLET_TICKET_PENDING,
});

export const createTicketSuccess = (
  data: IResponseTransaction
): WalletTicketAction => ({
  type: WalletTicket.WALLET_TICKET_SUCCESS,
  payload: {
    data,
  },
});

export const createTicketError = (error: AxiosError): WalletTicketAction => ({
  type: WalletTicket.WALLET_TICKET_ERROR,
  payload: {
    error,
  },
});

export const confirmationTicketAction = (
  bodyData: IBodyWalletTicket,
  callback?: (data: IResponseTransaction) => void
): ThunkAction<void, RootState, null, WalletTicketAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: '/webtopup/transaction/create',
        data: bodyData,
      };

      const response = await provider(objProvider);
      dispatch(createTicketSuccess(response));
      callback(response);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(createTicketError(e));
    }
  };
};

export const confirmationCallbackTicketAction = (
  params: string,
  callback?: (data: any) => void
) => {
  return async () => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `/webtopup/transaction/create-response?ticket=${params}`,
      };
      const response = await provider(objProvider);

      callback(response);
    } catch (e) {
      callback(e);
    }
  };
};

export const getTransactionTicketAction = (
  params: IParamsGetTransactionTicket,
  callback?: (data: IResponseTransaction) => void
): ThunkAction<void, RootState, null, WalletTicketAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.getHistoryTransaction,
        queryParams: {
          current: params.current,
          size: params.size,
          pfield: params?.pfield,
          pvalue: params?.pvalue,
          date: params?.date,
          status: params?.status,
          eventId: params?.eventId,
          cashierId: params?.cashierId,
        },
      };
      const { data } = await provider(objProvider);

      dispatch(createTicketSuccess(data));
      callback(data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(createTicketError(e));
    }
  };
};

export const getCallbackTransactionTicketAction = (
  params: string,
  callback?: (data: any) => void
) => {
  return async () => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${endpoints.getHistoryTransactionCallback}?ticket=${params}`,
      };
      const response = await provider(objProvider);

      callback(response);
    } catch (e) {
      callback(e);
    }
  };
};

export const getDetailTransactionTicketAction = (
  params: string,
  callback?: (data: IResponseTransaction) => void
): ThunkAction<void, RootState, null, WalletTicketAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${endpoints.getHistoryDetail}/${params}`,
      };
      const { data } = await provider(objProvider);

      dispatch(createTicketSuccess(data));
      callback(data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(createTicketError(e));
    }
  };
};

export const getCallbackDetailTransactionTicketAction = (
  params: string,
  callback?: (data: IResponseTransaction) => void
): ThunkAction<void, RootState, null, WalletTicketAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${endpoints.getHistoryDetailCallback}?ticket=${params}`,
      };
      const { data } = await provider(objProvider);

      callback(data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(createTicketError(e));
    }
  };
};

export default useWalletAction;

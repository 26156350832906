/* eslint-disable import/no-anonymous-default-export */
import { EventAction, EventType, IEventtState } from 'stores/types/eventTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IEventtState = initialState,
  { type, payload }: EventAction
) => {
  switch (type) {
    case EventType.EVENT_LIST_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case EventType.EVENT_LIST_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case EventType.EVENT_LIST_ERROR:
      return { ...state, ...payload, isLoading: false };

    default:
      return state;
  }
};

const SVGComponent = (props: any) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.34583 6C2.34583 5.44772 2.79354 5 3.34583 5H21.3458C21.8981 5 22.3458 5.44772 22.3458 6C22.3458 6.55228 21.8981 7 21.3458 7H3.34583C2.79354 7 2.34583 6.55228 2.34583 6ZM6.34583 12C6.34583 11.4477 6.79354 11 7.34583 11H21.3458C21.8981 11 22.3458 11.4477 22.3458 12C22.3458 12.5523 21.8981 13 21.3458 13H7.34583C6.79354 13 6.34583 12.5523 6.34583 12ZM3.34583 17C2.79354 17 2.34583 17.4477 2.34583 18C2.34583 18.5523 2.79354 19 3.34583 19H21.3458C21.8981 19 22.3458 18.5523 22.3458 18C22.3458 17.4477 21.8981 17 21.3458 17H3.34583Z"
      fill="#212529"
    />
  </svg>
);

export default SVGComponent;
const SVGComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.444 20H5.555C5.249 20 5 19.776 5 19.5V4.5C5 4.224 5.249 4 5.555 4H10V8.15C10 9.722 11.217 11 12.714 11H17V19.5C17 19.776 16.75 20 16.444 20ZM16.649 9H12.714C12.32 9 12 8.619 12 8.15V4H12.112L16.649 9ZM18.74 8.328L13.296 2.328C13.107 2.119 12.838 2 12.555 2H5.555C4.146 2 3 3.122 3 4.5V19.5C3 20.878 4.146 22 5.555 22H16.444C17.853 22 19 20.878 19 19.5V9C19 8.751 18.907 8.512 18.74 8.328Z"
      fill="#231F20"
    />
    <rect x={8} y={12} width={14} height={7} rx={1} fill="#D38D4D" />
    <path
      d="M13.5166 15.0618H12.9185C12.9076 14.981 12.8853 14.9093 12.8516 14.8466C12.8179 14.7829 12.7747 14.7287 12.7219 14.6841C12.6691 14.6394 12.6081 14.6052 12.5389 14.5815C12.4706 14.5577 12.3964 14.5458 12.3163 14.5458C12.1716 14.5458 12.0455 14.5834 11.9381 14.6584C11.8307 14.7325 11.7474 14.8409 11.6882 14.9834C11.629 15.1249 11.5994 15.2969 11.5994 15.4993C11.5994 15.7074 11.629 15.8822 11.6882 16.0238C11.7483 16.1653 11.832 16.2722 11.9394 16.3444C12.0469 16.4166 12.1711 16.4527 12.3122 16.4527C12.3914 16.4527 12.4647 16.4418 12.5321 16.42C12.6003 16.3981 12.6609 16.3663 12.7137 16.3245C12.7665 16.2817 12.8102 16.2299 12.8448 16.1691C12.8803 16.1083 12.9048 16.039 12.9185 15.961L13.5166 15.9639C13.5011 16.0979 13.4624 16.2271 13.4005 16.3515C13.3395 16.4751 13.2571 16.5857 13.1534 16.6836C13.0505 16.7805 12.9276 16.8575 12.7847 16.9145C12.6427 16.9705 12.482 16.9986 12.3027 16.9986C12.0532 16.9986 11.8302 16.9397 11.6336 16.8219C11.4379 16.704 11.2831 16.5335 11.1693 16.3102C11.0564 16.0869 11 15.8166 11 15.4993C11 15.181 11.0574 14.9102 11.1721 14.6869C11.2868 14.4637 11.4424 14.2936 11.639 14.1767C11.8357 14.0589 12.0569 14 12.3027 14C12.4647 14 12.6149 14.0238 12.7533 14.0713C12.8926 14.1188 13.0159 14.1881 13.1233 14.2793C13.2307 14.3696 13.3181 14.4803 13.3855 14.6114C13.4538 14.7425 13.4975 14.8926 13.5166 15.0618Z"
      fill="white"
    />
    <path
      d="M15.4576 14.8793C15.4467 14.7644 15.3998 14.6751 15.317 14.6114C15.2341 14.5477 15.1217 14.5159 14.9797 14.5159C14.8832 14.5159 14.8017 14.5302 14.7353 14.5587C14.6688 14.5862 14.6178 14.6247 14.5823 14.6741C14.5477 14.7235 14.5304 14.7796 14.5304 14.8423C14.5286 14.8945 14.5391 14.9401 14.5619 14.9791C14.5855 15.0181 14.6178 15.0518 14.6588 15.0803C14.6998 15.1078 14.7471 15.1321 14.8008 15.153C14.8545 15.1729 14.9119 15.19 14.9729 15.2043L15.2241 15.267C15.3461 15.2955 15.4581 15.3335 15.56 15.381C15.662 15.4285 15.7503 15.4869 15.8249 15.5563C15.8996 15.6257 15.9574 15.7074 15.9983 15.8014C16.0402 15.8955 16.0616 16.0033 16.0625 16.1249C16.0616 16.3036 16.0179 16.4584 15.9314 16.5895C15.8459 16.7197 15.7221 16.8209 15.56 16.8931C15.3989 16.9644 15.2045 17 14.977 17C14.7512 17 14.5546 16.9639 14.3871 16.8917C14.2205 16.8195 14.0903 16.7126 13.9965 16.571C13.9037 16.4285 13.855 16.2523 13.8504 16.0423H14.4226C14.4289 16.1401 14.4558 16.2219 14.5031 16.2874C14.5514 16.352 14.6156 16.401 14.6957 16.4342C14.7767 16.4665 14.8682 16.4827 14.9701 16.4827C15.0703 16.4827 15.1572 16.4675 15.2309 16.4371C15.3056 16.4067 15.3634 16.3644 15.4044 16.3102C15.4453 16.2561 15.4658 16.1938 15.4658 16.1235C15.4658 16.058 15.4471 16.0029 15.4098 15.9582C15.3734 15.9135 15.3197 15.8755 15.2487 15.8442C15.1786 15.8128 15.0926 15.7843 14.9906 15.7587L14.6861 15.6789C14.4503 15.619 14.2642 15.5254 14.1276 15.3981C13.9911 15.2708 13.9233 15.0993 13.9242 14.8836C13.9233 14.7069 13.9683 14.5525 14.0594 14.4204C14.1513 14.2884 14.2774 14.1853 14.4376 14.1112C14.5978 14.0371 14.7799 14 14.9838 14C15.1913 14 15.3725 14.0371 15.5272 14.1112C15.6829 14.1853 15.804 14.2884 15.8905 14.4204C15.9769 14.5525 16.0215 14.7055 16.0243 14.8793H15.4576Z"
      fill="white"
    />
    <path
      d="M16.9654 14.0399L17.6413 16.2575H17.6673L18.3446 14.0399H19L18.036 16.9587H17.274L16.3086 14.0399H16.9654Z"
      fill="white"
    />
  </svg>
);

export default SVGComponent;

import { AxiosError } from 'axios';

// Action Types
export enum WalletType {
  SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS',
  SET_SOLANA_PUBLICKEY = 'SET_SOLANA_PUBLICKEY',
  SET_QOIN_PUBLICKEY = 'SET_QOIN_PUBLICKEY',
  SET_SOLANA_BALANCE = 'SET_SOLANA_BALANCE',
  SET_QOIN_BALANCE = 'SET_QOIN_BALANCE',
  SET_QOIN_RATE = 'SET_QOIN_RATE',
  SET_QOIN_RATE_LOADING = 'SET_QOIN_RATE_LOADING',
  RESET_QOIN_RATE = 'RESET_QOIN_RATE',
  RESET_WALLET = 'RESET_WALLET',
  SET_CONFIRMATION = 'SET_CONFIRMATION',
}

export enum WalletTicket {
  WALLET_TICKET_SUCCESS = 'WALLET_TICKET_SUCCESS',
  WALLET_TICKET_PENDING = 'WALLET_TICKET_PENDING',
  WALLET_TICKET_ERROR = 'WALLET_TICKET_ERROR',
}

// IState Wallet
export interface IWalletState {
  address: string;
  solana: IWalletDetail;
  qoinToken: IWalletDetail;
  rateQoin: IQoinRate;
  isGettingConfirmation: boolean;
}

export interface IWalletTicketState {
  data: IResponseTransaction;
  isLoading: boolean;
  error: AxiosError;
}

export interface IWalletDetail {
  publicKey: string;
  balance: number;
  isLoading: Boolean;
}

export interface IQoinRate {
  fee: number;
  fee_amount: number;
  ppn: number;
  ppn_amount: number;
  discount: number;
  disc_price: number;
  fix_rate: number;
  markup_price: number;
  total: number;
  isLoading: boolean;
}

// IAction Wallet
interface GetWalletTicketAction {
  type: WalletTicket.WALLET_TICKET_SUCCESS;
  payload: {
    data: IResponseTransaction;
  };
}

interface SetLoadingTicketAction {
  type: WalletTicket.WALLET_TICKET_PENDING;
  payload?: any;
}

interface SetErrorTicketAction {
  type: WalletTicket.WALLET_TICKET_ERROR;
  payload: {
    error: AxiosError;
  };
}

// IBody Create Wallet
export interface IBodyWalletTicket {
  memberId: number;
  name: string;
  phone: string;
  email: string;
  addressReceiver: string;
  addressSender: string;
  amount: number | string;
  CryptoAmount: number | string;
  TokenType: string;
  UseGSN: number | string;
  eventId: string;
  eventName?: string;
  transactionHashSignature: string;
}

// IParams Get Transaction Ticket
export interface IParamsGetTransactionTicket {
  current: number;
  size: number;
  pfield: string;
  pvalue: string;
  date: string;
  status: string;
  eventId: string;
  cashierId: string;
}

// IResponse Create Wallet
export interface IResponseTransaction {
  requestId: string;
  code: number | string;
  status: string;
  page: IParamTransactions;
  data?: IWalletTicket & IWalletTransaction[] & IWalletDetailTransaction;
}

export interface IParamTransactions {
  current: number;
  pages: number;
  rows: number;
  size: number;
  pfield: string;
  pvalue: string;
  date: string;
  status: string;
  eventId: string;
  cashierId: string;
}

export interface IWalletTicket {
  ticket: string;
}

export interface IWalletTransaction {
  trxId: number | string;
  trxReceipt: string;
  trxDate: string;
  addressReceiver: string;
  amount: number;
  status: number;
}

export interface IWalletDetailTransaction {
  id: number;
  memberId: string | number;
  name: string;
  phone: string;
  email: string;
  addressReceiver: string;
  addressSender: string;
  amount: number;
  cryptoAmount: number | string;
  tokenType: string;
  useGSN: boolean;
  event: string;
  transactionHashSignature: string;
  trxRate: number;
  trxReceipt: string;
  cashierName: string;
  date: string;
}

export type WalletTicketAction =
  | GetWalletTicketAction
  | SetLoadingTicketAction
  | SetErrorTicketAction;

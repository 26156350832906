const SVGComponent = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66671 9.17065V12.046C2.66671 12.2047 2.81604 12.334 3.00004 12.334H13C13.184 12.334 13.3334 12.2047 13.3334 12.046V6.31932C13.3334 6.15998 13.184 6.03132 13 6.03132H8.00004C7.80004 6.03132 7.61071 5.94132 7.48404 5.78598L5.75071 3.66732H3.00004C2.81604 3.66732 2.66671 3.79598 2.66671 3.95465V9.17065ZM13 13.6673H3.00004C2.08137 13.6673 1.33337 12.94 1.33337 12.046V3.95465C1.33337 3.06065 2.08137 2.33398 3.00004 2.33398H6.06737C6.26671 2.33398 6.45671 2.42332 6.58337 2.57865L8.31604 4.69798H13C13.9187 4.69798 14.6667 5.42465 14.6667 6.31932V12.046C14.6667 12.94 13.9187 13.6673 13 13.6673Z"
      fill="#231F20"
    />
    <mask
      id="mask0_927_3821"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={2}
      width={14}
      height={12}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66671 9.17065V12.046C2.66671 12.2047 2.81604 12.334 3.00004 12.334H13C13.184 12.334 13.3334 12.2047 13.3334 12.046V6.31932C13.3334 6.15998 13.184 6.03132 13 6.03132H8.00004C7.80004 6.03132 7.61071 5.94132 7.48404 5.78598L5.75071 3.66732H3.00004C2.81604 3.66732 2.66671 3.79598 2.66671 3.95465V9.17065ZM13 13.6673H3.00004C2.08137 13.6673 1.33337 12.94 1.33337 12.046V3.95465C1.33337 3.06065 2.08137 2.33398 3.00004 2.33398H6.06737C6.26671 2.33398 6.45671 2.42332 6.58337 2.57865L8.31604 4.69798H13C13.9187 4.69798 14.6667 5.42465 14.6667 6.31932V12.046C14.6667 12.94 13.9187 13.6673 13 13.6673Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_927_3821)">
      <rect width={16} height={16} fill="#0D1C2E" />
    </g>
  </svg>
);

export default SVGComponent;

import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from 'utils/utility';
import { IContentContainerConfig } from './interface';

const Component: React.FC<IContentContainerConfig> = ({
  isSidebarMenu,
  isSidebarAccount,
  children,
}) => {
  const location = useLocation();
  const { width } = useWindowDimensions();

  return (
    <div
      className={`transition-all h-full allMobile:w-full xl:w-full 2xl:w-full ml-72 lg:max-w-[calc(100%_-_18rem)] xl:max-w-[calc(100%_-_18rem)] allMobile:ml-0 allMobile:bg-[#FCFCFC] ${
        width <= 640
          ? !isSidebarAccount || !isSidebarMenu
            ? 'ml-0 !h-screen overflow-x-hidden'
            : ''
          : 'ml-72'
      }`}
    >
      <div className="bg-white rounded-lg px-8 allMobile:px-0 py-6 allMobile:py-8">
        <div
          className={`${
            location?.pathname === '/'
              ? 'bg-[#FFF7E6] h-[230px] allMobile:h-[276px] w-full -z-10 absolute left-0 top-0'
              : ''
          }`}
        />
        {children}
      </div>
    </div>
  );
};

export default Component;

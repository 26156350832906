const SVGComponent = (props: any) => (
  <svg
    width={props.width ? props.width : 16}
    height={props.height ? props.height : 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_38_825)">
      <path
        d="M4.00001 6.00016V1.3335H12V6.00016M4.00001 12.0002H2.66668C2.31305 12.0002 1.97392 11.8597 1.72387 11.6096C1.47382 11.3596 1.33334 11.0205 1.33334 10.6668V7.3335C1.33334 6.97987 1.47382 6.64074 1.72387 6.39069C1.97392 6.14064 2.31305 6.00016 2.66668 6.00016H13.3333C13.687 6.00016 14.0261 6.14064 14.2762 6.39069C14.5262 6.64074 14.6667 6.97987 14.6667 7.3335V10.6668C14.6667 11.0205 14.5262 11.3596 14.2762 11.6096C14.0261 11.8597 13.687 12.0002 13.3333 12.0002H12M4.00001 9.3335H12V14.6668H4.00001V9.3335Z"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_38_825">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SVGComponent

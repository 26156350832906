import React from 'react';
import { ISwitch } from './interface';

const Component: React.FC<ISwitch> = ({
  status,
  label,
  classNameWrapper,
  classNameLabel,
  classNameSwitch,
  required,
  name,
  onChange,
}) => {
  const _renderLeftPosition = () => (
    <div className={`${classNameWrapper} flex items-center`}>
      <span
        className={`text-base text-[#212529] pr-6 whitespace-no-wrap font-medium leading-6 w-1/4 ${classNameLabel}`}
      >
        {label}
        {required ? <span className="text-[#FF433F]">*</span> : ''}
      </span>
      <div className={`${classNameSwitch} w-3/4 relative flex items-center`}>
        <input
          type="checkbox"
          name={name}
          className={`toggle ${
            status
              ? 'bg-[#00B887] border-[1px] border-[#00B887]'
              : 'bg-[#C8CBCE] border-[1px] border-[#C8CBCE]'
          }`}
          checked={status}
          onChange={onChange}
        />
      </div>
    </div>
  );

  return _renderLeftPosition();
};

export default Component;

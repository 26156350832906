import React, { useEffect, useState } from 'react';
import { IDataDropdown } from './interface';

const Component: React.FC<IDataDropdown> = ({
  data,
  filterText,
  setValue,
  className,
}) => {
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const dataOption = data?.filter(
      (dataItem: { name: string; value: string | number }) =>
        !filterText ||
        dataItem?.name?.toLowerCase().includes(filterText?.toLowerCase())
    );

    setOptions(
      dataOption.length < 1 ? [{ name: 'No options', value: '' }] : dataOption
    );
  }, [data, filterText]);

  return (
    <div
      className={`absolute z-20 bg-[#FFF] max-h-[200px] overflow-y-scroll w-full py-3 border-[#C8CBCE] border-[1px] rounded mt-1 shadow ${className}`}
    >
      {options?.map((dataOptions: any, idx: React.Key) => (
        <button
          className={`p-3 ${
            dataOptions?.value === ''
              ? 'text-[#C8CBCE]'
              : 'hover:bg-[#FFF7E6] hover:text-[#212529] hover:font-medium cursor-pointer'
          } w-full text-left`}
          key={idx}
          onClick={(evt) => {
            evt?.preventDefault();

            setValue(dataOptions);
          }}
          disabled={dataOptions?.value === ''}
          value={dataOptions?.value}
        >
          {dataOptions?.name}
        </button>
      ))}
    </div>
  );
};

export default Component;

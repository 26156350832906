const endpoints = {
  login: '/user/login',
  callbackLogin: '/User/GetJwtTokenByTicket',
  gsnSendTrans: 'sol/sendtransaction',
  exchangeRate: 'indodax/convert/idr',
  getMembership: '/webtopup/getprofile',
  getMembershipCallback: '/webtopup/getprofile-response',
  createTransaction: '/webtopup/transaction/create',
  createTransactionCallback: '/webtopup/transaction/create-response',
  getHistoryTransaction: '/webtopup/transaction/get',
  getHistoryTransactionCallback: '/webtopup/transaction/get-response',
  getHistoryDetail: '/webtopup/transaction/getbyid',
  getHistoryDetailCallback: '/webtopup/transaction/getbyid-response',
  getListCashier: '/webtopup/getcashier',
  getListCashierCallback: '/webtopup/getcashier-response',
  createEvent: '/masterevent/create',
  createEventCallback: '/masterevent/create-response',
  getListEvent: '/masterevent/get',
  getListEventCallback: '/masterevent/get-response',
  getEventDetail: '/masterevent/getbyid/',
  getEventDetailCallback: '/masterevent/getbyid-response',
  uploadFileEvent: '/masterevent/upload',
  editEvent: 'masterevent/update',
  editEventCallback: 'masterevent/update-response',
  deleteEvent: 'masterevent/delete',
  deleteEventCallback: 'masterevent/delete-response',
};

export default endpoints;

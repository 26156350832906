const SVGComponent = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={8} fill="#E6F8EF" />
    <path
      d="M29.21 23.89C28.5738 25.3945 27.5788 26.7202 26.3119 27.7513C25.045 28.7824 23.5448 29.4874 21.9424 29.8048C20.3401 30.1221 18.6844 30.0421 17.1202 29.5718C15.5559 29.1014 14.1306 28.2551 12.969 27.1067C11.8074 25.9582 10.9448 24.5428 10.4566 22.9839C9.96846 21.4251 9.86957 19.7705 10.1686 18.1646C10.4676 16.5588 11.1555 15.0506 12.1721 13.772C13.1886 12.4934 14.5029 11.4833 16 10.83M30 20C30 18.6868 29.7414 17.3864 29.2388 16.1732C28.7363 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10V20H30Z"
      stroke="#00B65B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVGComponent

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  confirmationCallbackTicketAction,
  confirmationTicketAction,
  getCallbackDetailTransactionTicketAction,
  getCallbackTransactionTicketAction,
  getDetailTransactionTicketAction,
  getTransactionTicketAction,
} from 'stores/actions/walletAction';
import {
  IBodyWalletTicket,
  IParamsGetTransactionTicket,
  IResponseTransaction,
} from 'stores/types/walletTypes';

const useWalletTicket = () => {
  const dispatch = useDispatch();
  const walletState = useSelector((state: RootState) => state.walletTicket);

  const handleConfirmationTicket = useCallback(
    (
      bodyData: IBodyWalletTicket,
      callback?: (data: IResponseTransaction) => void
    ) => {
      dispatch(confirmationTicketAction(bodyData, callback));
    },
    [dispatch]
  );

  const handleCallbackCanfirmationTicket = useCallback(
    (params: string, callback?: (data: any) => void) => {
      dispatch(confirmationCallbackTicketAction(params, callback));
    },
    [dispatch]
  );

  const handleGettingTransaction = useCallback(
    (
      params: IParamsGetTransactionTicket,
      callback?: (data: IResponseTransaction) => void
    ) => {
      dispatch(getTransactionTicketAction(params, callback));
    },
    [dispatch]
  );

  const handleGeetingTransactionCallback = useCallback(
    (params: string, callback?: (data: any) => void) => {
      dispatch(getCallbackTransactionTicketAction(params, callback));
    },
    [dispatch]
  );

  const handleGettingDetailTransaction = useCallback(
    (params: string, callback?: (data: IResponseTransaction) => void) => {
      dispatch(getDetailTransactionTicketAction(params, callback));
    },
    [dispatch]
  );

  const handleCallbackGettingDetailTransaction = useCallback(
    (params: string, callback?: (data: IResponseTransaction) => void) => {
      dispatch(getCallbackDetailTransactionTicketAction(params, callback));
    },
    [dispatch]
  );

  return {
    walletState,
    confirmationTicket: handleConfirmationTicket,
    confirmationCallbackTicket: handleCallbackCanfirmationTicket,
    getTransaction: handleGettingTransaction,
    getTransactionCallback: handleGeetingTransactionCallback,
    getDetailTransaction: handleGettingDetailTransaction,
    getDetailTransactionCallback: handleCallbackGettingDetailTransaction,
  };
};

export default useWalletTicket;

import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { rootReducer } from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IAuthState } from './types/authTypes';
import { IExampleState } from './types/exampleType';
import { IWalletState, IWalletTicketState } from './types/walletTypes';
import { ICashiertState } from './types/cashierTypes';
import { IEventtState } from './types/eventTypes';

let middleware: any[] = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer)
);

export interface RootState {
  example: IExampleState;
  auth: IAuthState;
  cashier: ICashiertState;
  event: IEventtState;
  wallet: IWalletState;
  walletTicket: IWalletTicketState;
}

export { store };

import React, { useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import useWalletAction from 'stores/actions/walletAction';
import { ISidebarMenu } from './interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuRoute } from 'components/Routing';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { useWindowDimensions } from 'utils/utility';
import { IcCloseBtn, IcLogoQoin } from 'assets/svgs';

const Component: React.FC<ISidebarMenu> = ({
  setIsSidebarMenu,
  isSidebarMenu,
}) => {
  const navigate = useNavigate();
  const wallet = useWallet();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { setAddress, managementWalletState, resetWallet } = useWalletAction();

  useEffect(() => {
    if (wallet.publicKey) {
      setAddress(wallet.publicKey.toBase58());
    } else {
      resetWallet();
    }
  }, [wallet.publicKey]);

  return (
    <div className="block">
      <div
        onClick={() => setIsSidebarMenu(false)}
        className={`allMobile:w-screen allMobile:h-screen z-50 absolute ${
          !isSidebarMenu
            ? 'allMobile:bg-transparent allMobile:hidden'
            : 'allMobile:bg-[#21252951]'
        }`}
      />
      <aside
        className={`transition-all z-50 fixed inset-0 flex flex-col flex-shrink-0 w-72 allMobile:ml-[20%] allMobile:w-4/5 h-full text-gray-700 bg-[#212529] inset-y-0 ${
          !isSidebarMenu ? 'allMobile:hidden' : 'allMobile:block'
        }`}
      >
        <div className="overflow-y-auto p-4 bg-[#212529] flex flex-col h-screen">
          <div className="flex justify-center allMobile:justify-end items-center">
            {width <= 640 ? (
              <div className="mb-8" onClick={() => setIsSidebarMenu(false)}>
                <IcCloseBtn />
              </div>
            ) : (
              <a href="/" className="my-[46px]">
                <IcLogoQoin />
              </a>
            )}
          </div>
          <div
            id="dropdown-cta"
            className="p-4 mb-6 bg-[#2B3036] rounded-lg"
            role="alert"
          >
            <WalletMultiButton className="w-full allMobile:text-2xl allMobile:h-max allMobile:py-4" />
            {!wallet.publicKey ? (
              <p className="mb-3 text-sm text-[#EDEEEF] my-2">
                Please connect your wallet to manage your token.
              </p>
            ) : (
              <div>
                <p className="mb-3 text-sm allMobile:text-base text-[#EDEEEF] my-2 allMobile:mb-0 allMobile:mt-4 font-semibold allMobile:font-normal">
                  Balance:{' '}
                  {new Intl.NumberFormat('id').format(
                    managementWalletState?.qoinToken?.balance
                  )}{' '}
                  QOIN
                </p>
              </div>
            )}
          </div>
          <ul className="space-y-2">
            {menuRoute?.map((menu, idx: React.Key) => (
              <li key={idx}>
                <div
                  onClick={() => navigate(menu.path)}
                  className={`cursor-pointer flex items-center px-[18px] py-4 allMobile:py-3 allMobile:px-4 text-base allMobile:mb-3 rounded-lg font-normal text-[#9AA4B2] hover:bg-[#2B3036] ${
                    location?.pathname.includes(menu?.path)
                      ? 'text-white bg-[#2B3036] text-[#FFB100] font-medium'
                      : 'font-normal text-[#9AA4B2] hover:bg-[#32373c]'
                  }`}
                >
                  <div className="mr-4">
                    <menu.icon
                      color={
                        location?.pathname.includes(menu?.path)
                          ? '#FFB100'
                          : '#9AA4B2'
                      }
                    />
                  </div>
                  <p
                    className={`${
                      location?.pathname.includes(menu?.path)
                        ? 'text-[#FFB100]'
                        : 'text-[#9AA4B2]'
                    }`}
                  >
                    {menu?.name}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Component;

const SVGComponent = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={8} fill="#FFECEC" />
    <path
      d="M9 12V18M9 18H15M9 18L13.64 13.64C14.7147 12.5647 16.0444 11.7792 17.5048 11.3567C18.9652 10.9343 20.5089 10.8887 21.9917 11.2242C23.4745 11.5597 24.8482 12.2654 25.9845 13.2754C27.1209 14.2854 27.9828 15.5667 28.49 17M31 28V22M31 22H25M31 22L26.36 26.36C25.2853 27.4352 23.9556 28.2207 22.4952 28.6432C21.0348 29.0656 19.4911 29.1112 18.0083 28.7757C16.5255 28.4402 15.1518 27.7345 14.0155 26.7245C12.8791 25.7145 12.0172 24.4332 11.51 23"
      stroke="#FF433F"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVGComponent

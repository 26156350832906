import React, { useState, useEffect } from 'react';
import { ITableAction } from './interface';
import Input from 'components/atoms/Input';

import { staticConst } from 'constants/staticConst';
import { IDropdown } from 'components/atoms/Dropdown/interface';
import { Button } from 'components/atoms';
import {
  IcCsvExport,
  IcDropdown,
  IcExcelExport,
  IcFilter,
  IcPdfExport,
  IcPrint,
  IcSearch,
} from 'assets/svgs';
import * as XLSX from 'xlsx';
import { CSVLink } from 'react-csv';
import BlockUi from 'react-block-ui';
import ExportPDF from 'utils/ExportPDF';

const Component: React.FC<ITableAction> = ({
  withAction,
  isModalFilter,
  setIsModalFilter,
  handleListData,
  typeName,
  searchWithDropdown,
  addIcon,
  setAction,
  action,
  exportFileName,
  exportData,
}) => {
  const [searchText, setSearchHistoty] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchTextDropdown, setSearchTextDropdown] = useState<IDropdown>({
    name: '',
    value: '',
  });
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const csvRef = React.useRef();
  const [csv, setcsv] = React.useState([]);

  useEffect(() => {
    return () => {
      setcsv([]);
    };
  }, []);

  useEffect(() => {
    if (csv.length != 0) {
      var link = document.getElementById('export-button-csv');
      link.click();
    }
  }, [csv]);

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchHistoty(evt?.target?.value);
  };

  const handlesearchWithDropdown = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTextDropdown({
      name: evt?.target?.value,
      value: '',
    });
  };

  const handleKeyPressSubmit = (evt: React.KeyboardEvent<HTMLImageElement>) => {
    if (evt?.key === 'Enter') {
      if (searchWithDropdown) {
        setAction({
          ...action,
          pfield: searchTextDropdown?.value,
          pvalue: searchText,
        });
      } else {
        setAction({
          ...action,
          keyword: searchText,
        });
      }
    }
  };

  const handleBlurSubmit = () => {
    if (searchWithDropdown) {
      setAction({
        ...action,
        pfield: searchTextDropdown?.value,
        pvalue: searchText,
      });
    } else {
      setAction({
        ...action,
        keyword: searchText,
      });
    }
  };

  const exportDatas = async (type) => {
    setShowDropdown(false);
    setLoadingExport(true);
    const { dataExcel, dataCSV } = await exportData(type);
    dataCSV.filename = exportFileName;
    setLoadingExport(false);
    if (type === 'excel') {
      const ws = XLSX.utils.json_to_sheet(dataExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'People');
      return XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    } else if (type === 'csv') {
      setcsv([dataCSV.column, ...dataCSV.data]);
    } else if (type === 'pdf') {
      ExportPDF({ type, ...dataCSV });
    }
  };

  return (
    <div className="flex justify-between allMobile:flex-col items-center mb-6">
      <div className="flex allMobile:flex-col items-center allMobile:w-full">
        {searchWithDropdown && (
          <div className="allMobile:w-full allMobile:px-6 allMobile:mb-3">
            <Input
              subfix
              type="select"
              name="dropdown"
              classNameWrapper="mt-0 allMobile:!mt-0 w-[180px] allMobile:w-full"
              classNameWrapperInput="allMobile:!mt-0"
              classNameInput="h-12 shadow-sm rounded-r-[0] allMobile:rounded border-r-[0] allMobile:border-r-[1px]"
              value={searchTextDropdown?.name}
              setValue={setSearchTextDropdown}
              placeholder="Search by..."
              handleChange={handlesearchWithDropdown}
              dataDropdown={staticConst?.optionsHistory}
              icon={<IcDropdown />}
            />
          </div>
        )}
        <div className="allMobile:w-full allMobile:px-6 allMobile:mb-3">
          <Input
            prefix
            type="text"
            handleChange={handleSearch}
            value={searchText}
            name="search"
            onKeyDown={handleKeyPressSubmit}
            onBlur={handleBlurSubmit}
            classNameWrapper="mt-0 allMobile:!mt-0"
            classNameWrapperInput="allMobile:!mt-0"
            classNameInput={`h-12 shadow-sm w-[320px] pl-[42px] ${
              searchWithDropdown ? 'rounded-l-[0]' : 'rounded'
            } allMobile:rounded allMobile:border-r-[1px]`}
            placeholder="Search..."
            icon={<IcSearch />}
          />
        </div>
      </div>
      <div
        className={`flex allMobile:grid allMobile:grid-cols-${
          withAction.length !== 2 ? '1' : '2'
        } allMobile:gap-2 justify-between items-center allMobile:w-full allMobile:px-6`}
      >
        {withAction?.map((act: string, idx: number) =>
          (() => {
            switch (act) {
              case 'filter':
                return (
                  <Button
                    key={idx}
                    btnAct
                    type="button"
                    className="mr-4"
                    onClick={() => setIsModalFilter(!isModalFilter)}
                    icon={<IcFilter />}
                  >
                    Filter
                  </Button>
                );
              case 'add':
                return (
                  <Button
                    key={idx}
                    type="button"
                    btnAct
                    className="mr-4"
                    onClick={handleListData}
                    icon={addIcon}
                  >
                    {`Tambah ${typeName}`}
                  </Button>
                );
              case 'export':
                return (
                  <BlockUi blocking={loadingExport} keepInView>
                    <div className="relative">
                      <Button
                        key={idx}
                        type="button"
                        btnAct
                        onClick={() => setShowDropdown(!showDropdown)}
                        icon={<IcPrint />}
                      >
                        Export Data
                      </Button>
                      <CSVLink
                        id="export-button-csv"
                        data={csv}
                        className="d-none"
                        filename={`${exportFileName}.csv`}
                        ref={csvRef}
                      />

                      {showDropdown && (
                        <div className="absolute rounded z-50 w-full bg-[#FFF] shadow-sm border-[1px] mt-1 border-[#C8CBCE] py-4">
                          <div
                            className="p-3 justify-start flex hover:bg-[#FFF7E6] hover:text-[#212529] hover:font-medium cursor-pointer"
                            onClick={() => exportDatas('excel')}
                          >
                            <IcExcelExport className="mr-3" /> Excel
                          </div>
                          <div
                            className="p-3 justify-start flex hover:bg-[#FFF7E6] hover:text-[#212529] hover:font-medium cursor-pointer"
                            onClick={() => exportDatas('pdf')}
                          >
                            <IcPdfExport className="mr-3" /> PDF
                          </div>
                          <div
                            className="p-3 justify-start flex hover:bg-[#FFF7E6] hover:text-[#212529] hover:font-medium cursor-pointer"
                            onClick={() => exportDatas('csv')}
                          >
                            <IcCsvExport className="mr-3" /> CSV
                          </div>
                        </div>
                      )}
                    </div>
                  </BlockUi>
                );
            }
          })()
        )}
      </div>
    </div>
  );
};

export default Component;

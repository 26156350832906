const SVGComponent = (props: any) => (
  <svg
    width={6}
    height={8}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 7.5111C6 7.92345 5.45076 8.14991 5.0863 7.88784L0.203588 4.37674C-0.067863 4.18154 -0.067863 3.81846 0.203588 3.62326L5.0863 0.112164C5.45076 -0.149916 6 0.0765475 6 0.488903L6 7.5111Z"
      fill={props?.color}
    />
  </svg>
);

export default SVGComponent;

import React, { useEffect, useState } from 'react';
import useWalletAction from 'stores/actions/walletAction';
import { getAuthToken, notification, removeAuthToken } from 'utils';
import { useNavigate } from 'react-router-dom';

import { capitalize, encodeGetAccount, firstLatter } from 'utils/utility';
import { Button, Input } from 'components/atoms';
import { IcCloseBtn, IcLogout } from 'assets/svgs';

const Component: React.FC<any> = ({
  setIsSidebarAccount,
  isSidebarAccount,
}) => {
  const { managementWalletState } = useWalletAction();
  const navigate = useNavigate();
  const [settingAcc, setSettingAcc] = useState<
    'profileInfo' | 'changePassword'
  >('profileInfo');
  const [fullname, setFullname] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    let token = getAuthToken();
    if (token) {
      const account = encodeGetAccount(token);
      const name = !account?.claims?.lastname
        ? account?.claims?.firstname
        : `${account?.claims?.firstname} ${account?.claims?.lastname}`;
      const email = account?.claims?.email;

      setFullname(name);
      setEmail(email);
    }
  }, []);

  const logoutFunction = () => {
    if (managementWalletState.address !== '') {
      notification('error', 'You Need To Disconnected Wallet Before Logout');
    } else {
      removeAuthToken();
      navigate('/login');
    }
  };

  return (
    <div>
      <div
        onClick={() => setIsSidebarAccount(false)}
        className={`transition-all absolute right-0 top-0 h-screen overflow-y-scroll w-full ${
          isSidebarAccount
            ? 'bg-[#21252951] z-50 block'
            : 'bg-transparent -z-10 hidden'
        }`}
      />
      <div
        className={`transition-all tr w-[400px] allMobile:w-full bg-[#FFF] absolute top-0 h-screen z-[60] overflow-y-scroll right-0 ${
          isSidebarAccount ? '' : '-right-[400px] hidden'
        }`}
      >
        <div className="bg-[#212529] h-[284px] p-10 text-[#FFF] text-center">
          <IcCloseBtn
            className="mb-2 float-right cursor-pointer"
            onClick={() => setIsSidebarAccount(false)}
          />
          <div className="clear-both">
            <div className="w-20 h-20 text-2xl flex items-center justify-center mx-auto mb-3 border-2 border-[#EDEEEF] rounded-full">
              {firstLatter(fullname)}
            </div>
            <h3 className="text-lg mb-2 font-medium">{capitalize(fullname)}</h3>
            <div className="bg-[#2B3036] py-2 px-[10px] max-w-max mx-auto rounded text-[#C8CBCE] text-sm">
              Balance :{' '}
              {new Intl.NumberFormat('id').format(
                managementWalletState?.qoinToken?.balance
              )}{' '}
              QOIN
            </div>
          </div>
        </div>

        {settingAcc === 'profileInfo' ? (
          <ComponentProfileInfo
            data={{
              email,
              fullname,
            }}
            setSettingAcc={setSettingAcc}
            logoutFunction={logoutFunction}
          />
        ) : (
          <ComponentChangePassword setSettingAcc={setSettingAcc} />
        )}
      </div>
    </div>
  );
};

const ComponentProfileInfo: React.FC<any> = ({
  data,
  setSettingAcc,
  logoutFunction,
}) => {
  const [dataProfile, setDataProfile] = useState<any>(data);

  useEffect(() => {
    setDataProfile(data);
  }, [data]);

  const handleChangeProfile = (evt: any) => {
    setDataProfile({
      ...dataProfile,
      [evt?.target?.name]: evt?.target?.value,
    });
  };

  return (
    <div className="py-10 px-6 bg-[#FFF]">
      <h4 className="text-[18px] text-[#212529] font-medium mb-6">
        Profile Info
      </h4>
      <div>
        <Input
          value={dataProfile?.fullname}
          handleChange={handleChangeProfile}
          placeholder="Maria Amalia"
          type="text"
          name="fullname"
          label="Nama"
          classNameWrapper="mb-4"
        />
        <Input
          value={dataProfile?.email}
          handleChange={handleChangeProfile}
          placeholder="maria@gmail.com"
          type="text"
          name="email"
          label="Email"
          classNameWrapper="mb-4"
        />
        <Input
          value=""
          placeholder="**********"
          type="text"
          name="password"
          label="Kata Sandi"
          handleClickSubfix={() => setSettingAcc('changePassword')}
          classNameWrapper="mb-4"
          subfix
          icon="Ubah Sandi"
          classNameSubfix="px-3 py-3 text-sm text-[#FFB100]"
        />
        <div
          onClick={logoutFunction}
          className="mt-[112px] cursor-pointer flex justify-center items-center border-[1px] border-[#E83D39] rounded py-3 bg-[#FFECEC]"
        >
          <p className="mr-4 text-[#FF433F] font-medium">Keluar</p>
          <IcLogout />
        </div>
      </div>
    </div>
  );
};

const ComponentChangePassword: React.FC<any> = ({ setSettingAcc }) => {
  return (
    <div className="py-10 px-6 bg-[#FFF]">
      <h4 className="text-[18px] text-[#212529] font-medium mb-6">
        Ubah Kata Sandi
      </h4>
      <div>
        <Input
          value=""
          placeholder="Masukkan kata sandi lama anda"
          type="text"
          name="oldPassword"
          label="Kata Sandi Lama"
          classNameWrapper="mb-4"
        />
        <Input
          value=""
          placeholder="Masukkan kata sandi baru"
          type="text"
          name="newPassword"
          label="Kata Sandi Baru"
          classNameWrapper="mb-6"
        />
        <div className="grid grid-cols-2 gap-4">
          <Button
            onClick={() => setSettingAcc('profileInfo')}
            type="button"
            btnType="secondary"
            className="bg-[#FFF] border-[#C8CBCE] border-[1px]"
          >
            Batalkan
          </Button>
          <Button onClick={() => null} type="button" btnType="primary">
            Ubah
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Component;

const SVGComponent = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.94283 8.00017L11.8048 5.13817C12.0655 4.8775 12.0655 4.45617 11.8048 4.1955C11.5442 3.93483 11.1228 3.93483 10.8622 4.1955L8.00017 7.0575L5.13817 4.1955C4.8775 3.93483 4.45617 3.93483 4.1955 4.1955C3.93483 4.45617 3.93483 4.8775 4.1955 5.13817L7.0575 8.00017L4.1955 10.8622C3.93483 11.1228 3.93483 11.5442 4.1955 11.8048C4.3255 11.9348 4.49617 12.0002 4.66683 12.0002C4.8375 12.0002 5.00817 11.9348 5.13817 11.8048L8.00017 8.94283L10.8622 11.8048C10.9922 11.9348 11.1628 12.0002 11.3335 12.0002C11.5042 12.0002 11.6748 11.9348 11.8048 11.8048C12.0655 11.5442 12.0655 11.1228 11.8048 10.8622L8.94283 8.00017Z"
      fill="#DBDCDE"
    />
    <mask
      id="mask0_927_4291"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={4}
      width={9}
      height={8}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94283 8.00017L11.8048 5.13817C12.0655 4.8775 12.0655 4.45617 11.8048 4.1955C11.5442 3.93483 11.1228 3.93483 10.8622 4.1955L8.00017 7.0575L5.13817 4.1955C4.8775 3.93483 4.45617 3.93483 4.1955 4.1955C3.93483 4.45617 3.93483 4.8775 4.1955 5.13817L7.0575 8.00017L4.1955 10.8622C3.93483 11.1228 3.93483 11.5442 4.1955 11.8048C4.3255 11.9348 4.49617 12.0002 4.66683 12.0002C4.8375 12.0002 5.00817 11.9348 5.13817 11.8048L8.00017 8.94283L10.8622 11.8048C10.9922 11.9348 11.1628 12.0002 11.3335 12.0002C11.5042 12.0002 11.6748 11.9348 11.8048 11.8048C12.0655 11.5442 12.0655 11.1228 11.8048 10.8622L8.94283 8.00017Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_927_4291)">
      <rect width={16} height={16} fill="#727981" />
    </g>
  </svg>
);

export default SVGComponent;

import React, { useState } from 'react';
import { IInput } from './interface';
import { InfoCircle } from 'iconsax-react';
import Upload from '../Upload';
import Dropdown from '../Dropdown';

const Component: React.FC<IInput> = ({
  prefix,
  subfix,
  icon,
  withInfo,
  tooltipTitle,
  classNameLabel,
  classNameWrapper,
  classNameInput,
  classNameWrapperInput,
  classNameWrapperType,
  handleChange,
  multiple,
  labelPosition,
  classNameSubfix,
  classNamePrefix,
  handleClickSubfix,
  handleClickPrefix,
  dataDropdown,
  setValue,
  files,
  setFiles,
  required,
  ...props
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleFocus = () => setShowDropdown(true);

  const handleBlur = () => setTimeout(() => setShowDropdown(false), 300);

  const _renderLeftPosition = () => (
    <div className={`${classNameWrapper} flex items-center`}>
      <span
        className={`text-base text-[#212529] pr-6 py-3 whitespace-no-wrap font-medium leading-6 w-1/4 ${classNameLabel}`}
      >
        {props?.label}{' '}
        {required ? <span className="text-[#FF433F]">*</span> : ''}
      </span>
      {prefix && (
        <button
          onClick={handleClickPrefix}
          className={`absolute left-0 top-0 p-4 ${classNamePrefix}`}
        >
          {icon}
        </button>
      )}
      <div className={`${classNameInput} w-3/4 relative`}>
        {props?.type === 'file' ? (
          <div className="mb-5">
            <Upload files={files} setFiles={setFiles} />
            <p className="mt-4">
              Maksimal 2 gambar dengan ukuran file maksimal sebesar 10 MB.
            </p>
          </div>
        ) : (
          <input
            {...props}
            min={props?.min || 0}
            autoComplete="off"
            onChange={handleChange}
            className={`p-4 h-14 border-[1px] w-full border-[#C8CBCE] rounded text-black focus:border-[#4C494A] focus:outline-none focus:ring-0 font-normal text-base bg-white ${
              props?.disabled
                ? 'bg-[#F5F5F5] text-[#898F96] cursor-not-allowed'
                : 'bg-[#FFFFFF]'
            } ${classNameInput}`}
          />
        )}
        {props?.type === 'select' && showDropdown && (
          <Dropdown
            className={classNameWrapper}
            data={dataDropdown}
            filterText={props?.value}
            setValue={setValue}
          />
        )}
      </div>
      {subfix && (
        <button
          onFocus={handleFocus}
          onClick={handleClickSubfix}
          className={`absolute right-0 top-0 p-4 ${classNameSubfix}`}
        >
          {icon}
        </button>
      )}
    </div>
  );

  const _renderTopPosition = () => (
    <div
      className={`flex-col mt-6 allMobile:mt-4 pt-0 flex ${classNameWrapper}`}
    >
      <div className="flex items-center">
        {props?.label && (
          <label
            htmlFor={props?.name}
            className={`text-sm font-medium text-subtitle ${classNameLabel}`}
          >
            {props?.label}{' '}
            {required ? <span className="text-[#FF433F]">*</span> : ''}
          </label>
        )}
        {withInfo && (
          <div
            className="tooltip tooltip-right"
            data-tip={`1 Qoin = Rp ${tooltipTitle}`}
          >
            <InfoCircle
              size="20"
              color="#212529"
              className="ml-2 cursor-pointer"
              data-tip="hello"
            />
          </div>
        )}
      </div>
      <div className={`${classNameWrapperInput} relative mt-2`}>
        {prefix && (
          <button
            onClick={handleClickPrefix}
            className={`absolute left-0 top-0 p-4 ${classNamePrefix}`}
          >
            {icon}
          </button>
        )}
        <div>
          <input
            {...props}
            min={props?.min || 0}
            autoComplete="off"
            onChange={handleChange}
            className={`allMobile:w-full px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm outline-none focus:border-[#4C494A] focus:outline-none focus:ring-0 border-[1px] border-[#C8CBCE] w-full ${
              props?.disabled
                ? 'bg-[#F5F5F5] text-[#898F96] cursor-not-allowed'
                : 'bg-[#FFFFFF]'
            } ${classNameInput}`}
          />
          {props?.type === 'select' && showDropdown && (
            <Dropdown
              className={classNameWrapper}
              data={dataDropdown}
              filterText={props?.value}
              setValue={setValue}
            />
          )}
        </div>
        {subfix && (
          <button
            onFocus={handleFocus}
            onClick={handleClickSubfix}
            className={`absolute right-0 top-0 p-4 ${classNameSubfix}`}
          >
            {icon}
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`relative ${classNameWrapperType}`}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {labelPosition === 'left' ? _renderLeftPosition() : _renderTopPosition()}
    </div>
  );
};

export default Component;

import React, { useState } from 'react';
import Dropdown from '../Dropdown';
import { IButton } from './interface';

const Component: React.FC<IButton> = ({
  btnType,
  btnActType,
  children,
  className,
  classNameBtnWrapper,
  idx,
  icon,
  dataOptions,
  ...props
}) => {
  let styleBtn = '';

  switch (btnType) {
    case 'primary':
      styleBtn = 'bg-[#FFB100] text-[#212529]';
      break;
    case 'secondary':
      styleBtn = 'bg-[#EDEEEF] text-[#212529]';
      break;
    case 'tertiary':
      styleBtn = 'border-[#FFCB54] bg-[#FFE7B0] text-[#212529] border-[1px]';
      break;
    case 'quaternary':
      styleBtn = 'border-[#EDEEEF] bg-[#FCFCFC] text-[#727981] border-[1px]';
      break;
  }

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleFocus = () => setShowDropdown(true);

  const handleBlur = () => setTimeout(() => setShowDropdown(false), 300);

  const _renderDefaultButton = () => (
    <button
      {...props}
      className={`${
        props?.disabled
          ? 'bg-[#EDEEEF] text-[#727981] cursor-not-allowed'
          : styleBtn
      } ${className} w-full font-medium p-3 rounded`}
    >
      {children}
    </button>
  );

  const _renderActionButton = () => (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      <button
        {...props}
        key={idx}
        className={`${className} max-w-full cursor-pointer shadow-sm border-[1px] border-[#C8CBCE] p-4 flex justify-between allMobile:justify-center items-center h-12 rounded allMobile:w-full`}
      >
        <span>{icon}</span>
        <span className="text-sm ml-2">{children}</span>
      </button>

      {btnActType === 'dropdown' && showDropdown && (
        <Dropdown
          filterText={''}
          className="max-w-[180px]"
          data={dataOptions}
        />
      )}
    </div>
  );

  return (
    <div className={classNameBtnWrapper}>
      {props?.btnAct ? _renderActionButton() : _renderDefaultButton()}
    </div>
  );
};

export default Component;

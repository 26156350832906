import ReactDOM from "react-dom";
import App from "App";
import { Provider } from "react-redux";
import CacheBuster from "react-cache-buster";
import Package from "../package.json";
import { store } from "./stores";
import "assets/styles";

ReactDOM.render(
  <CacheBuster
    currentVersion={Package.version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <App />
    </Provider>
  </CacheBuster>,
  document.getElementById("root")
);

import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getAuthToken } from 'utils'

function PublicRoute({ children }) {
  const [isLogin, setIsLogin] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLogin(getAuthToken())
    setIsLoading(false)
  }, [])

  const renderingUI = () => {
    if (isLoading === true) {
      return <>Loading ...</>
    } else if (!isLogin) {
      return children
    } else {
      return <Navigate replace={true} to="/" />
    }
  }

  return <>{renderingUI()}</>
}

export default PublicRoute

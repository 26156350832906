const SVGComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.444 20H5.555C5.249 20 5 19.776 5 19.5V4.5C5 4.224 5.249 4 5.555 4H10V8.15C10 9.722 11.217 11 12.714 11H17V19.5C17 19.776 16.75 20 16.444 20ZM16.649 9H12.714C12.32 9 12 8.619 12 8.15V4H12.112L16.649 9ZM18.74 8.328L13.296 2.328C13.107 2.119 12.838 2 12.555 2H5.555C4.146 2 3 3.122 3 4.5V19.5C3 20.878 4.146 22 5.555 22H16.444C17.853 22 19 20.878 19 19.5V9C19 8.751 18.907 8.512 18.74 8.328Z"
      fill="#231F20"
    />
    <rect x={8} y={12} width={13} height={7} rx={1} fill="#D13636" />
    <path
      d="M10 17V14H11.391C11.6584 14 11.8862 14.0435 12.0744 14.1304C12.2626 14.2163 12.4061 14.3359 12.5048 14.4893C12.6046 14.6416 12.6546 14.8174 12.6546 15.0166C12.6546 15.2158 12.6041 15.3916 12.5031 15.5439C12.4021 15.6963 12.2557 15.8149 12.0641 15.8999C11.8736 15.9849 11.6429 16.0273 11.372 16.0273H10.4855V15.519H11.2515C11.395 15.519 11.5132 15.498 11.6062 15.4561C11.7003 15.4131 11.7703 15.354 11.8162 15.2788C11.8632 15.2026 11.8868 15.1152 11.8868 15.0166C11.8868 14.917 11.8632 14.8301 11.8162 14.7559C11.7703 14.6807 11.7003 14.6226 11.6062 14.5815C11.5121 14.5396 11.3927 14.5186 11.2481 14.5186H10.7454V17H10Z"
      fill="white"
    />
    <path
      d="M14.3885 17H13.1387V14H14.3989C14.7535 14 15.0588 14.0601 15.3147 14.1802C15.5706 14.2993 15.7675 14.4707 15.9052 14.6943C16.044 14.918 16.1135 15.1855 16.1135 15.4971C16.1135 15.8096 16.044 16.0781 15.9052 16.3027C15.7675 16.5273 15.5695 16.6997 15.3113 16.8198C15.0542 16.9399 14.7466 17 14.3885 17ZM13.8841 16.4565H14.3575C14.5779 16.4565 14.7632 16.4233 14.9136 16.3569C15.0651 16.2896 15.1787 16.1855 15.2544 16.0449C15.3313 15.9033 15.3698 15.7207 15.3698 15.4971C15.3698 15.2754 15.3313 15.0942 15.2544 14.9536C15.1787 14.813 15.0657 14.7095 14.9153 14.6431C14.765 14.5767 14.5796 14.5435 14.3593 14.5435H13.8841V16.4565Z"
      fill="white"
    />
    <path
      d="M16.6656 17V14H19V14.5229H17.4111V15.2378H18.8451V15.7607H17.4111V17H16.6656Z"
      fill="white"
    />
  </svg>
);

export default SVGComponent;

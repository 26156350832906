export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthToken = (token: string): void => {
  return localStorage.setItem("token", token);
};

export const removeAuthToken = (): void => {
  return localStorage.clear();
};

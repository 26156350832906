const SVGComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.444 20H5.555C5.249 20 5 19.776 5 19.5V4.5C5 4.224 5.249 4 5.555 4H10V8.15C10 9.722 11.217 11 12.714 11H17V19.5C17 19.776 16.75 20 16.444 20ZM16.649 9H12.714C12.32 9 12 8.619 12 8.15V4H12.112L16.649 9ZM18.74 8.328L13.296 2.328C13.107 2.119 12.838 2 12.555 2H5.555C4.146 2 3 3.122 3 4.5V19.5C3 20.878 4.146 22 5.555 22H16.444C17.853 22 19 20.878 19 19.5V9C19 8.751 18.907 8.512 18.74 8.328Z"
      fill="#231F20"
    />
    <rect x={7} y={12} width={15} height={7} rx={1} fill="#529732" />
    <path
      d="M9 16.9601V14.0399H10.7262V14.549H9.54163V15.2448H10.6374V15.7538H9.54163V16.451H10.7312V16.9601H9Z"
      fill="white"
    />
    <path
      d="M11.6647 14.0399L12.1813 15.0352H12.2013L12.7205 14.0399H13.3321L12.5503 15.5L13.3497 16.9601H12.7267L12.2013 15.9634H12.1813L11.6559 16.9601H11.0355L11.8373 15.5L11.0505 14.0399H11.6647Z"
      fill="white"
    />
    <path
      d="M15.8148 15.0623H15.267C15.2569 14.9815 15.2365 14.9097 15.2057 14.847C15.1748 14.7833 15.1352 14.7291 15.0868 14.6844C15.0385 14.6397 14.9826 14.6055 14.9192 14.5817C14.8567 14.558 14.7887 14.5461 14.7153 14.5461C14.5827 14.5461 14.4672 14.5837 14.3688 14.6587C14.2704 14.7329 14.1941 14.8413 14.1399 14.9838C14.0857 15.1255 14.0586 15.2975 14.0586 15.5C14.0586 15.7082 14.0857 15.8831 14.1399 16.0247C14.1949 16.1663 14.2717 16.2733 14.3701 16.3455C14.4685 16.4178 14.5823 16.4539 14.7116 16.4539C14.7841 16.4539 14.8512 16.443 14.913 16.4211C14.9755 16.3992 15.031 16.3674 15.0793 16.3256C15.1277 16.2828 15.1677 16.231 15.1994 16.1702C15.2319 16.1093 15.2544 16.0399 15.267 15.962L15.8148 15.9648C15.8007 16.0989 15.7652 16.2281 15.7085 16.3527C15.6526 16.4762 15.5772 16.587 15.4821 16.6849C15.3879 16.7818 15.2753 16.8588 15.1444 16.9159C15.0143 16.972 14.8671 17 14.7028 17C14.4743 17 14.27 16.9411 14.0899 16.8232C13.9106 16.7053 13.7688 16.5347 13.6646 16.3113C13.5612 16.0879 13.5095 15.8175 13.5095 15.5C13.5095 15.1816 13.562 14.9106 13.6671 14.6873C13.7721 14.4639 13.9147 14.2937 14.0949 14.1768C14.275 14.0589 14.4776 14 14.7028 14C14.8512 14 14.9888 14.0238 15.1156 14.0713C15.2432 14.1188 15.3562 14.1882 15.4546 14.2795C15.553 14.3698 15.633 14.4805 15.6948 14.6117C15.7573 14.7429 15.7973 14.8931 15.8148 15.0623Z"
      fill="white"
    />
    <path
      d="M16.2032 16.9601V14.0399H17.9295V14.549H16.7449V15.2448H17.8407V15.7538H16.7449V16.451H17.9345V16.9601H16.2032Z"
      fill="white"
    />
    <path
      d="M18.3601 16.9601V14.0399H18.9017V16.451H20V16.9601H18.3601Z"
      fill="white"
    />
  </svg>
);

export default SVGComponent;

import { lazy } from 'react';
import {
  IcActivity,
  IcEvent,
  IcHome,
  IcTopup,
  IcTransaction,
  IcUserManagement,
} from 'assets/svgs';

const Home = lazy(() => import('pages/Home'));
const Redirect = lazy(() => import('pages/Redirect.js'));
const Topup = lazy(() => import('pages/Topup'));
const HistoryTransaction = lazy(() => import('pages/HistoryTransaction'));
const MasterEvent = lazy(() => import('pages/MasterEvent'));
const FormEvent = lazy(() => import('pages/MasterEvent/FormEvent'));
const DetailHistoryTransaction = lazy(
  () => import('pages/HistoryTransaction/DetailHistoryTransaction')
);
const ActivityLog = lazy(() => import('pages/ActivityLog'));
const UserManagement = lazy(() => import('pages/UserManagement'));

const routing = [
  {
    key: 'dashboard',
    path: '/dashboard',
    Component: Home,
    title: 'Qoinpay Dashboard',
    subTitle: 'Selamat datang kembali',
    webTitle: 'Dashboard',
  },
  {
    key: 'Redirect',
    path: '/',
    Component: Redirect,
    title: 'Qoinpay Dashboard',
    subTitle: 'Selamat datang kembali',
    webTitle: 'Dashboard',
  },
  {
    key: 'topup-token',
    path: '/topup-token',
    Component: Topup,
    title: 'Topup Saldo Qoinpay',
    subTitle: '',
    webTitle: 'Topup',
  },
  {
    key: 'transaction-history',
    path: '/transaction-history',
    Component: HistoryTransaction,
    title: 'History Transaksi',
    subTitle: '',
    webTitle: 'History Transaksi',
  },
  {
    key: 'detail-transaction-history',
    path: '/transaction-history/:id',
    Component: DetailHistoryTransaction,
    title: 'Detail History Transaksi',
    subTitle: '',
    webTitle: 'Detail History Transkasi',
  },
  {
    key: 'event',
    path: '/event',
    Component: MasterEvent,
    title: 'Master Event',
    subTitle: '',
    webTitle: 'Master Event',
  },
  {
    key: 'create-event',
    path: '/event/create',
    Component: FormEvent,
    title: 'Master Event',
    subTitle: '',
    webTitle: 'Create Master Event',
  },
  {
    key: 'edit-event',
    path: '/event/edit/:id',
    Component: FormEvent,
    title: 'Master Event',
    subTitle: '',
    webTitle: 'Edit Master Event',
  },
  {
    key: 'detail-event',
    path: '/event/:id',
    Component: FormEvent,
    title: 'Master Event',
    subTitle: '',
    webTitle: 'Detail Master Event',
  },
  {
    key: 'user-management',
    path: '/user-management',
    Component: UserManagement,
    title: 'User Management',
    subTitle: '',
    webTitle: 'User Management',
  },
  {
    key: 'activity-log',
    path: '/activity-log',
    Component: ActivityLog,
    title: 'Activity Log',
    subTitle: '',
    webTitle: 'Activity Log',
  },
];

const menuRoute = [
  {
    name: 'Dashboard',
    icon: IcHome,
    path: '/dashboard',
  },
  {
    name: 'Topup',
    icon: IcTopup,
    path: '/topup-token',
  },
  {
    name: 'Transaction History',
    icon: IcTransaction,
    path: '/transaction-history',
  },
  {
    name: 'Master Event',
    icon: IcEvent,
    path: '/event',
  },
  {
    name: 'User Management',
    icon: IcUserManagement,
    path: '/user-management',
  },
  {
    name: 'Activity Log',
    icon: IcActivity,
    path: '/activity-log',
  },
];

export { routing, menuRoute };

import { AxiosError } from 'axios';
import { IWalletTicket } from './walletTypes';

// Cashier Types
export enum CashierType {
  CASHIER_LIST_PENDING = 'CASHIER_LIST_PENDING',
  CASHIER_LIST_SUCCESS = 'CASHIER_LIST_SUCCESS',
  CASHIER_LIST_ERROR = 'CASHIER_LIST_ERROR',
}

// IState Cashier
export interface ICashiertState {
  data: ICashierData[] & ICashierData & IWalletTicket;
  isLoading: boolean;
  error: AxiosError;
}

// IAction Cashier
interface GetCashierAction {
  type: CashierType.CASHIER_LIST_SUCCESS;
  payload: {
    data: ICashierResponse;
  };
}

interface SetLoadingCashierAction {
  type: CashierType.CASHIER_LIST_PENDING;
  payload?: any;
}

interface SetErrorCashierAction {
  type: CashierType.CASHIER_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

// IResponse Cashier
export interface ICashierResponse {
  requestId: string;
  status: string;
  code: number;
  message: string;
  data?: ICashierData[] & ICashierData & IWalletTicket;
}

export interface ICashierData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userName: string;
  phoneNumber: string;
}

export type CashierAction =
  | GetCashierAction
  | SetLoadingCashierAction
  | SetErrorCashierAction;

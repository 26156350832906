const SVGComponent = (props: any) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.83334 2.66714H3.16668C2.81305 2.66714 2.47392 2.80762 2.22387 3.05767C1.97382 3.30772 1.83334 3.64685 1.83334 4.00048V13.3338C1.83334 13.6874 1.97382 14.0266 2.22387 14.2766C2.47392 14.5267 2.81305 14.6671 3.16668 14.6671H12.5C12.8536 14.6671 13.1928 14.5267 13.4428 14.2766C13.6929 14.0266 13.8333 13.6874 13.8333 13.3338V8.66714M12.8333 1.66714C13.0986 1.40193 13.4583 1.25293 13.8333 1.25293C14.2084 1.25293 14.5681 1.40193 14.8333 1.66714C15.0986 1.93236 15.2476 2.29207 15.2476 2.66714C15.2476 3.04222 15.0986 3.40193 14.8333 3.66714L8.50001 10.0005L5.83334 10.6671L6.50001 8.00048L12.8333 1.66714Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SVGComponent

const SVGComponent = (props: any) => (
  <svg
    width={587}
    height={489}
    viewBox="0 0 587 489"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_66_1403)">
      <path
        d="M582.689 480.189H4.31133C1.93025 480.189 0 482.12 0 484.502V484.687C0 487.069 1.93025 489 4.31133 489H582.689C585.07 489 587 487.069 587 484.687V484.502C587 482.12 585.07 480.189 582.689 480.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M485.253 10.6777H256.974C255.402 10.6777 254.128 11.9522 254.128 13.5243V335.969C254.128 337.541 255.402 338.816 256.974 338.816H485.253C486.824 338.816 488.098 337.541 488.098 335.969V13.5243C488.098 11.9522 486.824 10.6777 485.253 10.6777Z"
        fill="white"
      />
      <path
        d="M337.553 109.618V338.816H256.974C255.403 338.816 254.128 337.54 254.128 335.969V54.0971H293.09L337.553 109.618Z"
        fill="#FFF7E6"
      />
      <path
        d="M272.279 133.111V147.455C272.279 148.28 272.95 148.952 273.776 148.952H287.486C288.311 148.952 288.982 148.28 288.982 147.455V133.111C288.982 128.502 285.238 124.756 280.631 124.756C276.024 124.756 272.279 128.502 272.279 133.111ZM279.879 126.297V133.986H273.776V133.111C273.776 129.581 276.455 126.672 279.879 126.297ZM287.48 133.111V133.986H281.376V126.297C284.806 126.672 287.48 129.581 287.48 133.111ZM287.48 147.455H281.376V135.483H287.48V147.455ZM273.769 147.455V135.483H279.879V147.455H273.769Z"
        fill="#FFE7B0"
      />
      <path
        d="M302.699 133.111V147.455C302.699 148.28 303.37 148.952 304.195 148.952H317.905C318.73 148.952 319.402 148.28 319.402 147.455V133.111C319.402 128.502 315.657 124.756 311.05 124.756C306.443 124.756 302.699 128.502 302.699 133.111ZM310.299 126.297V133.986H304.195V133.111C304.195 129.581 306.874 126.672 310.299 126.297ZM317.899 133.111V133.986H311.795V126.297C315.226 126.672 317.899 129.581 317.899 133.111ZM317.899 147.455H311.795V135.483H317.899V147.455ZM304.189 147.455V135.483H310.299V147.455H304.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M272.279 174.319V188.662C272.279 189.488 272.95 190.159 273.776 190.159H287.486C288.311 190.159 288.982 189.488 288.982 188.662V174.319C288.982 169.71 285.238 165.964 280.631 165.964C276.024 165.964 272.279 169.71 272.279 174.319ZM279.879 167.504V175.193H273.776V174.319C273.776 170.788 276.455 167.88 279.879 167.504ZM287.48 174.319V175.193H281.376V167.504C284.806 167.88 287.48 170.788 287.48 174.319ZM287.48 188.662H281.376V176.691H287.48V188.662ZM273.769 188.662V176.691H279.879V188.662H273.769Z"
        fill="#FFE7B0"
      />
      <path
        d="M302.699 174.319V188.662C302.699 189.488 303.37 190.159 304.195 190.159H317.905C318.73 190.159 319.402 189.488 319.402 188.662V174.319C319.402 169.71 315.657 165.964 311.05 165.964C306.443 165.964 302.699 169.71 302.699 174.319ZM310.299 167.504V175.193H304.195V174.319C304.195 170.788 306.874 167.88 310.299 167.504ZM317.899 174.319V175.193H311.795V167.504C315.226 167.88 317.899 170.788 317.899 174.319ZM317.899 188.662H311.795V176.691H317.899V188.662ZM304.189 188.662V176.691H310.299V188.662H304.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M272.279 215.526V229.87C272.279 230.695 272.95 231.367 273.776 231.367H287.486C288.311 231.367 288.982 230.695 288.982 229.87V215.526C288.982 210.917 285.238 207.171 280.631 207.171C276.024 207.171 272.279 210.917 272.279 215.526ZM279.879 208.711V216.401H273.776V215.526C273.776 211.995 276.455 209.087 279.879 208.711ZM287.48 215.526V216.401H281.376V208.711C284.806 209.087 287.48 211.995 287.48 215.526ZM287.48 229.87H281.376V217.898H287.48V229.87ZM273.769 229.87V217.898H279.879V229.87H273.769Z"
        fill="#FFE7B0"
      />
      <path
        d="M302.699 215.526V229.87C302.699 230.695 303.37 231.367 304.195 231.367H317.905C318.73 231.367 319.402 230.695 319.402 229.87V215.526C319.402 210.917 315.657 207.171 311.05 207.171C306.443 207.171 302.699 210.917 302.699 215.526ZM310.299 208.711V216.401H304.195V215.526C304.195 211.995 306.874 209.087 310.299 208.711ZM317.899 215.526V216.401H311.795V208.711C315.226 209.087 317.899 211.995 317.899 215.526ZM317.899 229.87H311.795V217.898H317.899V229.87ZM304.189 229.87V217.898H310.299V229.87H304.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M272.279 256.733V271.077C272.279 271.903 272.95 272.574 273.776 272.574H287.486C288.311 272.574 288.982 271.903 288.982 271.077V256.733C288.982 252.125 285.238 248.379 280.631 248.379C276.024 248.379 272.279 252.125 272.279 256.733ZM279.879 249.919V257.608H273.776V256.733C273.776 253.203 276.455 250.295 279.879 249.919ZM287.48 256.733V257.608H281.376V249.919C284.806 250.295 287.48 253.203 287.48 256.733ZM287.48 271.077H281.376V259.106H287.48V271.077ZM273.769 271.077V259.106H279.879V271.077H273.769Z"
        fill="#FFE7B0"
      />
      <path
        d="M302.699 256.733V271.077C302.699 271.903 303.37 272.574 304.195 272.574H317.905C318.73 272.574 319.402 271.903 319.402 271.077V256.733C319.402 252.125 315.657 248.379 311.05 248.379C306.443 248.379 302.699 252.125 302.699 256.733ZM310.299 249.919V257.608H304.195V256.733C304.195 253.203 306.874 250.295 310.299 249.919ZM317.899 256.733V257.608H311.795V249.919C315.226 250.295 317.899 253.203 317.899 256.733ZM317.899 271.077H311.795V259.106H317.899V271.077ZM304.189 271.077V259.106H310.299V271.077H304.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M272.279 297.941V312.285C272.279 313.11 272.95 313.782 273.776 313.782H287.486C288.311 313.782 288.982 313.11 288.982 312.285V297.941C288.982 293.332 285.238 289.586 280.631 289.586C276.024 289.586 272.279 293.332 272.279 297.941ZM279.879 291.126V298.816H273.776V297.941C273.776 294.41 276.455 291.502 279.879 291.126ZM287.48 297.941V298.816H281.376V291.126C284.806 291.502 287.48 294.41 287.48 297.941ZM287.48 312.285H281.376V300.313H287.48V312.285ZM273.769 312.285V300.313H279.879V312.285H273.769Z"
        fill="#FFE7B0"
      />
      <path
        d="M302.699 297.941V312.285C302.699 313.11 303.37 313.782 304.195 313.782H317.905C318.73 313.782 319.402 313.11 319.402 312.285V297.941C319.402 293.332 315.657 289.586 311.05 289.586C306.443 289.586 302.699 293.332 302.699 297.941ZM310.299 291.126V298.816H304.195V297.941C304.195 294.41 306.874 291.502 310.299 291.126ZM317.899 297.941V298.816H311.795V291.126C315.226 291.502 317.899 294.41 317.899 297.941ZM317.899 312.285H311.795V300.313H317.899V312.285ZM304.189 312.285V300.313H310.299V312.285H304.189Z"
        fill="#FFE7B0"
      />
      <path
        d="M494.146 0H248.08C246.51 0 245.235 1.27541 245.235 2.84657V346.641C245.235 348.212 246.51 349.487 248.08 349.487H494.146C495.717 349.487 496.992 348.212 496.992 346.641V2.84657C496.992 1.27541 495.717 0 494.146 0ZM254.128 13.5243C254.128 11.9531 255.403 10.6777 256.974 10.6777H365.952V82.569H254.128V13.5243ZM254.128 90.32H365.952V221.995H254.128V90.32ZM254.128 335.963V229.753H365.952V338.816H256.974C255.403 338.816 254.128 337.54 254.128 335.969V335.963ZM488.098 335.963C488.098 337.534 486.823 338.81 485.253 338.81H373.7V229.747H488.098V335.957V335.963ZM488.098 221.995H373.7V90.32H488.098V221.995ZM488.098 82.5628H373.7V10.6777H485.253C486.823 10.6777 488.098 11.9531 488.098 13.5243V82.569V82.5628Z"
        fill="#FFE7B0"
      />
      <path
        d="M488.098 109.618V152.68H387.422L421.697 109.618H488.098Z"
        fill="#FFF7E6"
      />
      <path
        d="M488.098 152.68V161.219H390.262C388.691 161.219 387.416 159.944 387.416 158.373V152.68H488.098Z"
        fill="#FFE7B0"
      />
      <path
        d="M488.104 161.219H398.096V213.178H488.104V161.219Z"
        fill="#FFF7E6"
      />
      <path
        d="M419.536 182.316V196.66C419.536 197.485 420.207 198.157 421.032 198.157H434.742C435.568 198.157 436.239 197.485 436.239 196.66V182.316C436.239 177.707 432.494 173.961 427.887 173.961C423.28 173.961 419.536 177.707 419.536 182.316ZM427.136 175.501V183.191H421.032V182.316C421.032 178.786 423.711 175.877 427.136 175.501ZM434.736 182.316V183.191H428.633V175.501C432.063 175.877 434.736 178.786 434.736 182.316ZM434.736 196.66H428.633V184.688H434.736V196.66ZM421.026 196.66V184.688H427.136V196.66H421.026Z"
        fill="#FFE7B0"
      />
      <path
        d="M449.955 182.316V196.66C449.955 197.485 450.626 198.157 451.452 198.157H465.162C465.987 198.157 466.658 197.485 466.658 196.66V182.316C466.658 177.707 462.914 173.961 458.307 173.961C453.7 173.961 449.955 177.707 449.955 182.316ZM457.555 175.501V183.191H451.452V182.316C451.452 178.786 454.131 175.877 457.555 175.501ZM465.156 182.316V183.191H459.052V175.501C462.483 175.877 465.156 178.786 465.156 182.316ZM465.156 196.66H459.052V184.688H465.156V196.66ZM451.446 196.66V184.688H457.555V196.66H451.446Z"
        fill="#FFE7B0"
      />
      <path
        d="M342.307 353.48C338.236 353.418 335.107 351.909 333.013 349.007C329.133 343.634 330.063 334.725 331.079 331.484C332.545 326.826 342.862 328.68 344.913 329.099L344.395 331.638C339.659 330.677 334.042 330.695 333.549 332.26C332.785 334.688 331.738 342.808 335.114 347.485C336.727 349.721 339.092 350.83 342.344 350.88L342.301 353.474L342.307 353.48Z"
        fill="#F47F56"
      />
      <path
        d="M345.085 323.72H372.604C374.304 323.72 375.683 325.1 375.683 326.801V351.915C375.683 356.333 372.092 359.925 367.676 359.925H350.012C345.596 359.925 342.006 356.333 342.006 351.915V326.801C342.006 325.1 343.385 323.72 345.085 323.72Z"
        fill="#FBAE61"
      />
      <path
        d="M568.049 266.937H543.117C542.096 266.937 541.269 267.764 541.269 268.785V272.568C541.269 273.589 542.096 274.417 543.117 274.417H568.049C569.069 274.417 569.896 273.589 569.896 272.568V268.785C569.896 267.764 569.069 266.937 568.049 266.937Z"
        fill="#FFE7B0"
      />
      <path
        d="M552.269 105.878H527.337C526.317 105.878 525.49 106.705 525.49 107.726V111.509C525.49 112.53 526.317 113.358 527.337 113.358H552.269C553.29 113.358 554.117 112.53 554.117 111.509V107.726C554.117 106.705 553.29 105.878 552.269 105.878Z"
        fill="#FFE7B0"
      />
      <path
        d="M559.056 50.3572H532.018C530.998 50.3572 530.17 51.1847 530.17 52.2056V55.9887C530.17 57.0096 530.998 57.8371 532.018 57.8371H559.056C560.077 57.8371 560.904 57.0096 560.904 55.9887V52.2056C560.904 51.1847 560.077 50.3572 559.056 50.3572Z"
        fill="#FFF7E6"
      />
      <path
        d="M543.69 113.234H516.651C515.631 113.234 514.804 114.062 514.804 115.083V118.866C514.804 119.887 515.631 120.714 516.651 120.714H543.69C544.71 120.714 545.537 119.887 545.537 118.866V115.083C545.537 114.062 544.71 113.234 543.69 113.234Z"
        fill="#FFF7E6"
      />
      <path
        d="M132.652 77.7607C137.145 73.2659 137.145 65.9785 132.652 61.4838C128.159 56.989 120.874 56.989 116.381 61.4838C111.888 65.9785 111.888 73.2659 116.381 77.7607C120.874 82.2554 128.159 82.2554 132.652 77.7607Z"
        fill="#FEEB9B"
      />
      <path
        d="M136.632 5.47133H112.39C111.03 5.47133 109.927 6.57475 109.927 7.93589V9.41463C109.927 10.7758 111.03 11.8792 112.39 11.8792H136.632C137.993 11.8792 139.096 10.7758 139.096 9.41463V7.93589C139.096 6.57475 137.993 5.47133 136.632 5.47133Z"
        fill="#FEEB9B"
      />
      <path
        d="M125.761 11.8792H122.559V43.0189H125.761V11.8792Z"
        fill="#F47F56"
      />
      <path
        d="M175.742 67.9295C175.742 67.9295 149.314 74.9781 124.511 75.0459H124.154C99.7395 74.9781 73.7545 68.1451 72.9415 67.9295C74.2103 68.2252 78.9959 64.7009 80.0675 64.1279C81.5519 63.3393 83.0362 62.5506 84.5205 61.7619C85.3274 61.3368 86.128 60.9117 86.9226 60.4865C90.0144 58.8476 93.1062 57.2025 96.1981 55.5636C102.831 52.0454 109.458 48.5211 116.086 44.9967C118.167 43.8877 120.237 42.6184 122.386 41.6511C126.408 39.8458 130.387 43.6967 133.811 45.5451C137.556 47.566 141.27 49.624 145.027 51.6203C150.613 54.5901 156.199 57.5537 161.786 60.5235C162.586 60.9486 163.387 61.3738 164.188 61.7989C168.031 63.8445 171.88 65.8901 175.73 67.9295H175.742Z"
        fill="#FBAE61"
      />
      <path
        d="M164.206 61.7989C155.436 64.1587 142.656 66.1612 124.517 66.1612C106.379 66.1612 93.3895 64.1402 84.5328 61.7681C85.3397 61.343 86.1403 60.9178 86.9349 60.4927C95.5883 62.6307 107.789 64.3805 124.517 64.3805C141.245 64.3805 153.249 62.643 161.804 60.5235C162.605 60.9486 163.406 61.3738 164.206 61.7989Z"
        fill="#F47F56"
      />
      <path
        d="M86.4853 102.255H47.554C46.5335 102.255 45.7062 103.082 45.7062 104.103V107.886C45.7062 108.907 46.5335 109.735 47.554 109.735H86.4853C87.5057 109.735 88.333 108.907 88.333 107.886V104.103C88.333 103.082 87.5057 102.255 86.4853 102.255Z"
        fill="#FFE7B0"
      />
      <path
        d="M98.3414 109.494H71.3032C70.2828 109.494 69.4555 110.322 69.4555 111.343V115.126C69.4555 116.147 70.2828 116.974 71.3032 116.974H98.3414C99.3619 116.974 100.189 116.147 100.189 115.126V111.343C100.189 110.322 99.3619 109.494 98.3414 109.494Z"
        fill="#FFF7E6"
      />
      <path
        d="M216.115 133.111H189.076C188.056 133.111 187.229 133.939 187.229 134.959V138.743C187.229 139.763 188.056 140.591 189.076 140.591H216.115C217.135 140.591 217.962 139.763 217.962 138.743V134.959C217.962 133.939 217.135 133.111 216.115 133.111Z"
        fill="#FFE7B0"
      />
      <path
        d="M209.851 68.5518H192.07C191.049 68.5518 190.222 69.3794 190.222 70.4002V71.4045C190.222 72.4254 191.049 73.253 192.07 73.253H209.851C210.871 73.253 211.699 72.4254 211.699 71.4045V70.4002C211.699 69.3794 210.871 68.5518 209.851 68.5518Z"
        fill="#FFF7E6"
      />
      <path
        d="M222.378 73.3947H204.597C203.577 73.3947 202.75 74.2222 202.75 75.2431V76.2474C202.75 77.2683 203.577 78.0958 204.597 78.0958H222.378C223.399 78.0958 224.226 77.2683 224.226 76.2474V75.2431C224.226 74.2222 223.399 73.3947 222.378 73.3947Z"
        fill="#FFE7B0"
      />
      <path
        d="M223.703 196.352H205.921C204.901 196.352 204.074 197.179 204.074 198.2V199.204C204.074 200.225 204.901 201.053 205.921 201.053H223.703C224.723 201.053 225.55 200.225 225.55 199.204V198.2C225.55 197.179 224.723 196.352 223.703 196.352Z"
        fill="#FFF7E6"
      />
      <path
        d="M212.967 201.053H195.186C194.166 201.053 193.339 201.88 193.339 202.901V203.906C193.339 204.926 194.166 205.754 195.186 205.754H212.967C213.988 205.754 214.815 204.926 214.815 203.906V202.901C214.815 201.88 213.988 201.053 212.967 201.053Z"
        fill="#FFE7B0"
      />
      <path
        d="M229.274 260.719C229.274 252.919 223.481 246.33 215.74 245.368C203.148 243.801 181.106 241.946 147.246 241.946C113.385 241.946 90.7683 243.826 78.0083 245.4C70.255 246.355 64.4493 252.944 64.4431 260.756C64.4368 274.29 64.88 296.292 67.271 317.65C69.8055 340.227 74.8808 360.199 78.0395 371.19C79.7313 377.061 84.7004 381.401 90.7496 382.232C99.8763 383.481 116.775 384.83 147.239 384.83C177.703 384.83 193.866 383.525 202.886 382.288C208.96 381.457 213.961 377.13 215.652 371.24C218.818 360.261 223.899 340.264 226.44 317.65C228.837 296.273 229.28 274.252 229.268 260.719H229.274Z"
        fill="#FFB100"
      />
      <path
        d="M254.128 369.66L265.653 386.607C266.911 388.458 269.431 388.937 271.281 387.678C273.131 386.419 273.611 383.898 272.352 382.047L260.827 365.1C259.568 363.25 257.048 362.77 255.198 364.029C253.348 365.288 252.869 367.81 254.128 369.66Z"
        fill="#E8A100"
      />
      <path
        d="M270.026 390.65L255.243 407.862C254.426 408.811 253.502 409.661 252.49 410.385C251.217 411.297 249.806 412.009 248.295 412.465C247.178 412.796 246.048 413.027 244.912 413.152C240.492 413.639 236.004 412.565 232.277 410.104C231.671 409.704 231.091 409.267 230.529 408.793C229.23 407.706 228.063 406.426 227.07 404.958C227.002 404.864 226.939 404.783 226.896 404.715L222.276 397.739C217.525 390.544 218.274 381.333 223.506 375.025C225.329 372.82 227.807 371.253 230.535 370.403L246.797 365.32H246.81L252.184 363.634C253.477 363.234 254.85 363.546 255.83 364.402C256.086 364.62 256.311 364.876 256.504 365.17L270.313 386.06C271.256 387.478 271.137 389.358 270.026 390.65Z"
        fill="#FFCB54"
      />
      <path
        d="M296.57 341.688C293.692 339.933 290.002 340.227 287.387 342.35C281.675 346.996 279.608 353.679 276.574 352.367C273.522 351.056 283.335 345.941 283.985 341.207C284.184 339.764 282.886 338.578 281.456 338.834C273.441 340.258 266.505 346.147 264.233 354.684C261.442 365.17 266.98 376.293 277.03 380.371C288.829 385.155 302.007 378.897 305.89 366.994C309.086 357.22 304.979 346.815 296.563 341.682L296.57 341.688Z"
        fill="#212529"
      />
      <path
        d="M252.49 410.385C251.217 411.297 249.806 412.009 248.295 412.465C247.178 412.796 246.048 413.027 244.912 413.152C240.492 413.639 236.004 412.565 232.277 410.104C231.765 409.592 231.091 409.267 230.529 408.793C228.893 407.075 227.62 405.676 227.07 404.958C227.002 404.864 226.939 404.783 226.896 404.715L222.276 397.739C217.525 390.544 218.274 381.333 223.506 375.025C225.329 372.82 227.807 371.253 230.535 370.403L246.797 365.32H246.81L252.184 363.634C253.477 363.234 254.85 363.546 255.83 364.402C252.553 373.632 241.591 391.131 252.49 410.385Z"
        fill="#E8A100"
      />
      <path
        d="M77.0595 454.614C75.1929 456.537 73.0891 458.005 70.8605 459.054C69.4122 459.741 67.6518 459.035 67.1711 457.511C67.1711 457.505 67.1586 457.505 67.1649 457.499C66.7029 456.031 67.015 454.301 67.7766 452.952C69.4996 449.88 70.024 442.229 65.548 442.735C61.072 443.254 58.0443 447.863 62.0209 453.777C63.1259 455.426 63.1883 456.862 62.6951 458.08C62.383 458.873 61.8336 459.579 61.1906 460.178C60.5914 460.734 59.7798 460.996 58.9683 460.89C53.0377 460.091 47.4568 456.812 43.911 451.304C38.1053 442.267 40.053 430.12 48.3932 423.356C58.4439 415.2 73.37 417.786 80.0622 428.996C84.9377 437.171 83.7016 447.781 77.0657 454.607L77.0595 454.614Z"
        fill="#212529"
      />
      <path
        d="M86.631 417.975L68.6357 408.176C66.6704 407.106 64.21 407.832 63.1403 409.798C62.0706 411.764 62.7965 414.226 64.7618 415.296L82.7571 425.095C84.7224 426.165 87.1828 425.439 88.2525 423.473C89.3223 421.507 88.5963 419.045 86.631 417.975Z"
        fill="#E8A100"
      />
      <path
        d="M111.407 399.206C110.789 401.998 109.309 404.521 107.274 406.526L91.6111 421.945C91.4425 422.114 91.274 422.282 91.1054 422.445C89.9006 423.644 88.0465 423.887 86.567 423.057L64.7365 410.804C63.2445 409.961 62.4954 408.243 62.8949 406.588L68.1762 384.518C68.8379 381.763 70.2175 379.209 72.2589 377.248C78.1582 371.553 87.3162 370.122 94.8323 374.344L102.124 378.447C103.085 378.984 103.978 379.59 104.802 380.258C110.458 384.83 112.961 392.224 111.413 399.206H111.407Z"
        fill="#FFCB54"
      />
      <path
        d="M111.407 399.206C110.789 401.998 109.309 404.521 107.274 406.526L91.6111 421.945C118.548 396.165 102.124 378.447 102.124 378.447L104.802 380.258C110.458 384.83 112.961 392.224 111.413 399.206H111.407Z"
        fill="#E8A100"
      />
      <path
        d="M200.196 436.115H132.994C120.309 436.115 111.076 424.081 114.353 411.822L119.26 393.486C119.99 390.757 122.462 388.858 125.29 388.858H207.905C210.733 388.858 213.205 390.757 213.936 393.486L218.842 411.822C222.126 424.081 212.893 436.115 200.202 436.115H200.196Z"
        fill="#FFCB54"
      />
      <path
        d="M135.672 437.783C144.518 437.783 151.703 444.971 151.703 453.821V469.858H119.641V453.821C119.641 444.971 126.826 437.783 135.672 437.783Z"
        fill="#FFCB54"
      />
      <path
        d="M113.685 471.363V467.953C113.685 459.429 120.602 452.515 129.117 452.515H141.009C149.025 452.515 155.536 459.029 155.536 467.048V470.058C155.536 478.077 149.025 484.591 141.009 484.591H126.901C119.603 484.591 113.685 478.664 113.685 471.37V471.363Z"
        fill="#FFCB54"
      />
      <path
        d="M119.797 454.951C119.797 454.951 133.505 441.942 147.489 454.726C147.489 454.726 134.904 448.194 119.797 454.951Z"
        fill="#E8A100"
      />
      <path
        d="M211.052 469.852H178.99V453.814C178.99 444.965 186.175 437.777 195.021 437.777C203.866 437.777 211.052 444.965 211.052 453.814V469.852Z"
        fill="#FFCB54"
      />
      <path
        d="M178.99 476.06V452.515H204.809C213.655 452.515 220.84 459.704 220.84 468.553C220.84 477.402 213.655 484.591 204.809 484.591H187.511C182.81 484.591 178.99 480.769 178.99 476.066V476.06Z"
        fill="#FFCB54"
      />
      <path
        d="M211.052 453.821C211.052 453.821 196.319 441.986 183.434 455.875C183.434 455.875 195.439 448.331 211.052 453.821Z"
        fill="#E8A100"
      />
      <path
        d="M217.919 410.872H115.539C113.505 410.872 111.856 412.522 111.856 414.557V419.16C111.856 421.195 113.505 422.844 115.539 422.844H217.919C219.953 422.844 221.602 421.195 221.602 419.16V414.557C221.602 412.522 219.953 410.872 217.919 410.872Z"
        fill="#FFB100"
      />
      <path
        d="M165.649 228.794C165.53 221.849 159.874 216.253 152.901 216.253C145.928 216.253 140.273 221.849 140.154 228.794H140.141V259.045H165.718V228.794H165.643H165.649Z"
        fill="#FFB100"
      />
      <path
        d="M220.054 228.794C219.935 221.849 214.279 216.253 207.306 216.253C200.333 216.253 194.677 221.849 194.559 228.794H194.546V259.045H220.122V228.794H220.047H220.054Z"
        fill="#FFB100"
      />
      <path
        d="M164.438 255.692H141.471V228.781H141.515C141.964 222.886 146.952 218.189 152.933 218.189C158.913 218.189 163.895 222.879 164.35 228.781H164.444V255.692H164.438ZM142.845 254.318H163.065V230.049L163.002 229.481C162.908 224.016 158.389 219.563 152.926 219.563C147.464 219.563 142.944 224.01 142.851 229.481L142.838 230.024V254.324L142.845 254.318Z"
        fill="#E8A100"
      />
      <path
        d="M218.849 255.692H195.882V228.781H195.926C196.375 222.886 201.363 218.189 207.344 218.189C213.324 218.189 218.306 222.879 218.761 228.781H218.855V255.692H218.849ZM197.256 254.318H217.482V230.049L217.419 229.481C217.326 224.016 212.806 219.563 207.344 219.563C201.881 219.563 197.362 224.01 197.268 229.481L197.256 230.024V254.324V254.318Z"
        fill="#E8A100"
      />
      <path
        d="M263.04 260.719C263.04 252.919 257.247 246.33 249.506 245.368C236.915 243.801 214.872 241.946 181.012 241.946C147.152 241.946 124.535 243.826 111.775 245.4C104.022 246.355 98.2158 252.944 98.2096 260.756C98.2034 274.29 98.6466 296.292 101.038 317.65C103.572 340.227 108.647 360.199 111.806 371.19C113.498 377.061 118.467 381.401 124.516 382.232C133.643 383.481 150.542 384.83 181.006 384.83C211.47 384.83 227.632 383.525 236.653 382.288C242.727 381.457 247.727 377.13 249.419 371.24C252.584 360.261 257.666 340.264 260.206 317.65C262.603 296.273 263.047 274.252 263.034 260.719H263.04Z"
        fill="#FFCB54"
      />
      <path
        d="M242.49 273.853C242.496 284.014 242.165 300.545 240.367 316.589C238.463 333.563 234.643 348.576 232.27 356.82C230.997 361.242 227.245 364.489 222.688 365.108C216.414 365.969 205.215 366.875 185.657 367H185.638C184.115 367.012 182.541 367.019 180.918 367.019C158.045 367.019 145.36 366.007 138.512 365.064C133.967 364.446 130.234 361.186 128.967 356.783C126.595 348.533 122.781 333.538 120.883 316.589C119.085 300.557 118.754 284.039 118.754 273.884C118.76 268.013 123.118 263.073 128.942 262.355C138.518 261.169 155.324 259.757 180.918 259.757C206.513 259.757 222.881 261.15 232.333 262.33C238.138 263.055 242.49 267.995 242.49 273.853Z"
        fill="#2B3036"
      />
      <path
        d="M251.666 363.053L243.794 392.999C243.201 395.259 240.423 396.09 238.688 394.529L226.302 383.387L251.666 363.059V363.053Z"
        fill="#FFCB54"
      />
      <path
        d="M242.49 273.853C242.496 284.014 242.165 300.545 240.367 316.589C238.463 333.563 234.643 348.576 232.27 356.82C230.997 361.242 227.245 364.489 222.688 365.108C216.414 365.969 205.215 366.875 185.657 367C188.435 366.694 224.142 364.633 227.719 351.693C231.44 338.234 241.316 284.532 234.73 273.078C227.095 259.807 126.458 260.65 118.754 273.884C118.76 268.013 123.118 263.073 128.942 262.355C138.518 261.169 155.324 259.757 180.918 259.757C206.513 259.757 222.881 261.15 232.333 262.33C238.138 263.055 242.49 267.995 242.49 273.853Z"
        fill="#485059"
      />
      <path
        d="M166.167 315.895C166.167 315.895 166.167 315.939 166.167 315.958C166.167 324.12 172.778 330.734 180.937 330.734C189.096 330.734 195.707 324.12 195.707 315.958C195.707 315.939 195.707 315.914 195.707 315.895H166.167Z"
        fill="white"
      />
      <path
        d="M155.81 310.487C158.851 310.487 161.316 308.021 161.316 304.979C161.316 301.937 158.851 299.47 155.81 299.47C152.77 299.47 150.304 301.937 150.304 304.979C150.304 308.021 152.77 310.487 155.81 310.487Z"
        fill="white"
      />
      <path
        d="M205.502 310.487C208.543 310.487 211.008 308.021 211.008 304.979C211.008 301.937 208.543 299.47 205.502 299.47C202.461 299.47 199.996 301.937 199.996 304.979C199.996 308.021 202.461 310.487 205.502 310.487Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M483.518 375.445H472.116C470.275 375.445 468.781 376.937 468.781 378.778V383.781C464.881 384.661 461.215 386.162 457.872 388.139L457.866 388.143L454.196 384.476C452.893 383.174 450.782 383.174 449.479 384.476L441.417 392.533C440.115 393.835 440.115 395.945 441.417 397.247L444.956 400.783C442.875 404.074 441.318 407.695 440.333 411.566L440.331 411.575H435.133C433.291 411.575 431.798 413.067 431.798 414.907V426.302C431.798 428.143 433.291 429.635 435.133 429.635H440.141C441.013 433.53 442.515 437.191 444.5 440.53L444.501 440.532L440.826 444.205C439.524 445.507 439.524 447.617 440.826 448.919L448.889 456.976C450.191 458.278 452.303 458.278 453.605 456.976L457.135 453.448L457.137 453.45C460.432 455.516 464.058 457.09 467.934 458.074L467.95 458.078V463.263C467.95 465.104 469.443 466.596 471.285 466.596H482.687C484.528 466.596 486.022 465.104 486.022 463.263V458.258C489.919 457.378 493.582 455.886 496.907 453.902L496.915 453.897L500.6 457.58C501.903 458.881 504.014 458.881 505.317 457.58L513.379 449.522C514.682 448.221 514.682 446.11 513.379 444.809L509.834 441.266L509.835 441.264C511.918 437.972 513.493 434.348 514.478 430.475L519.67 430.475C521.512 430.475 523.005 428.982 523.005 427.142V415.747C523.005 413.906 521.512 412.414 519.67 412.414H514.672C513.792 408.516 512.29 404.852 510.295 401.511C510.295 401.51 510.294 401.51 510.294 401.509L513.976 397.83C515.278 396.528 515.278 394.418 513.976 393.116L505.913 385.059C504.611 383.757 502.499 383.757 501.197 385.059L497.66 388.593C497.66 388.592 497.659 388.592 497.658 388.591C494.363 386.525 490.721 384.952 486.853 383.967L486.853 383.967V378.778C486.853 376.937 485.36 375.445 483.518 375.445ZM456.176 421.025C456.176 409.307 465.681 399.816 477.398 399.816C489.114 399.816 498.635 409.307 498.635 421.025C498.635 432.742 489.131 442.233 477.398 442.233C465.664 442.233 456.176 432.742 456.176 421.025Z"
        fill="#FFE7B0"
      />
      <path
        d="M462.362 484.285H319.369C314.742 484.285 311.512 480.541 312.15 475.917L347.163 221.614C347.801 216.99 352.065 213.245 356.692 213.245H499.685C504.312 213.245 507.542 216.99 506.904 221.614L471.89 475.917C471.252 480.541 466.989 484.285 462.362 484.285Z"
        fill="#E8A100"
      />
      <path
        d="M456.887 480.67H313.894C309.267 480.67 306.037 476.926 306.675 472.301L341.688 217.999C342.326 213.374 346.59 209.63 351.217 209.63H494.21C498.837 209.63 502.067 213.374 501.429 217.999L466.415 472.301C465.777 476.926 461.514 480.67 456.887 480.67Z"
        fill="white"
      />
      <path
        d="M495.599 220.904L461.579 467.919C461.159 470.954 458.365 473.407 455.328 473.407H317.738C314.693 473.407 312.578 470.946 312.998 467.919L319.853 418.152L336.658 296.084L347.01 220.904C347.43 217.87 350.224 215.416 353.268 215.416H388.782C391.156 215.416 392.811 217.337 392.48 219.701C392.327 220.888 392.658 221.961 393.328 222.736C393.99 223.51 395 223.987 396.187 223.987H445.574C447.948 223.987 450.128 222.058 450.459 219.701C450.629 218.515 451.251 217.442 452.131 216.667C453.011 215.892 454.158 215.408 455.336 215.408H490.851C493.895 215.408 496.01 217.87 495.591 220.896L495.599 220.904Z"
        fill="#FFE7B0"
      />
      <path
        d="M431.144 218.451H404.116C403.624 218.451 403.22 218.047 403.22 217.555C403.22 217.062 403.624 216.659 404.116 216.659H431.144C431.636 216.659 432.04 217.062 432.04 217.555C432.04 218.047 431.636 218.451 431.144 218.451Z"
        fill="#E8A100"
      />
      <path
        d="M439.018 218.356C439.48 217.925 439.55 217.247 439.172 216.843C438.795 216.439 438.114 216.462 437.651 216.893C437.188 217.325 437.119 218.003 437.496 218.407C437.873 218.811 438.555 218.788 439.018 218.356Z"
        fill="#E8A100"
      />
      <path
        d="M473.691 273.157H354.528L357.418 252.159H476.582L473.691 273.157Z"
        fill="#E8A100"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.5}
      >
        <path
          d="M477.834 243.096H358.662L359.647 235.954H478.811L477.834 243.096Z"
          fill="#8391FC"
        />
      </g>
      <path
        d="M447.94 460.156H328.776L331.667 439.158H450.831L447.94 460.156Z"
        fill="#FFB100"
      />
      <path
        d="M369.563 447.212H367.617L367.714 446.51H372.454L372.357 447.212H370.403L369.62 452.909H368.78L369.563 447.212Z"
        fill="#31374C"
      />
      <path
        d="M379.851 449.649C379.544 451.852 378.05 453.022 376.411 453.022C374.772 453.022 373.714 451.715 373.98 449.778C374.263 447.744 375.708 446.421 377.42 446.421C379.132 446.421 380.117 447.761 379.851 449.657V449.649ZM374.869 449.754C374.683 451.118 375.256 452.344 376.556 452.344C377.856 452.344 378.777 451.142 378.971 449.689C379.148 448.414 378.664 447.091 377.283 447.091C375.902 447.091 375.054 448.35 374.861 449.754H374.869Z"
        fill="#31374C"
      />
      <path
        d="M383.234 446.59C383.646 446.526 384.171 446.469 384.841 446.469C385.657 446.469 386.23 446.663 386.561 447.002C386.868 447.309 387.013 447.769 386.941 448.342C386.86 448.923 386.626 449.375 386.263 449.705C385.762 450.173 385.019 450.407 384.211 450.407C383.961 450.407 383.735 450.399 383.557 450.351L383.202 452.909H382.378L383.251 446.59H383.234ZM383.638 449.673C383.816 449.722 384.042 449.738 384.316 449.738C385.318 449.738 385.988 449.253 386.109 448.374C386.222 447.526 385.681 447.123 384.769 447.123C384.405 447.123 384.131 447.147 383.977 447.188L383.638 449.673Z"
        fill="#31374C"
      />
      <path
        d="M394.935 446.518L394.41 450.303C394.216 451.739 394.765 452.344 395.621 452.344C396.574 452.344 397.269 451.715 397.463 450.303L397.987 446.518H398.827L398.31 450.246C398.044 452.207 396.889 453.014 395.508 453.014C394.2 453.014 393.312 452.264 393.587 450.278L394.103 446.51H394.943L394.935 446.518Z"
        fill="#31374C"
      />
      <path
        d="M402.138 446.59C402.55 446.526 403.075 446.469 403.745 446.469C404.561 446.469 405.134 446.663 405.465 447.002C405.772 447.309 405.917 447.769 405.844 448.342C405.764 448.923 405.53 449.375 405.166 449.705C404.665 450.173 403.923 450.407 403.115 450.407C402.865 450.407 402.639 450.399 402.461 450.351L402.106 452.909H401.282L402.154 446.59H402.138ZM402.542 449.673C402.719 449.722 402.946 449.738 403.22 449.738C404.221 449.738 404.892 449.253 405.013 448.374C405.126 447.526 404.585 447.123 403.672 447.123C403.309 447.123 403.034 447.147 402.881 447.188L402.542 449.673Z"
        fill="#31374C"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.5}
      >
        <path
          d="M469.847 296.084L453.035 418.152H319.845L336.649 296.084H469.847Z"
          fill="#B57E00"
        />
      </g>
      <path
        d="M444.661 409.121H274.294L291.098 287.062H461.473L444.661 409.121Z"
        fill="#FFC133"
      />
      <path
        d="M366.584 325.411H298.382L301.951 299.49H370.153L366.584 325.411Z"
        fill="#212529"
      />
      <path
        d="M443.636 325.411H375.426L378.995 299.49H447.205L443.636 325.411Z"
        fill="white"
      />
      <path
        d="M361.674 361.056H293.472L297.041 335.135H365.243L361.674 361.056Z"
        fill="white"
      />
      <path
        d="M438.726 361.056H370.516L374.085 335.135H442.295L438.726 361.056Z"
        fill="white"
      />
      <path
        d="M356.772 396.701H288.563L292.132 370.78H360.342L356.772 396.701Z"
        fill="white"
      />
      <path
        d="M433.817 396.701H365.615L369.184 370.78H437.386L433.817 396.701Z"
        fill="white"
      />
      <path
        d="M319.288 310.457H319.264L317.996 311.086L317.915 310.392L319.506 309.602H320.289L319.361 316.332H318.473L319.28 310.449L319.288 310.457Z"
        fill="white"
      />
      <path
        d="M326.838 312.902C326.523 315.194 325.49 316.453 323.988 316.453C322.663 316.453 321.929 315.218 322.211 312.991C322.526 310.731 323.673 309.489 325.054 309.489C326.435 309.489 327.129 310.756 326.83 312.894L326.838 312.902ZM323.132 313.007C322.89 314.758 323.301 315.751 324.133 315.751C325.078 315.751 325.675 314.661 325.91 312.943C326.136 311.288 325.861 310.199 324.908 310.199C324.101 310.199 323.382 311.175 323.132 313.007Z"
        fill="white"
      />
      <path
        d="M332.208 312.902C331.893 315.194 330.86 316.453 329.358 316.453C328.033 316.453 327.299 315.218 327.581 312.991C327.896 310.731 329.043 309.489 330.424 309.489C331.804 309.489 332.499 310.756 332.2 312.894L332.208 312.902ZM328.502 313.007C328.26 314.758 328.671 315.751 329.503 315.751C330.448 315.751 331.045 314.661 331.28 312.943C331.506 311.288 331.231 310.199 330.278 310.199C329.471 310.199 328.752 311.175 328.502 313.007Z"
        fill="white"
      />
      <path
        d="M332.103 317.615C332.418 317.002 332.854 315.896 333.072 315.138L334.114 315.033C333.75 315.912 333.137 317.058 332.773 317.551L332.103 317.615Z"
        fill="white"
      />
      <path
        d="M339.734 312.902C339.419 315.194 338.386 316.453 336.884 316.453C335.551 316.453 334.825 315.218 335.107 312.991C335.414 310.731 336.569 309.489 337.95 309.489C339.33 309.489 340.025 310.756 339.726 312.894L339.734 312.902ZM336.028 313.007C335.785 314.758 336.197 315.751 337.029 315.751C337.974 315.751 338.571 314.661 338.806 312.943C339.032 311.288 338.757 310.199 337.804 310.199C336.997 310.199 336.278 311.175 336.028 313.007Z"
        fill="white"
      />
      <path
        d="M345.104 312.902C344.789 315.194 343.756 316.453 342.254 316.453C340.921 316.453 340.194 315.218 340.477 312.991C340.784 310.731 341.939 309.489 343.319 309.489C344.7 309.489 345.395 310.756 345.096 312.894L345.104 312.902ZM341.398 313.007C341.155 314.758 341.567 315.751 342.399 315.751C343.344 315.751 343.941 314.661 344.175 312.943C344.402 311.288 344.127 310.199 343.174 310.199C342.367 310.199 341.648 311.175 341.398 313.007Z"
        fill="white"
      />
      <path
        d="M394.547 316.211L394.628 315.654L395.444 314.96C397.406 313.322 398.31 312.45 398.464 311.442C398.561 310.756 398.31 310.126 397.293 310.126C396.679 310.126 396.122 310.433 395.767 310.699L395.565 310.054C396.09 309.658 396.8 309.368 397.584 309.368C399.045 309.368 399.53 310.36 399.393 311.329C399.223 312.572 398.173 313.572 396.55 314.944L395.936 315.444V315.469H398.989L398.884 316.227H394.539L394.547 316.211Z"
        fill="#31374C"
      />
      <path
        d="M404.908 312.773C404.593 315.065 403.559 316.324 402.057 316.324C400.725 316.324 399.998 315.089 400.281 312.862C400.588 310.602 401.742 309.359 403.123 309.359C404.504 309.359 405.198 310.626 404.9 312.765L404.908 312.773ZM401.201 312.878C400.959 314.629 401.371 315.622 402.203 315.622C403.147 315.622 403.745 314.532 403.979 312.813C404.205 311.159 403.931 310.07 402.978 310.07C402.17 310.07 401.452 311.046 401.201 312.878Z"
        fill="#31374C"
      />
      <path
        d="M410.278 312.773C409.963 315.065 408.929 316.324 407.427 316.324C406.095 316.324 405.368 315.089 405.651 312.862C405.958 310.602 407.112 309.359 408.493 309.359C409.874 309.359 410.568 310.626 410.27 312.765L410.278 312.773ZM406.571 312.878C406.329 314.629 406.741 315.622 407.573 315.622C408.517 315.622 409.115 314.532 409.349 312.813C409.575 311.159 409.301 310.07 408.348 310.07C407.54 310.07 406.822 311.046 406.571 312.878Z"
        fill="#31374C"
      />
      <path
        d="M410.173 317.478C410.488 316.865 410.924 315.759 411.142 315L412.183 314.896C411.82 315.775 411.206 316.929 410.843 317.413L410.173 317.478Z"
        fill="#31374C"
      />
      <path
        d="M417.812 312.773C417.497 315.065 416.463 316.324 414.961 316.324C413.637 316.324 412.902 315.089 413.193 312.862C413.5 310.602 414.654 309.359 416.035 309.359C417.416 309.359 418.111 310.626 417.812 312.765V312.773ZM414.105 312.878C413.863 314.629 414.275 315.622 415.107 315.622C416.051 315.622 416.649 314.532 416.883 312.813C417.109 311.159 416.835 310.07 415.882 310.07C415.074 310.07 414.356 311.046 414.105 312.878Z"
        fill="#31374C"
      />
      <path
        d="M423.182 312.773C422.867 315.065 421.833 316.324 420.331 316.324C419.007 316.324 418.272 315.089 418.563 312.862C418.87 310.602 420.024 309.359 421.405 309.359C422.786 309.359 423.48 310.626 423.182 312.765V312.773ZM419.475 312.878C419.233 314.629 419.645 315.622 420.477 315.622C421.421 315.622 422.019 314.532 422.253 312.813C422.479 311.159 422.205 310.07 421.252 310.07C420.444 310.07 419.726 311.046 419.475 312.878Z"
        fill="#31374C"
      />
      <path
        d="M312.021 350.283C312.263 350.452 312.828 350.711 313.466 350.711C314.645 350.711 315.113 349.968 315.186 349.403C315.307 348.459 314.5 348.056 313.611 348.056H313.103L313.2 347.37H313.708C314.378 347.37 315.275 347.031 315.38 346.232C315.452 345.691 315.178 345.215 314.33 345.215C313.789 345.215 313.232 345.449 312.909 345.659L312.763 344.997C313.151 344.739 313.878 344.48 314.613 344.48C315.953 344.48 316.454 345.271 316.341 346.086C316.244 346.78 315.743 347.37 314.871 347.668V347.692C315.679 347.862 316.276 348.483 316.147 349.419C316.002 350.493 315.024 351.437 313.393 351.437C312.626 351.437 311.996 351.203 311.689 350.985L312.037 350.283H312.021Z"
        fill="#31374C"
      />
      <path
        d="M322.018 347.886C321.703 350.178 320.669 351.437 319.167 351.437C317.835 351.437 317.108 350.202 317.391 347.975C317.705 345.715 318.852 344.472 320.233 344.472C321.614 344.472 322.308 345.739 322.009 347.878L322.018 347.886ZM318.311 347.991C318.069 349.742 318.481 350.735 319.312 350.735C320.257 350.735 320.855 349.645 321.089 347.926C321.315 346.272 321.04 345.182 320.088 345.182C319.28 345.182 318.561 346.159 318.311 347.991Z"
        fill="#31374C"
      />
      <path
        d="M327.387 347.886C327.072 350.178 326.039 351.437 324.537 351.437C323.204 351.437 322.478 350.202 322.76 347.975C323.075 345.715 324.222 344.472 325.603 344.472C326.984 344.472 327.678 345.739 327.379 347.878L327.387 347.886ZM323.681 347.991C323.439 349.742 323.851 350.735 324.682 350.735C325.627 350.735 326.225 349.645 326.459 347.926C326.685 346.272 326.41 345.182 325.457 345.182C324.65 345.182 323.931 346.159 323.681 347.991Z"
        fill="#31374C"
      />
      <path
        d="M327.282 352.599C327.597 351.985 328.033 350.88 328.251 350.121L329.293 350.016C328.93 350.896 328.316 352.05 327.953 352.534L327.282 352.599Z"
        fill="#31374C"
      />
      <path
        d="M334.921 347.886C334.607 350.178 333.573 351.437 332.071 351.437C330.739 351.437 330.012 350.202 330.303 347.975C330.609 345.715 331.764 344.472 333.145 344.472C334.526 344.472 335.22 345.739 334.921 347.878V347.886ZM331.215 347.991C330.973 349.742 331.385 350.735 332.216 350.735C333.161 350.735 333.759 349.645 333.993 347.926C334.219 346.272 333.944 345.182 332.991 345.182C332.184 345.182 331.465 346.159 331.215 347.991Z"
        fill="#31374C"
      />
      <path
        d="M340.291 347.886C339.976 350.178 338.943 351.437 337.441 351.437C336.108 351.437 335.382 350.202 335.672 347.975C335.979 345.715 337.134 344.472 338.515 344.472C339.896 344.472 340.59 345.739 340.291 347.878V347.886ZM336.585 347.991C336.343 349.742 336.754 350.735 337.586 350.735C338.531 350.735 339.129 349.645 339.363 347.926C339.589 346.272 339.314 345.182 338.361 345.182C337.554 345.182 336.835 346.159 336.585 347.991Z"
        fill="#31374C"
      />
      <path
        d="M392.577 351.195L392.828 349.363H389.67L389.751 348.765L393.377 344.464H394.37L393.797 348.652H394.749L394.653 349.363H393.7L393.449 351.195H392.577ZM392.924 348.644L393.231 346.393C393.28 346.038 393.336 345.691 393.409 345.336H393.377C393.11 345.731 392.908 346.022 392.674 346.328L390.696 348.62V348.644H392.924Z"
        fill="#31374C"
      />
      <path
        d="M400.095 347.757C399.78 350.049 398.747 351.307 397.245 351.307C395.912 351.307 395.185 350.073 395.468 347.845C395.775 345.586 396.93 344.343 398.311 344.343C399.691 344.343 400.386 345.61 400.087 347.749L400.095 347.757ZM396.389 347.862C396.146 349.613 396.558 350.605 397.39 350.605C398.335 350.605 398.932 349.516 399.166 347.797C399.393 346.143 399.118 345.053 398.165 345.053C397.358 345.053 396.639 346.03 396.389 347.862Z"
        fill="#31374C"
      />
      <path
        d="M405.457 347.757C405.142 350.049 404.108 351.307 402.606 351.307C401.274 351.307 400.547 350.073 400.83 347.845C401.137 345.586 402.291 344.343 403.672 344.343C405.053 344.343 405.748 345.61 405.449 347.749L405.457 347.757ZM401.75 347.862C401.508 349.613 401.92 350.605 402.752 350.605C403.697 350.605 404.294 349.516 404.528 347.797C404.754 346.143 404.48 345.053 403.527 345.053C402.719 345.053 402.001 346.03 401.75 347.862Z"
        fill="#31374C"
      />
      <path
        d="M405.36 352.47C405.675 351.856 406.111 350.751 406.329 349.992L407.371 349.887C407.007 350.767 406.394 351.913 406.03 352.405L405.36 352.47Z"
        fill="#31374C"
      />
      <path
        d="M412.991 347.757C412.676 350.049 411.642 351.307 410.14 351.307C408.816 351.307 408.081 350.073 408.364 347.845C408.679 345.586 409.826 344.343 411.206 344.343C412.587 344.343 413.282 345.61 412.983 347.749L412.991 347.757ZM409.284 347.862C409.042 349.613 409.454 350.605 410.286 350.605C411.231 350.605 411.828 349.516 412.062 347.797C412.288 346.143 412.014 345.053 411.061 345.053C410.253 345.053 409.535 346.03 409.284 347.862Z"
        fill="#31374C"
      />
      <path
        d="M418.361 347.757C418.046 350.049 417.012 351.307 415.51 351.307C414.186 351.307 413.451 350.073 413.734 347.845C414.049 345.586 415.195 344.343 416.576 344.343C417.957 344.343 418.652 345.61 418.353 347.749L418.361 347.757ZM414.654 347.862C414.412 349.613 414.824 350.605 415.656 350.605C416.6 350.605 417.198 349.516 417.432 347.797C417.658 346.143 417.384 345.053 416.431 345.053C415.623 345.053 414.905 346.03 414.654 347.862Z"
        fill="#31374C"
      />
      <path
        d="M311.488 382.062H308.895L308.395 383.789C308.556 383.765 308.702 383.749 308.952 383.749C309.477 383.749 309.986 383.862 310.365 384.112C310.858 384.411 311.213 384.992 311.1 385.839C310.922 387.155 309.727 388.139 308.249 388.139C307.507 388.139 306.909 387.929 306.61 387.728L306.933 387.034C307.192 387.203 307.717 387.405 308.338 387.405C309.21 387.405 310.026 386.848 310.147 385.944C310.26 385.072 309.759 384.451 308.395 384.451C308.007 384.451 307.7 384.491 307.442 384.524L308.33 381.296H311.584L311.479 382.062H311.488Z"
        fill="#31374C"
      />
      <path
        d="M316.962 384.588C316.647 386.88 315.614 388.139 314.112 388.139C312.78 388.139 312.053 386.904 312.335 384.677C312.65 382.417 313.797 381.175 315.178 381.175C316.559 381.175 317.253 382.442 316.954 384.58L316.962 384.588ZM313.256 384.693C313.014 386.444 313.426 387.437 314.257 387.437C315.202 387.437 315.8 386.348 316.034 384.629C316.26 382.974 315.985 381.885 315.032 381.885C314.225 381.885 313.506 382.861 313.256 384.693Z"
        fill="#31374C"
      />
      <path
        d="M322.332 384.588C322.017 386.88 320.984 388.139 319.482 388.139C318.149 388.139 317.423 386.904 317.705 384.677C318.02 382.417 319.167 381.175 320.548 381.175C321.929 381.175 322.623 382.442 322.324 384.58L322.332 384.588ZM318.626 384.693C318.384 386.444 318.795 387.437 319.627 387.437C320.572 387.437 321.17 386.348 321.404 384.629C321.63 382.974 321.355 381.885 320.402 381.885C319.595 381.885 318.876 382.861 318.626 384.693Z"
        fill="#31374C"
      />
      <path
        d="M322.227 389.301C322.542 388.688 322.978 387.582 323.196 386.824L324.238 386.719C323.875 387.598 323.261 388.752 322.898 389.237L322.227 389.301Z"
        fill="#31374C"
      />
      <path
        d="M329.866 384.588C329.551 386.88 328.518 388.139 327.016 388.139C325.684 388.139 324.957 386.904 325.239 384.677C325.546 382.417 326.701 381.175 328.082 381.175C329.463 381.175 330.157 382.442 329.858 384.58L329.866 384.588ZM326.16 384.693C325.918 386.444 326.33 387.437 327.161 387.437C328.106 387.437 328.704 386.348 328.938 384.629C329.164 382.974 328.889 381.885 327.936 381.885C327.129 381.885 326.41 382.861 326.16 384.693Z"
        fill="#31374C"
      />
      <path
        d="M335.236 384.588C334.921 386.88 333.888 388.139 332.386 388.139C331.053 388.139 330.327 386.904 330.609 384.677C330.916 382.417 332.071 381.175 333.452 381.175C334.832 381.175 335.527 382.442 335.228 384.58L335.236 384.588ZM331.53 384.693C331.288 386.444 331.699 387.437 332.531 387.437C333.476 387.437 334.073 386.348 334.308 384.629C334.534 382.974 334.259 381.885 333.306 381.885C332.499 381.885 331.78 382.861 331.53 384.693Z"
        fill="#31374C"
      />
      <path
        d="M389.396 381.796C389.21 381.788 388.968 381.796 388.701 381.836C387.224 382.079 386.319 383.12 386.004 384.225H386.036C386.416 383.797 387.03 383.45 387.789 383.45C388.992 383.45 389.719 384.314 389.541 385.629C389.371 386.864 388.37 388.002 386.957 388.002C385.544 388.002 384.704 386.88 384.946 385.129C385.132 383.806 385.754 382.756 386.513 382.095C387.151 381.546 387.95 381.207 388.822 381.102C389.097 381.062 389.331 381.054 389.501 381.054L389.396 381.796ZM388.596 385.678C388.725 384.717 388.257 384.136 387.409 384.136C386.852 384.136 386.295 384.475 385.98 384.968C385.899 385.073 385.843 385.202 385.818 385.371C385.689 386.477 386.085 387.3 387.046 387.3C387.845 387.3 388.467 386.646 388.596 385.686V385.678Z"
        fill="#31374C"
      />
      <path
        d="M395.04 384.451C394.725 386.743 393.692 388.002 392.19 388.002C390.857 388.002 390.131 386.767 390.413 384.54C390.72 382.28 391.875 381.038 393.256 381.038C394.636 381.038 395.331 382.305 395.032 384.443L395.04 384.451ZM391.334 384.556C391.091 386.307 391.503 387.3 392.335 387.3C393.28 387.3 393.877 386.21 394.112 384.491C394.338 382.837 394.063 381.748 393.11 381.748C392.303 381.748 391.584 382.724 391.334 384.556Z"
        fill="#31374C"
      />
      <path
        d="M400.41 384.451C400.095 386.743 399.062 388.002 397.56 388.002C396.227 388.002 395.5 386.767 395.783 384.54C396.09 382.28 397.245 381.038 398.625 381.038C400.006 381.038 400.701 382.305 400.402 384.443L400.41 384.451ZM396.704 384.556C396.461 386.307 396.873 387.3 397.705 387.3C398.65 387.3 399.247 386.21 399.481 384.491C399.708 382.837 399.433 381.748 398.48 381.748C397.673 381.748 396.954 382.724 396.704 384.556Z"
        fill="#31374C"
      />
      <path
        d="M400.305 389.164C400.62 388.551 401.056 387.445 401.274 386.687L402.316 386.582C401.952 387.461 401.339 388.607 400.975 389.099L400.305 389.164Z"
        fill="#31374C"
      />
      <path
        d="M407.944 384.451C407.629 386.743 406.596 388.002 405.094 388.002C403.769 388.002 403.034 386.767 403.325 384.54C403.632 382.28 404.787 381.038 406.168 381.038C407.548 381.038 408.243 382.305 407.944 384.443V384.451ZM404.238 384.556C403.995 386.307 404.407 387.3 405.239 387.3C406.184 387.3 406.781 386.21 407.015 384.491C407.242 382.837 406.967 381.748 406.014 381.748C405.207 381.748 404.488 382.724 404.238 384.556Z"
        fill="#31374C"
      />
      <path
        d="M413.306 384.451C412.991 386.743 411.957 388.002 410.455 388.002C409.131 388.002 408.396 386.767 408.687 384.54C408.994 382.28 410.149 381.038 411.529 381.038C412.91 381.038 413.605 382.305 413.306 384.443V384.451ZM409.599 384.556C409.357 386.307 409.769 387.3 410.601 387.3C411.546 387.3 412.143 386.21 412.377 384.491C412.603 382.837 412.329 381.748 411.376 381.748C410.568 381.748 409.85 382.724 409.599 384.556Z"
        fill="#31374C"
      />
      <path
        d="M544.792 362.614H496.681L504.61 311.514H552.722L544.792 362.614Z"
        fill="#FFF7E6"
      />
      <path
        d="M535.958 339.929H512.54L514.397 327.953C515.326 321.957 520.534 316.687 526.599 316.017C533.786 315.218 538.929 320.738 537.863 327.638L535.958 339.929ZM514.728 338.105H534.335L535.934 327.832C536.684 323.006 533.971 318.688 529.328 317.906C523.353 316.897 517.296 321.626 516.368 327.565L514.728 338.105Z"
        fill="#6B4A00"
      />
      <path
        d="M540.738 330.898H510.578C509.568 330.898 508.624 331.713 508.462 332.73L504.788 356.375C504.635 357.384 505.321 358.207 506.33 358.207H536.491C537.5 358.207 538.445 357.384 538.606 356.375L542.28 332.73C542.434 331.722 541.739 330.898 540.738 330.898ZM522.19 344.319L522.497 342.366C522.593 341.761 523.038 341.18 523.643 340.994C524.645 340.695 525.412 341.389 525.266 342.301L524.951 344.319C525.719 344.803 526.147 345.707 525.985 346.748C525.71 348.507 523.861 349.879 522.101 349.451C521.1 349.209 520.405 348.33 520.373 347.281C520.332 346.022 521.124 344.884 522.19 344.319Z"
        fill="#FFC133"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.3}
      >
        <path
          d="M542.289 332.722L538.614 356.367C538.461 357.376 537.508 358.199 536.499 358.199H506.338C505.329 358.199 504.635 357.384 504.796 356.367L504.982 355.189C512.136 355.286 522.86 354.971 529.005 352.631C537.807 349.274 539.963 335.313 540.359 330.89H540.746C541.756 330.89 542.45 331.713 542.289 332.722Z"
          fill="#C5D0FF"
        />
      </g>
      <path
        d="M541.546 258.518C541.425 256.767 540.908 255.177 540.076 253.813C539.285 252.522 538.195 251.441 536.894 250.674C535.449 249.819 533.745 249.343 531.872 249.351C525.824 249.399 520.324 254.556 519.598 260.883C519.574 261.076 519.557 261.278 519.541 261.472C519.485 262.335 519.517 263.167 519.638 263.966C519.719 264.506 519.84 265.023 520.001 265.531C520.098 265.83 520.195 266.12 520.316 266.403C521.77 269.913 525.089 272.27 529.223 272.237C534.892 272.189 540.092 267.645 541.312 261.851C541.392 261.472 541.457 261.093 541.497 260.705C541.586 259.955 541.594 259.22 541.546 258.51V258.518ZM529.457 270.212C526.284 270.236 523.692 268.549 522.384 265.975C522.206 265.628 522.053 265.265 521.923 264.894C521.73 264.304 521.592 263.683 521.512 263.037C521.431 262.335 521.439 261.617 521.52 260.867C521.544 260.625 521.584 260.391 521.625 260.156C522.537 255.282 526.865 251.417 531.621 251.376C532.76 251.368 533.826 251.578 534.779 251.973C536.176 252.546 537.339 253.515 538.186 254.75C539.018 255.984 539.527 257.485 539.6 259.14C539.624 259.648 539.6 260.189 539.543 260.721C539.479 261.262 539.374 261.795 539.228 262.311C537.985 266.758 533.899 270.171 529.441 270.204L529.457 270.212Z"
        fill="#FFB100"
      />
      <path
        d="M540.431 257.84C540.31 256.089 539.793 254.499 538.962 253.136C538.17 251.844 537.08 250.763 535.78 249.996C534.335 249.141 532.631 248.665 530.757 248.673C524.709 248.721 519.21 253.878 518.483 260.205C518.459 260.399 518.443 260.6 518.427 260.794C518.37 261.658 518.403 262.489 518.524 263.288C518.605 263.828 518.726 264.345 518.887 264.853C518.984 265.152 519.081 265.442 519.202 265.725C520.656 269.235 523.974 271.592 528.109 271.559C533.778 271.511 538.978 266.968 540.197 261.173C540.278 260.794 540.343 260.415 540.383 260.027C540.472 259.277 540.48 258.542 540.431 257.832V257.84ZM528.343 269.534C525.169 269.558 522.577 267.871 521.269 265.297C521.092 264.95 520.938 264.587 520.809 264.216C520.615 263.627 520.478 263.005 520.397 262.36C520.316 261.658 520.324 260.939 520.405 260.189C520.429 259.947 520.47 259.713 520.51 259.479C521.423 254.604 525.751 250.739 530.507 250.698C531.646 250.69 532.712 250.9 533.664 251.296C535.061 251.869 536.224 252.837 537.072 254.072C537.904 255.306 538.413 256.807 538.485 258.462C538.509 258.97 538.485 259.511 538.429 260.043C538.364 260.584 538.259 261.117 538.114 261.633C536.87 266.08 532.784 269.494 528.327 269.526L528.343 269.534Z"
        fill="#FFCB54"
      />
      <path
        d="M530.531 250.642C535.546 250.602 539.115 254.814 538.509 260.052C537.904 265.289 533.35 269.566 528.335 269.606C523.32 269.647 519.751 265.434 520.357 260.197C520.962 254.959 525.517 250.682 530.531 250.642ZM537.314 260.06C537.839 255.508 534.738 251.852 530.386 251.885C526.034 251.917 522.077 255.637 521.544 260.189C521.019 264.74 524.12 268.396 528.472 268.364C532.825 268.331 536.781 264.611 537.314 260.06Z"
        fill="white"
      />
      <path
        d="M535.54 264.692C538.431 260.837 538.039 255.661 534.664 253.132C531.288 250.603 526.207 251.678 523.316 255.533C520.424 259.387 520.816 264.563 524.191 267.092C527.567 269.621 532.648 268.546 535.54 264.692Z"
        fill="#FFCB54"
      />
      <path
        d="M531.129 256.46C531.985 256.807 532.316 257.606 532.187 258.736L532.171 258.906H531.056C531.129 258.284 531.032 257.832 530.757 257.558L530.031 259.648C531.403 259.866 532.066 260.431 531.928 261.641V261.73C531.759 263.11 530.709 263.966 529.045 263.982C528.868 263.982 528.706 263.974 528.545 263.966L528.101 265.273H527.196L527.721 263.756C526.857 263.376 526.478 262.577 526.607 261.52V261.456L527.729 261.448C527.665 261.996 527.794 262.416 528.093 262.674L528.852 260.463C527.439 260.221 526.906 259.6 527.035 258.526V258.438C527.204 257.09 528.335 256.267 529.885 256.259C530.031 256.259 530.168 256.267 530.297 256.275L530.741 254.984H531.646L531.137 256.46H531.129ZM529.167 259.519L529.942 257.251C529.885 257.243 529.821 257.243 529.772 257.243H529.732C528.932 257.243 528.262 257.655 528.181 258.349V258.405C528.109 258.986 528.351 259.349 529.175 259.519H529.167ZM530.757 261.803V261.746C530.838 261.093 530.507 260.754 529.7 260.592L528.876 262.997C528.965 263.005 529.045 263.005 529.134 263.005H529.175C530.031 263.005 530.669 262.561 530.757 261.803Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M538.962 253.144C538.348 253.45 537.718 253.765 537.088 254.08C531.678 256.791 525.702 259.761 520.405 262.368C519.767 262.682 519.137 262.989 518.524 263.296C518.403 262.497 518.362 261.666 518.427 260.802C519.113 260.366 519.816 259.931 520.526 259.487C524.733 256.856 529.328 253.999 533.681 251.304C534.391 250.868 535.086 250.432 535.78 250.004C537.08 250.771 538.162 251.844 538.962 253.144Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M540.383 260.035C540.334 260.423 540.27 260.802 540.197 261.181C539.567 261.319 538.873 261.472 538.13 261.641C533.245 262.723 526.397 264.224 521.269 265.297C520.542 265.45 519.848 265.596 519.202 265.733C519.081 265.45 518.976 265.16 518.887 264.861C519.485 264.668 520.139 264.45 520.817 264.224C526.114 262.465 533.438 260.108 538.501 258.47C539.188 258.244 539.842 258.034 540.439 257.848C540.488 258.559 540.48 259.285 540.391 260.044L540.383 260.035Z"
          fill="#F0F1F1"
        />
      </g>
      <path
        d="M322.618 225.509C322.423 222.686 321.59 220.123 320.249 217.925C318.974 215.843 317.217 214.1 315.121 212.864C312.791 211.485 310.044 210.718 307.024 210.731C297.275 210.809 288.411 219.121 287.239 229.32C287.2 229.632 287.174 229.958 287.148 230.27C287.057 231.662 287.109 233.002 287.304 234.289C287.434 235.161 287.63 235.994 287.89 236.813C288.046 237.294 288.202 237.763 288.398 238.218C290.741 243.877 296.09 247.675 302.755 247.623C311.893 247.545 320.275 240.221 322.241 230.881C322.371 230.27 322.475 229.658 322.54 229.034C322.684 227.824 322.697 226.64 322.618 225.496V225.509ZM303.132 244.358C298.017 244.397 293.839 241.678 291.73 237.529C291.443 236.969 291.196 236.384 290.988 235.785C290.675 234.836 290.454 233.834 290.324 232.793C290.194 231.662 290.207 230.504 290.337 229.294C290.376 228.904 290.441 228.527 290.506 228.149C291.977 220.292 298.954 214.061 306.621 213.996C308.456 213.983 310.174 214.321 311.71 214.959C313.962 215.882 315.837 217.443 317.203 219.434C318.544 221.424 319.364 223.843 319.481 226.51C319.52 227.33 319.481 228.201 319.39 229.06C319.286 229.932 319.117 230.79 318.883 231.623C316.878 238.79 310.292 244.293 303.106 244.345L303.132 244.358Z"
        fill="#FFB100"
      />
      <path
        d="M320.822 224.416C320.627 221.593 319.794 219.03 318.453 216.832C317.177 214.75 315.42 213.007 313.325 211.772C310.995 210.393 308.248 209.625 305.228 209.638C295.479 209.716 286.614 218.029 285.443 228.227C285.404 228.54 285.378 228.865 285.352 229.177C285.261 230.569 285.313 231.909 285.508 233.197C285.638 234.068 285.833 234.901 286.094 235.72C286.25 236.202 286.406 236.67 286.601 237.125C288.944 242.784 294.294 246.583 300.959 246.531C310.096 246.452 318.479 239.129 320.445 229.788C320.575 229.177 320.679 228.566 320.744 227.941C320.887 226.731 320.9 225.548 320.822 224.403V224.416ZM301.336 243.265C296.221 243.304 292.042 240.586 289.934 236.436C289.647 235.876 289.4 235.291 289.192 234.693C288.879 233.743 288.658 232.741 288.528 231.701C288.398 230.569 288.411 229.411 288.541 228.201C288.58 227.811 288.645 227.434 288.71 227.057C290.181 219.199 297.158 212.968 304.825 212.903C306.66 212.89 308.378 213.228 309.914 213.866C312.166 214.79 314.04 216.351 315.407 218.341C316.748 220.331 317.568 222.751 317.685 225.418C317.724 226.237 317.685 227.109 317.594 227.967C317.49 228.839 317.321 229.697 317.086 230.53C315.082 237.698 308.495 243.2 301.31 243.252L301.336 243.265Z"
        fill="#FFCB54"
      />
      <path
        d="M304.864 212.812C312.947 212.747 318.7 219.538 317.724 227.98C316.748 236.423 309.406 243.317 301.323 243.382C293.24 243.447 287.486 236.657 288.463 228.214C289.439 219.772 296.78 212.877 304.864 212.812ZM315.798 227.993C316.644 220.656 311.645 214.763 304.629 214.816C297.613 214.868 291.235 220.865 290.376 228.201C289.53 235.538 294.528 241.431 301.544 241.379C308.56 241.327 314.939 235.33 315.798 227.993Z"
        fill="white"
      />
      <path
        d="M312.937 235.46C317.598 229.246 316.966 220.903 311.525 216.826C306.084 212.749 297.894 214.482 293.232 220.696C288.57 226.91 289.203 235.252 294.644 239.329C300.085 243.406 308.275 241.674 312.937 235.46Z"
        fill="#FFCB54"
      />
      <path
        d="M305.827 222.191C307.207 222.751 307.74 224.039 307.532 225.86L307.506 226.133H305.71C305.827 225.131 305.671 224.403 305.228 223.961L304.057 227.33C306.269 227.681 307.337 228.592 307.115 230.543V230.686C306.842 232.91 305.15 234.289 302.469 234.315C302.182 234.315 301.922 234.302 301.661 234.289L300.946 236.397H299.488L300.334 233.951C298.941 233.34 298.329 232.052 298.537 230.348V230.244L300.347 230.231C300.243 231.115 300.451 231.792 300.933 232.208L302.156 228.644C299.878 228.253 299.019 227.252 299.227 225.522V225.378C299.501 223.206 301.323 221.879 303.822 221.866C304.057 221.866 304.278 221.879 304.486 221.892L305.202 219.811H306.66L305.84 222.191H305.827ZM302.664 227.122L303.913 223.466C303.822 223.453 303.718 223.453 303.64 223.453H303.575C302.286 223.453 301.206 224.117 301.076 225.235V225.326C300.959 226.263 301.349 226.848 302.677 227.122H302.664ZM305.228 230.803V230.712C305.358 229.658 304.825 229.112 303.523 228.852L302.195 232.728C302.338 232.741 302.469 232.741 302.612 232.741H302.677C304.057 232.741 305.085 232.026 305.228 230.803Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M318.453 216.845C317.464 217.339 316.449 217.847 315.433 218.354C306.712 222.725 297.08 227.512 288.541 231.714C287.513 232.221 286.497 232.715 285.508 233.21C285.313 231.922 285.248 230.582 285.352 229.19C286.458 228.488 287.591 227.785 288.736 227.07C295.518 222.829 302.924 218.224 309.94 213.879C311.086 213.176 312.205 212.474 313.325 211.785C315.42 213.02 317.165 214.75 318.453 216.845Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M320.744 227.954C320.666 228.579 320.562 229.19 320.445 229.801C319.429 230.023 318.31 230.27 317.112 230.543C309.237 232.286 298.199 234.706 289.934 236.436C288.762 236.683 287.643 236.917 286.601 237.138C286.406 236.683 286.237 236.215 286.094 235.733C287.057 235.421 288.111 235.07 289.205 234.706C297.744 231.87 309.55 228.071 317.711 225.431C318.818 225.066 319.872 224.728 320.835 224.429C320.913 225.574 320.9 226.744 320.757 227.967L320.744 227.954Z"
          fill="#F0F1F1"
        />
      </g>
      <path
        d="M449.571 178.253C449.45 176.502 448.933 174.912 448.102 173.548C447.31 172.257 446.22 171.175 444.92 170.409C443.475 169.553 441.771 169.077 439.897 169.085C433.849 169.134 428.35 174.29 427.623 180.617C427.599 180.811 427.583 181.013 427.567 181.206C427.51 182.07 427.542 182.901 427.664 183.7C427.744 184.241 427.865 184.757 428.027 185.266C428.124 185.564 428.221 185.855 428.342 186.137C429.795 189.648 433.114 192.004 437.249 191.972C442.917 191.923 448.118 187.38 449.337 181.586C449.418 181.206 449.482 180.827 449.523 180.44C449.612 179.689 449.62 178.955 449.571 178.245V178.253ZM437.483 189.946C434.309 189.971 431.717 188.284 430.409 185.71C430.231 185.363 430.078 184.999 429.949 184.628C429.755 184.039 429.618 183.418 429.537 182.772C429.456 182.07 429.464 181.352 429.545 180.601C429.569 180.359 429.61 180.125 429.65 179.891C430.563 175.017 434.891 171.151 439.647 171.111C440.786 171.103 441.851 171.313 442.804 171.708C444.201 172.281 445.364 173.249 446.212 174.484C447.044 175.719 447.552 177.22 447.625 178.874C447.649 179.383 447.625 179.923 447.569 180.456C447.504 180.997 447.399 181.529 447.254 182.046C446.01 186.492 441.924 189.906 437.467 189.938L437.483 189.946Z"
        fill="#FFB100"
      />
      <path
        d="M448.457 177.575C448.336 175.824 447.819 174.234 446.987 172.87C446.196 171.579 445.106 170.498 443.806 169.731C442.36 168.875 440.656 168.399 438.783 168.407C432.735 168.456 427.236 173.613 426.509 179.939C426.485 180.133 426.468 180.335 426.452 180.529C426.396 181.392 426.428 182.223 426.549 183.022C426.63 183.563 426.751 184.079 426.913 184.588C427.01 184.886 427.106 185.177 427.228 185.459C428.681 188.97 432 191.326 436.134 191.294C441.803 191.246 447.003 186.702 448.223 180.908C448.303 180.529 448.368 180.149 448.408 179.762C448.497 179.011 448.505 178.277 448.457 177.567V177.575ZM436.368 189.268C433.195 189.293 430.603 187.606 429.295 185.032C429.117 184.685 428.964 184.321 428.834 183.95C428.641 183.361 428.503 182.74 428.423 182.094C428.342 181.392 428.35 180.674 428.431 179.923C428.455 179.681 428.495 179.447 428.536 179.213C429.448 174.339 433.776 170.473 438.533 170.433C439.671 170.425 440.737 170.635 441.69 171.03C443.087 171.603 444.25 172.572 445.098 173.806C445.929 175.041 446.438 176.542 446.511 178.196C446.535 178.705 446.511 179.245 446.454 179.778C446.39 180.319 446.285 180.851 446.139 181.368C444.896 185.814 440.81 189.228 436.352 189.26L436.368 189.268Z"
        fill="#FFCB54"
      />
      <path
        d="M438.557 170.376C443.571 170.336 447.141 174.549 446.535 179.786C445.929 185.024 441.375 189.301 436.36 189.341C431.346 189.381 427.777 185.169 428.382 179.931C428.988 174.694 433.542 170.417 438.557 170.376ZM445.34 179.794C445.865 175.243 442.764 171.587 438.411 171.619C434.059 171.652 430.102 175.372 429.569 179.923C429.044 184.475 432.145 188.131 436.498 188.098C440.85 188.066 444.807 184.346 445.34 179.794Z"
        fill="white"
      />
      <path
        d="M443.565 184.426C446.457 180.571 446.065 175.396 442.689 172.867C439.314 170.338 434.233 171.412 431.341 175.267C428.449 179.122 428.841 184.297 432.217 186.826C435.592 189.356 440.673 188.281 443.565 184.426Z"
        fill="#FFCB54"
      />
      <path
        d="M439.154 176.195C440.01 176.542 440.341 177.341 440.212 178.471L440.196 178.64H439.082C439.154 178.019 439.057 177.567 438.783 177.292L438.056 179.383C439.429 179.601 440.091 180.165 439.954 181.376V181.465C439.784 182.845 438.734 183.7 437.071 183.716C436.893 183.716 436.732 183.708 436.57 183.7L436.126 185.007H435.222L435.747 183.49C434.883 183.111 434.503 182.312 434.632 181.255V181.19L435.755 181.182C435.69 181.731 435.819 182.151 436.118 182.409L436.877 180.198C435.464 179.956 434.931 179.334 435.06 178.261V178.172C435.23 176.824 436.36 176.001 437.911 175.993C438.056 175.993 438.193 176.001 438.323 176.009L438.767 174.718H439.671L439.162 176.195H439.154ZM437.192 179.254L437.967 176.986C437.911 176.978 437.846 176.978 437.798 176.978H437.757C436.958 176.978 436.288 177.389 436.207 178.083V178.14C436.134 178.721 436.377 179.084 437.2 179.254H437.192ZM438.783 181.537V181.481C438.864 180.827 438.533 180.488 437.725 180.327L436.901 182.732C436.99 182.74 437.071 182.74 437.16 182.74H437.2C438.056 182.74 438.694 182.296 438.783 181.537Z"
        fill="#FFF7E6"
      />
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M446.987 172.878C446.374 173.185 445.744 173.5 445.114 173.814C439.704 176.526 433.728 179.496 428.431 182.102C427.793 182.417 427.163 182.724 426.549 183.03C426.428 182.231 426.388 181.4 426.452 180.537C427.139 180.101 427.841 179.665 428.552 179.221C432.759 176.59 437.354 173.734 441.706 171.038C442.417 170.602 443.111 170.167 443.806 169.739C445.106 170.506 446.188 171.579 446.987 172.878Z"
          fill="#F0F1F1"
        />
      </g>
      <g
        style={{
          mixBlendMode: "screen",
        }}
        opacity={0.1}
      >
        <path
          d="M448.408 179.77C448.36 180.157 448.295 180.537 448.223 180.916C447.593 181.053 446.898 181.206 446.155 181.376C441.27 182.457 434.422 183.958 429.295 185.032C428.568 185.185 427.874 185.33 427.228 185.467C427.106 185.185 427.001 184.894 426.913 184.596C427.51 184.402 428.164 184.184 428.843 183.958C434.14 182.199 441.464 179.843 446.527 178.204C447.213 177.978 447.867 177.769 448.465 177.583C448.513 178.293 448.505 179.019 448.416 179.778L448.408 179.77Z"
          fill="#F0F1F1"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_66_1403">
        <rect width={587} height={489} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SVGComponent

import React, { useEffect, useState } from 'react';
import { IHeader } from './interface';
import { getAuthToken } from 'utils';
import {
  capitalize,
  encodeGetAccount,
  firstLatter,
  useWindowDimensions,
} from 'utils/utility';
import {
  IcBurgerBtn,
  IcDropdown,
  IcLogoQoin,
  IcLogoQoinBlack,
} from 'assets/svgs';

const Component: React.FC<IHeader> = ({
  title,
  desc,
  setIsSidebarAccount,
  setIsSidebarMenu,
}) => {
  const [fullname, setFullname] = useState<string>('');
  const { width } = useWindowDimensions();

  useEffect(() => {
    let token = getAuthToken();
    if (token) {
      const account = encodeGetAccount(token);
      const name = !account?.claims?.lastname
        ? account?.claims?.firstname
        : `${account?.claims?.firstname} ${account?.claims?.lastname}`;

      setFullname(name);
    }
  }, []);

  return (
    <div className="allMobile:px-6">
      <div className="flex justify-between items-center mb-10">
        {width <= 640 ? (
          <IcLogoQoinBlack />
        ) : (
          <div>
            <h1 className="text-4xl font-medium">{title}</h1>
            <p className="text-base font-normal text-[#485059] mt-2">
              {!!fullname && !!desc
                ? `${desc}, ${capitalize(fullname)}!`
                : desc}
            </p>
          </div>
        )}

        <div className="allMobile:flex allMobile:justify-center allMobile:items-center">
          <div
            onClick={() => setIsSidebarAccount(true)}
            className="cursor-pointer py-3 allMobile:py-1 px-4 allMobile:px-2 flex justify-between items-center border-[1px] border-[#EDEEEF] bg-[#FFF] rounded-full shadow allMobile:mr-6"
          >
            <div className="border-[1px] border-[#EDEEEF] rounded-full w-8 h-8 text-center flex justify-center items-center allMobile:mr-4">
              {firstLatter(fullname)}
            </div>
            <p className="mx-3 allMobile:hidden">{capitalize(fullname)}</p>
            <IcDropdown />
          </div>

          {width <= 640 && (
            <div onClick={() => setIsSidebarMenu(true)}>
              <IcBurgerBtn />
            </div>
          )}
        </div>
      </div>

      {width <= 640 && (
        <div className="mb-6">
          <h1 className="text-4xl font-semibold">{title}</h1>
          <p className="text-base font-normal text-[#485059] mt-2">
            {!!fullname && !!desc ? `${desc}, ${capitalize(fullname)}!` : desc}
          </p>
        </div>
      )}
    </div>
  );
};

export default Component;

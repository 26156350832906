import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthToken } from 'utils';
import { Helmet } from 'react-helmet';
import useWalletAction from 'stores/actions/walletAction';
import { Header } from './organisms';

function PrivateRoute({
  children,
  title,
  subtitle,
  webTitle = '',
  setIsSidebarAccount,
  setIsSidebarMenu,
}) {
  const [isLogin, setIsLogin] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { managementWalletState } = useWalletAction();

  useEffect(() => {
    setIsLogin(getAuthToken());
    setIsLoading(false);
  }, []);

  const renderingUI = () => {
    if (isLoading === true) {
      return <>Loading ...</>;
    } else if (!isLogin) {
      return <Navigate replace={true} to="/login" />;
    } else if (managementWalletState.address === '') {
      return (
        <>
          <Header
            title={title}
            desc={subtitle}
            setIsSidebarMenu={setIsSidebarMenu}
            setIsSidebarAccount={setIsSidebarAccount}
          />
          <div>
            <div className="flex items-start justify-between">
              <div className="w-full block mx-6 allMobile:mx-0 mb-6 allMobile:mb-0 py-4 border-b border-gray-200">
                <h1 className="text-lg font-bold text-[#2B3036]">
                  Please connect your wallet to manage your token.
                </h1>
                <p className="text-xs block text-[#2B3036]">
                  Any tokens minted using the address connected will appear
                  below. Please note: If you have transferred ownership of a
                  token you've minted here, it will not appear below
                </p>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Header
            title={title}
            desc={subtitle}
            setIsSidebarMenu={setIsSidebarMenu}
            setIsSidebarAccount={setIsSidebarAccount}
          />
          {children}
        </>
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{webTitle}</title>
      </Helmet>
      {renderingUI()}
    </>
  );
}

export default PrivateRoute;
